var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        [
          _c(
            "q-pull-to-refresh",
            {
              attrs: {
                handler: _vm.channelInit,
                "refresh-icon": "ion-sync",
                "release-message": _vm.$t("PTR.RELEASE"),
                "refresh-message": _vm.$t("PTR.REFRESH"),
                "pull-message": _vm.$t("PTR.PULL"),
                color: "faded"
              }
            },
            [
              _c(
                "div",
                { staticClass: "product column items-center" },
                [
                  _c("q-scroll-observable", { on: { scroll: _vm.scrolled } }),
                  _c(
                    "q-card",
                    {
                      staticClass: "q-card-flat text-center",
                      nativeOn: {
                        click: function($event) {
                          _vm.contentInfoShow = !_vm.contentInfoShow
                        }
                      }
                    },
                    [
                      _c("q-card-title", [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-size-140p text-weight-semibold inline-block",
                            class: { hidden: _vm.productNameHidden },
                            staticStyle: { "line-height": "1.1em" }
                          },
                          [
                            _vm._v(
                              "\n        " + _vm._s(_vm.paramName) + "\n      "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "block text-family-brand text-subinfo-l capitalize font-size-90p",
                            staticStyle: { "margin-top": "-4px" }
                          },
                          [
                            _c("span", {
                              staticClass:
                                "text-weight-semibold uppercase inline-block ellipsis",
                              staticStyle: {
                                "line-height": "1em",
                                width: "90vw"
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.product.data.business.address.html
                                )
                              }
                            })
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.contentInfoShow
                    ? _c(
                        "transition",
                        {
                          attrs: {
                            appear: "",
                            "enter-active-class":
                              "animated zoomIn animated-d400",
                            "leave-active-class":
                              "animated zoomOut animated-d200"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              key: "info",
                              staticClass:
                                "row no-wrap justify-center items-center relative-position",
                              staticStyle: {
                                "max-width": "400px",
                                height: "70px"
                              }
                            },
                            [
                              _vm.shareSheetSupport()
                                ? _c(
                                    "q-btn",
                                    {
                                      staticStyle: { margin: "0px 10px" },
                                      attrs: {
                                        size: "1.4em",
                                        "text-color": _vm.anyDarkmode
                                          ? "shallow"
                                          : "subinfo_l",
                                        round: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.shareSheet($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        style: _vm.anyDarkmode
                                          ? "filter: invert(.5)"
                                          : "",
                                        attrs: {
                                          src:
                                            "/statics/_demo/square.and.arrow.up.fill_primary.svg",
                                          width: "28px"
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "q-btn",
                                {
                                  staticStyle: { margin: "0px 10px" },
                                  attrs: {
                                    size: "1.4em",
                                    "text-color": _vm.anyDarkmode
                                      ? "shallow"
                                      : "subinfo_l",
                                    round: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.qrcode($event)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    style: _vm.anyDarkmode
                                      ? "filter: invert(.5)"
                                      : "",
                                    attrs: {
                                      src: "/statics/_demo/qrcode_primary.svg",
                                      width: "34px"
                                    }
                                  })
                                ]
                              ),
                              _vm.channel && _vm.channel.description === 0
                                ? _c(
                                    "q-btn",
                                    {
                                      staticStyle: { margin: "0px 10px" },
                                      attrs: {
                                        size: "1.4em",
                                        "text-color": _vm.anyDarkmode
                                          ? "shallow"
                                          : "subinfo_l",
                                        round: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.about($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        style: _vm.anyDarkmode
                                          ? "filter: invert(.5)"
                                          : "",
                                        attrs: {
                                          src:
                                            "/statics/_demo/info_primary.svg",
                                          width: "34px"
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "q-card",
                    {
                      staticClass:
                        "limit-width-420 overflow-hidden q-card-grouped q-card-flat on-top-default",
                      attrs: { inline: "" }
                    },
                    [
                      _c("q-card-title", { staticClass: "no-padding" }, [
                        _c(
                          "div",
                          { staticClass: "row justify-start items-center" },
                          [
                            _c(
                              "transition",
                              {
                                attrs: {
                                  mode: "out-in",
                                  appear: "",
                                  "enter-active-class":
                                    "animated slideInUp animated-d400",
                                  "leave-active-class":
                                    "animated slideOutDown animated-d200"
                                }
                              },
                              [
                                _vm.channel && _vm.channel.online === 0
                                  ? _c(
                                      "div",
                                      {
                                        key: "closed",
                                        staticClass:
                                          "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-red"
                                      },
                                      [
                                        _vm._v(
                                          "\n            Closed\n          "
                                        )
                                      ]
                                    )
                                  : _vm.channel && _vm.channel.online === 1
                                  ? _c(
                                      "div",
                                      {
                                        key: "current-" + _vm.channel.orders,
                                        staticClass:
                                          "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder",
                                        class: {
                                          "text-educate":
                                            _vm.channel.orders === 0,
                                          "text-attention":
                                            _vm.channel.orders === 1
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.datagroupDataValue(
                                                "services",
                                                "orders",
                                                _vm.channel.orders
                                              )
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        key: "others",
                                        staticClass:
                                          "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder",
                                        class: {
                                          "text-educate":
                                            _vm.channel &&
                                            _vm.channel.orders === 0,
                                          "text-attention":
                                            _vm.channel &&
                                            _vm.channel.orders === 1
                                        }
                                      },
                                      [_vm._v("\n             \n          ")]
                                    )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "transition",
                    {
                      attrs: {
                        appear: "",
                        "enter-active-class": "animated fadeInUp animated-d800",
                        "leave-active-class": "animated fadeOutUp animated-d800"
                      }
                    },
                    [
                      _c(
                        "q-card",
                        {
                          directives: [
                            {
                              name: "touch-pan",
                              rawName: "v-touch-pan.noMouse",
                              value: function(obj) {
                                _vm.setCardIntent(obj, "guardian", function() {
                                  _vm.personalizeList()
                                  /*personalize();*/ _vm.soundPlay("sheet_up")
                                })
                              },
                              expression:
                                "(obj) => { setCardIntent(obj, 'guardian', () => { personalizeList(); /*personalize();*/ soundPlay('sheet_up') }) }",
                              modifiers: { noMouse: true }
                            }
                          ],
                          key: "guardian",
                          ref: "product-card-guardian",
                          staticClass:
                            "no-border limit-width-420 overflow-hidden q-card-grouped q-card-widget",
                          class: {
                            "q-card-widget-guard":
                              !_vm.channel ||
                              (_vm.channel && !_vm.channel.online),
                            "q-card-widget-guard-online":
                              _vm.channel && _vm.channel.online,
                            "q-card-widget-guard-warning":
                              _vm.channel &&
                              _vm.channel.online &&
                              _vm.channel.orders === 1
                          },
                          staticStyle: { "margin-top": "10px" },
                          attrs: { inline: "" }
                        },
                        [
                          [
                            _vm.channel === null
                              ? _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    stripe: "",
                                    indeterminate: "",
                                    color: "hint",
                                    height: "8px"
                                  }
                                })
                              : _vm.channel === false
                              ? _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    stripe: "",
                                    percentage: 100,
                                    color: "hint",
                                    height: "8px"
                                  }
                                })
                              : _vm.channel.online && _vm.channel.orders === 1
                              ? _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    stripe: "",
                                    indeterminate: "",
                                    color: "black",
                                    height: "8px"
                                  }
                                })
                              : _vm.channel.online
                              ? _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    stripe: "",
                                    percentage: 100,
                                    indeterminate: "",
                                    color: "green-d",
                                    height: "8px"
                                  }
                                })
                              : _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    percentage: 100,
                                    stripe: "",
                                    color: "protect",
                                    height: "8px"
                                  }
                                })
                          ],
                          _c(
                            "q-card-title",
                            [
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    mode: "out-in",
                                    appear: "",
                                    "enter-active-class":
                                      "animated fadeIn animated-d400",
                                    "leave-active-class":
                                      "animated fadeOut animated-d200"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      key: _vm.channel
                                        ? "guardian-info-img-" +
                                          _vm.channel.online +
                                          "-" +
                                          _vm.channel.orders
                                        : "guardian-info-img-null",
                                      staticClass:
                                        "row justify-start items-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "left on-left-lg items-center row text-center justify-center",
                                          staticStyle: {
                                            width: "55px",
                                            height: "70px"
                                          }
                                        },
                                        [
                                          _vm.channel === false
                                            ? _c("img", {
                                                staticClass:
                                                  "brighten-50 dark-brighten-100",
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/circle.b3line_white.svg",
                                                  width: "48"
                                                }
                                              })
                                            : !_vm.channel
                                            ? _c("img", {
                                                staticClass:
                                                  "brighten-50 dark-brighten-100",
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/circles4_white.svg",
                                                  width: "48"
                                                }
                                              })
                                            : _vm.channel.online &&
                                              _vm.channel.orders === 1
                                            ? _c("img", {
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/exclamationmark_white.svg",
                                                  width: "48"
                                                }
                                              })
                                            : _vm.channel.online
                                            ? _c("img", {
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/heart_white.svg",
                                                  width: "48"
                                                }
                                              })
                                            : _c("img", {
                                                staticClass:
                                                  "brighten-50 dark-img-brighten-50 dark-img-invert-100",
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/xmark_white.svg",
                                                  height: "48"
                                                }
                                              })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          key: _vm.channel
                                            ? "guardian-info-text-" +
                                              _vm.channel.online +
                                              "-" +
                                              _vm.channel.orders
                                            : "guardian-info-text-null",
                                          staticClass:
                                            "float-left line-height-sm"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "font-size-140p uppercase"
                                            },
                                            [
                                              _vm.channel === null
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("CONNECTING")
                                                      )
                                                    )
                                                  ])
                                                : _vm.channel.online === 1 &&
                                                  _vm.channel.orders === 0
                                                ? _c("span", [_vm._v("ON")])
                                                : _vm.channel.online === 1 &&
                                                  _vm.channel.orders === 1
                                                ? _c("span", [
                                                    _vm._v("ATTENTION")
                                                  ])
                                                : _vm.channel === false
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("NEW"))
                                                    )
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("Off"))
                                                    )
                                                  ])
                                            ]
                                          ),
                                          _vm.channel === false
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-weight-regular font-size-100p block on-top-sm",
                                                  attrs: { slot: "subtitle" },
                                                  slot: "subtitle"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "EMPTY_COMMUNICATION"
                                                        )
                                                      ) +
                                                      "\n            "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.channel
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-weight-regular font-size-100p block on-top-sm",
                                                  attrs: { slot: "subtitle" },
                                                  slot: "subtitle"
                                                },
                                                [
                                                  _vm.channel.online
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "Personalize your lifestyle"
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          "Check back next time."
                                                        )
                                                      ])
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "absolute text-family-brand text-weight-semibold absolute-top-right on-left-lg on-top-lg",
                                  attrs: { slot: "right" },
                                  slot: "right"
                                },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        appear: "",
                                        "enter-active-class":
                                          "animated bounceIn animated-d800",
                                        "leave-active-class":
                                          "animated bounceOut animated-d800"
                                      }
                                    },
                                    [
                                      _vm.channel && _vm.channel.online === 1
                                        ? _c(
                                            "span",
                                            { staticClass: "chip-live" },
                                            [_vm._v("LIVE")]
                                          )
                                        : _vm.channel &&
                                          _vm.channel.online === false
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "hidden text-subinfo-l"
                                            },
                                            [_vm._v("3:22 hours ago")]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.channel !== null &&
                          _vm.channel !== false &&
                          _vm.channel.online
                            ? _c("q-card-separator", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.$q.platform.has.touch,
                                    expression: "!$q.platform.has.touch"
                                  }
                                ]
                              })
                            : _vm._e(),
                          _vm.channel !== null &&
                          _vm.channel !== false &&
                          _vm.channel.online
                            ? _c(
                                "q-card-actions",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.$q.platform.has.touch,
                                      expression: "!$q.platform.has.touch"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass: "full-width",
                                      attrs: {
                                        color: "white",
                                        flat: "",
                                        rounded: "",
                                        label: _vm.$t("VIEW")
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.personalizeList()
                                          _vm.soundPlay("sheet_up")
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "q-tooltip",
                                        {
                                          attrs: { delay: 2000, inverted: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(_vm.$t("VIEW")) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm.channel !== null && _vm.channel !== false
                    ? [
                        _c(
                          "transition",
                          {
                            attrs: {
                              appear: "",
                              "enter-active-class":
                                "animated fadeInUp animated-d800",
                              "leave-active-class":
                                "animated fadeOutUp animated-d800"
                            }
                          },
                          [
                            _vm.channel === null || _vm.channel === false
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "on-top-xl text-family-brand text-weight-semibold text-shallow capitalize"
                                  },
                                  [_vm._v("--")]
                                )
                              : _vm._e(),
                            !_vm.channel || !_vm.channel.online
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "on-top-xl text-family-brand text-weight-semibold text-shallow capitalize"
                                  },
                                  [_vm._v(_vm._s(_vm.$t("COMMUNICATION.LAST")))]
                                )
                              : _vm._e(),
                            _vm.channel && _vm.channel.online
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "on-top-xl text-family-brand text-weight-semibold text-shallow capitalize"
                                  },
                                  [_vm._v(_vm._s(_vm.$t("COMMUNICATION.LIVE")))]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "transition",
                          {
                            attrs: {
                              appear: "",
                              "enter-active-class":
                                "animated fadeInUp animated-d800",
                              "leave-active-class":
                                "animated fadeOutDown animated-d800"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "full-width limit-width-420 dev-width-breath text-family-brand text-center row justify-around items-center on-top-default"
                              },
                              [
                                _c(
                                  "q-card",
                                  {
                                    directives: [
                                      {
                                        name: "touch-pan",
                                        rawName: "v-touch-pan.noMouse",
                                        value: function(obj) {
                                          _vm.setCardIntent(
                                            obj,
                                            "queue",
                                            _vm.dialogQueueOpen
                                          )
                                        },
                                        expression:
                                          "(obj) => { setCardIntent(obj, 'queue', dialogQueueOpen) }",
                                        modifiers: { noMouse: true }
                                      }
                                    ],
                                    ref: "product-card-queue",
                                    staticClass:
                                      "overflow-hidden q-card-grouped q-card-widget animated800 animated-c1",
                                    class: { "animate-bounce": _vm.bagUpdated },
                                    staticStyle: {
                                      width: "46%",
                                      "min-height": "164px"
                                    },
                                    attrs: {
                                      disabled:
                                        !_vm.channel || !_vm.channel.online,
                                      inline: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "q-card-title",
                                      { staticStyle: { height: "96px" } },
                                      [
                                        _c("img", {
                                          class: {
                                            "filter-invert-80": _vm.anyDarkmode
                                          },
                                          staticStyle: {
                                            width: "54px",
                                            "margin-top": "18px"
                                          },
                                          attrs: {
                                            src:
                                              "/statics/_demo/stack.processing_black.svg"
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "q-card-main",
                                      {
                                        staticStyle: {
                                          padding: "8px 0px 8px 0px"
                                        }
                                      },
                                      [
                                        _vm.channel &&
                                        _vm.channel.service_time >= 0
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-size-180p text-weight-bold text-empower",
                                                attrs: { dir: "ltr" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n              QUEUE\n              "
                                                ),
                                                _c("br"),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-shallow"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.requests_user_count
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "em",
                                                  {
                                                    staticClass:
                                                      "text-shallow font-size-100p on-left-xs"
                                                  },
                                                  [_vm._v("of")]
                                                ),
                                                _vm._v(
                                                  _vm._s(
                                                    (_vm.channel &&
                                                      _vm.channel.requests &&
                                                      _vm.channel.requests
                                                        .length) ||
                                                      0
                                                  ) + "\n            "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "block text-shallow text-weight-bold uppercase"
                                          },
                                          [_vm._v("items")]
                                        )
                                      ]
                                    ),
                                    _c("q-card-separator", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.$q.platform.has.touch,
                                          expression: "!$q.platform.has.touch"
                                        }
                                      ]
                                    }),
                                    _c(
                                      "q-card-actions",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.$q.platform.has.touch,
                                            expression: "!$q.platform.has.touch"
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "q-btn",
                                          {
                                            staticClass: "full-width",
                                            attrs: {
                                              color: "primary",
                                              flat: "",
                                              rounded: "",
                                              label: _vm.$t("VIEW")
                                            },
                                            on: { click: _vm.dialogQueueOpen }
                                          },
                                          [
                                            _c(
                                              "q-tooltip",
                                              {
                                                attrs: {
                                                  delay: 2000,
                                                  inverted: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(_vm.$t("VIEW")) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "q-card",
                                  {
                                    directives: [
                                      {
                                        name: "touch-pan",
                                        rawName: "v-touch-pan.noMouse",
                                        value: function(obj) {
                                          _vm.setCardIntent(
                                            obj,
                                            "order-winglet",
                                            _vm.wingletDialogOpen
                                          )
                                        },
                                        expression:
                                          "(obj) => { setCardIntent(obj, 'order-winglet', wingletDialogOpen) }",
                                        modifiers: { noMouse: true }
                                      }
                                    ],
                                    ref: "product-card-order-winglet",
                                    staticClass:
                                      "overflow-hidden q-card-grouped q-card-widget",
                                    staticStyle: {
                                      width: "46%",
                                      "margin-top": "0",
                                      "min-height": "164px"
                                    },
                                    attrs: {
                                      disabled:
                                        !_vm.channel || !_vm.channel.online,
                                      inline: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "q-card-title",
                                      { staticStyle: { height: "96px" } },
                                      [
                                        _vm.intentions.dropoffs.lastscan
                                          ? [
                                              _c("div", [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass:
                                                      "q-spinner text-gold",
                                                    staticStyle: {
                                                      opacity: "0.2",
                                                      "max-width": "100%",
                                                      position: "absolute",
                                                      top: "-30px",
                                                      left: "0",
                                                      height: "170px"
                                                    },
                                                    attrs: {
                                                      stroke: "currentColor",
                                                      width: "20em",
                                                      height: "20em",
                                                      viewBox: "0 0 44 44",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "g",
                                                      {
                                                        attrs: {
                                                          fill: "none",
                                                          "fill-rule":
                                                            "evenodd",
                                                          "stroke-width": "2"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "circle",
                                                          {
                                                            attrs: {
                                                              cx: "22",
                                                              cy: "22",
                                                              r: "16.0582"
                                                            }
                                                          },
                                                          [
                                                            _c("animate", {
                                                              attrs: {
                                                                attributeName:
                                                                  "r",
                                                                begin: "0s",
                                                                dur: "2s",
                                                                values: "1; 20",
                                                                calcMode:
                                                                  "spline",
                                                                keyTimes:
                                                                  "0; 1",
                                                                keySplines:
                                                                  "0.165, 0.84, 0.44, 1",
                                                                repeatCount:
                                                                  "indefinite"
                                                              }
                                                            }),
                                                            _c("animate", {
                                                              attrs: {
                                                                attributeName:
                                                                  "stroke-opacity",
                                                                begin: "0s",
                                                                dur: "2s",
                                                                values: "1; 0",
                                                                calcMode:
                                                                  "spline",
                                                                keyTimes:
                                                                  "0; 1",
                                                                keySplines:
                                                                  "0.3, 0.61, 0.355, 1",
                                                                repeatCount:
                                                                  "indefinite"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c("img", {
                                                staticClass:
                                                  "block margin-auto-lr",
                                                staticStyle: {
                                                  position: "absolute",
                                                  top: "25px",
                                                  left: "calc(50% - 29px)"
                                                },
                                                attrs: {
                                                  src: require("assets/letsbutterfly-wings-winglet.png"),
                                                  height: "60"
                                                }
                                              })
                                            ]
                                          : _c("img", {
                                              class: {
                                                "filter-invert-80":
                                                  _vm.anyDarkmode
                                              },
                                              staticStyle: {
                                                width: "65px",
                                                "margin-top": "12px"
                                              },
                                              attrs: {
                                                src:
                                                  "/statics/_demo/winglet.svg"
                                              }
                                            })
                                      ],
                                      2
                                    ),
                                    _c(
                                      "q-card-main",
                                      {
                                        staticStyle: {
                                          padding: "8px 0px 8px 0px"
                                        }
                                      },
                                      [
                                        _vm.channel &&
                                        _vm.intentions.dropoffs.lastscan
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-size-180p text-weight-bold text-secondary",
                                                attrs: { dir: "ltr" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.intentions.dropoffs.lastscan.name.split(
                                                        " "
                                                      )[0]
                                                    ) +
                                                    "\n              "
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.intentions.dropoffs.lastscan.name.split(
                                                      " "
                                                    )[1]
                                                  ) + "\n            "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-size-180p text-weight-bold text-shallow"
                                              },
                                              [
                                                _vm._v("--"),
                                                _c("br"),
                                                _vm._v(" ")
                                              ]
                                            ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "block text-shallow text-weight-bold uppercase"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("DROPOFF.LABEL_TT"))
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c("q-card-separator", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.$q.platform.has.touch,
                                          expression: "!$q.platform.has.touch"
                                        }
                                      ]
                                    }),
                                    _c(
                                      "q-card-actions",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.$q.platform.has.touch,
                                            expression: "!$q.platform.has.touch"
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "q-btn",
                                          {
                                            staticClass: "full-width",
                                            attrs: {
                                              color: "primary",
                                              flat: "",
                                              rounded: "",
                                              label: _vm.$t("VIEW")
                                            },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.wingletDialogOpen(
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "q-tooltip",
                                              {
                                                attrs: {
                                                  delay: 2000,
                                                  inverted: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(_vm.$t("VIEW")) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._l(_vm.offerings.groups, function(group, index) {
                          return [
                            group.list
                              ? _c(
                                  "div",
                                  {
                                    key: "product-groups-" + index,
                                    staticClass:
                                      "row justify-between items-center margin-auto-lr datagroup-container on-top-xl"
                                  },
                                  [
                                    _c(
                                      "transition",
                                      {
                                        attrs: {
                                          appear: "",
                                          "enter-active-class":
                                            "animated fadeInUp animated-d800"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "full-width title-group text-family-brand text-weight-bolder text-center uppercase"
                                          },
                                          [
                                            _c("img", {
                                              staticClass:
                                                "block margin-auto-lr opacity-3",
                                              staticStyle: {
                                                "margin-bottom": "-8px"
                                              },
                                              attrs: {
                                                src:
                                                  "/statics/_demo/persona.styles." +
                                                  index +
                                                  ".svg",
                                                width: "40"
                                              }
                                            }),
                                            _vm._v(
                                              "\n            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    index.toUpperCase() +
                                                      ".LABEL"
                                                  )
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    group.description
                                      ? _c(
                                          "p",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.settings_display_groups_description,
                                                expression:
                                                  "settings_display_groups_description"
                                              }
                                            ],
                                            staticClass:
                                              "full-width text-center text-family-brand text-weight-bold font-size-140p text-shallow",
                                            staticStyle: {
                                              margin: "10px auto 0px auto"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n          " +
                                                _vm._s(group.description) +
                                                "\n        "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._l(group.list, function(item) {
                                      return [
                                        _vm.offerings_computed[item] &&
                                        _vm.channel[
                                          _vm.offerings.items[
                                            _vm.offerings_computed[item].offer
                                          ].station + "_staff"
                                        ] !== 0
                                          ? _c(
                                              "transition",
                                              {
                                                key:
                                                  "product-groups-" +
                                                  index +
                                                  "-" +
                                                  item,
                                                attrs: {
                                                  appear: "",
                                                  "enter-active-class":
                                                    "animated fadeInUp animated-d800"
                                                }
                                              },
                                              [
                                                _c(
                                                  "q-card",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "touch-pan",
                                                        rawName:
                                                          "v-touch-pan.noMouse",
                                                        value: function(obj) {
                                                          _vm.setCardIntent(
                                                            obj,
                                                            [
                                                              "item",
                                                              index,
                                                              item
                                                            ].join("-"),
                                                            _vm.processCardSelection
                                                          )
                                                        },
                                                        expression:
                                                          "(obj) => { setCardIntent(obj, ['item', index, item].join('-'), processCardSelection) }",
                                                        modifiers: {
                                                          noMouse: true
                                                        }
                                                      }
                                                    ],
                                                    ref:
                                                      "product-card-" +
                                                      [
                                                        "item",
                                                        index,
                                                        item
                                                      ].join("-"),
                                                    refInFor: true,
                                                    staticClass:
                                                      "overflow-hidden q-card-grouped q-card-widget q-card-title-columns",
                                                    attrs: {
                                                      inline: "",
                                                      "data-index": [
                                                        "item",
                                                        index,
                                                        item
                                                      ].join("-"),
                                                      "data-item": item
                                                    }
                                                  },
                                                  [
                                                    _c("q-card-title", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "q-card-title-columns-wrapper"
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "q-card-title-columns-hero",
                                                            style:
                                                              "background-image: url(" +
                                                              _vm
                                                                .offerings_computed[
                                                                item
                                                              ].products[0]
                                                                .photo +
                                                              ");"
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "q-card-title-columns-content text-weight-semibold font-size-180p capitalize"
                                                            },
                                                            [
                                                              _vm
                                                                .offerings_computed[
                                                                item
                                                              ].products[0]
                                                                .signature
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "q-chip q-chip-card q-chip-info"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "PH"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "padding-top":
                                                                      "10px"
                                                                  }
                                                                },
                                                                [
                                                                  _vm
                                                                    .offerings_computed[
                                                                    item
                                                                  ].products
                                                                    .length ===
                                                                  1
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "hidden"
                                                                        },
                                                                        [
                                                                          _vm._l(
                                                                            _vm
                                                                              .offerings_computed[
                                                                              item
                                                                            ]
                                                                              .products[0]
                                                                              .personas,
                                                                            function(
                                                                              persona
                                                                            ) {
                                                                              return [
                                                                                _vm
                                                                                  .offerings
                                                                                  .personas
                                                                                  .bases[
                                                                                  persona
                                                                                ]
                                                                                  ? _c(
                                                                                      "img",
                                                                                      {
                                                                                        key:
                                                                                          "offerings-" +
                                                                                          item +
                                                                                          "-" +
                                                                                          persona,
                                                                                        staticClass:
                                                                                          "brighten-50 dark-img-invert-100 on-left-sm",
                                                                                        staticStyle: {
                                                                                          height:
                                                                                            "30px"
                                                                                        },
                                                                                        attrs: {
                                                                                          src:
                                                                                            "/statics/_demo/persona." +
                                                                                            _vm
                                                                                              .offerings
                                                                                              .personas
                                                                                              .bases[
                                                                                              persona
                                                                                            ]
                                                                                              .group +
                                                                                            "." +
                                                                                            persona +
                                                                                            ".svg"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : _vm._e()
                                                                              ]
                                                                            }
                                                                          )
                                                                        ],
                                                                        2
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "q-card-detail-chips hidden"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "q-chip",
                                                                            {
                                                                              staticClass:
                                                                                "inline-block lowercase",
                                                                              staticStyle: {
                                                                                "margin-top":
                                                                                  "-30px"
                                                                              },
                                                                              attrs: {
                                                                                dense:
                                                                                  "",
                                                                                "text-color":
                                                                                  "white",
                                                                                color:
                                                                                  "purple-l2"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                          multiple "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-weight-regular"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "options"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle: {
                                                                        "margin-top":
                                                                          "4px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .offerings
                                                                            .items[
                                                                            _vm
                                                                              .offerings_computed[
                                                                              item
                                                                            ]
                                                                              .offer
                                                                          ].name
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm
                                                                    .offerings_computed[
                                                                    item
                                                                  ].products[0]
                                                                    .size
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "q-chip",
                                                                            {
                                                                              staticClass:
                                                                                "q-chip-info",
                                                                              attrs: {
                                                                                dense:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .offerings_computed[
                                                                                    item
                                                                                  ]
                                                                                    .products[0]
                                                                                    .size
                                                                                    .value
                                                                                ) +
                                                                                  " " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .offerings_computed[
                                                                                      item
                                                                                    ]
                                                                                      .products[0]
                                                                                      .size
                                                                                      .unit
                                                                                  ) +
                                                                                  "."
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm
                                                                    .offerings_computed[
                                                                    item
                                                                  ].products[0]
                                                                    .computes
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "q-card-detail-chips"
                                                                        },
                                                                        [
                                                                          _vm
                                                                            .offerings_computed[
                                                                            item
                                                                          ]
                                                                            .products[0]
                                                                            .computes
                                                                            .lowcarb
                                                                            ? _c(
                                                                                "q-chip",
                                                                                {
                                                                                  staticClass:
                                                                                    "inline-block lowercase",
                                                                                  staticStyle: {
                                                                                    "margin-bottom":
                                                                                      "0px"
                                                                                  },
                                                                                  attrs: {
                                                                                    dense:
                                                                                      "",
                                                                                    "text-color":
                                                                                      "white",
                                                                                    color:
                                                                                      "value"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                          lowcarb\n                        "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e()
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-weight-semibold on-right-xs font-size-60p",
                                                                  staticStyle: {
                                                                    "margin-bottom":
                                                                      "-4px",
                                                                    transform:
                                                                      "scale(0.85)",
                                                                    "margin-left":
                                                                      "-10px"
                                                                  }
                                                                },
                                                                [
                                                                  _vm
                                                                    .offerings_computed[
                                                                    item
                                                                  ].products
                                                                    .length > 1
                                                                    ? [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "q-card-detail-chips"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "q-chip",
                                                                              {
                                                                                staticClass:
                                                                                  "inline-block lowercase",
                                                                                staticStyle: {
                                                                                  "margin-left":
                                                                                    "-20px",
                                                                                  "margin-top":
                                                                                    "-30px",
                                                                                  "border-top-left-radius":
                                                                                    "0",
                                                                                  "border-bottom-left-radius":
                                                                                    "0"
                                                                                },
                                                                                attrs: {
                                                                                  dense:
                                                                                    "",
                                                                                  "text-color":
                                                                                    "white",
                                                                                  color:
                                                                                    "purple-l2"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                          multiple "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-weight-regular"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "options"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "q-list",
                                                                          {
                                                                            staticClass:
                                                                              "denser",
                                                                            staticStyle: {
                                                                              width:
                                                                                "99%"
                                                                            },
                                                                            attrs: {
                                                                              dense:
                                                                                "",
                                                                              "no-border":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._l(
                                                                              _vm
                                                                                .offerings_computed[
                                                                                item
                                                                              ]
                                                                                .products,
                                                                              function(
                                                                                product,
                                                                                ip
                                                                              ) {
                                                                                return [
                                                                                  _vm._l(
                                                                                    product.contains,
                                                                                    function(
                                                                                      contains,
                                                                                      ic
                                                                                    ) {
                                                                                      return [
                                                                                        contains.mutator
                                                                                          ? _c(
                                                                                              "q-item",
                                                                                              {
                                                                                                key:
                                                                                                  "offerings-" +
                                                                                                  item +
                                                                                                  "-options-" +
                                                                                                  contains.base +
                                                                                                  "-" +
                                                                                                  ip +
                                                                                                  ic,
                                                                                                staticClass:
                                                                                                  "font-size-80p"
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "q-item-main",
                                                                                                  [
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "text-purple-l2 text-weight-bolder inline-block",
                                                                                                        staticStyle: {
                                                                                                          width:
                                                                                                            "16px"
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            ip +
                                                                                                              1
                                                                                                          ) +
                                                                                                            "."
                                                                                                        )
                                                                                                      ]
                                                                                                    ),
                                                                                                    _vm._v(
                                                                                                      "\n                                  " +
                                                                                                        _vm._s(
                                                                                                          contains.modifiers.join()
                                                                                                        ) +
                                                                                                        " " +
                                                                                                        _vm._s(
                                                                                                          contains.base.replace(
                                                                                                            /\_/g,
                                                                                                            " "
                                                                                                          )
                                                                                                        ) +
                                                                                                        "\n                                "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          : _vm._e()
                                                                                      ]
                                                                                    }
                                                                                  )
                                                                                ]
                                                                              }
                                                                            )
                                                                          ],
                                                                          2
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  _vm
                                                                    .offerings_computed[
                                                                    item
                                                                  ].products
                                                                    .length ===
                                                                  1
                                                                    ? [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "no-margin text-subinfo-l lowercase font-size-80p"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "highlights"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "q-list",
                                                                          {
                                                                            staticClass:
                                                                              "denser",
                                                                            staticStyle: {
                                                                              width:
                                                                                "99%",
                                                                              "margin-top":
                                                                                "-4px"
                                                                            },
                                                                            attrs: {
                                                                              dense:
                                                                                "",
                                                                              "no-border":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._l(
                                                                              _vm
                                                                                .offerings_computed[
                                                                                item
                                                                              ]
                                                                                .products[0]
                                                                                .contains,
                                                                              function(
                                                                                contains
                                                                              ) {
                                                                                return [
                                                                                  contains.highlight
                                                                                    ? _c(
                                                                                        "q-item",
                                                                                        {
                                                                                          key:
                                                                                            "offerings-" +
                                                                                            item +
                                                                                            "-highlight-" +
                                                                                            contains.base +
                                                                                            "-" +
                                                                                            ((contains.modifiers &&
                                                                                              contains.modifiers.join(
                                                                                                "_"
                                                                                              )) ||
                                                                                              ""),
                                                                                          staticClass:
                                                                                            "font-size-100p"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "q-item-main",
                                                                                            [
                                                                                              _c(
                                                                                                "img",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "brighten-50 dark-img-invert-100 vertical-middle on-left-sm float-left",
                                                                                                  staticStyle: {
                                                                                                    width:
                                                                                                      "30px"
                                                                                                  },
                                                                                                  attrs: {
                                                                                                    src:
                                                                                                      "/statics/_demo/persona." +
                                                                                                      _vm
                                                                                                        .offerings
                                                                                                        .personas
                                                                                                        .bases[
                                                                                                        contains
                                                                                                          .base
                                                                                                      ]
                                                                                                        .group +
                                                                                                      "." +
                                                                                                      (_vm
                                                                                                        .offerings
                                                                                                        .personas
                                                                                                        .bases[
                                                                                                        contains
                                                                                                          .base
                                                                                                      ]
                                                                                                        .family ||
                                                                                                        contains.base) +
                                                                                                      ".svg"
                                                                                                  }
                                                                                                }
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "inline-block",
                                                                                                  staticStyle: {
                                                                                                    padding:
                                                                                                      "4px 4px 0px 0px"
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      contains.base.replace(
                                                                                                        /\_/g,
                                                                                                        " "
                                                                                                      )
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm.settings_display_cards_mode
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticStyle: {
                                                                                                        clear:
                                                                                                          "both",
                                                                                                        "padding-left":
                                                                                                          "34px"
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      contains.qty >
                                                                                                      1
                                                                                                        ? _c(
                                                                                                            "q-chip",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "dark-img-invert-100 q-chip-subinfo on-left-xs",
                                                                                                              attrs: {
                                                                                                                dense:
                                                                                                                  ""
                                                                                                              }
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "×" +
                                                                                                                  _vm._s(
                                                                                                                    contains.qty
                                                                                                                  )
                                                                                                              )
                                                                                                            ]
                                                                                                          )
                                                                                                        : _vm._e(),
                                                                                                      _vm._l(
                                                                                                        contains.modifiers,
                                                                                                        function(
                                                                                                          modifier
                                                                                                        ) {
                                                                                                          return _c(
                                                                                                            "q-chip",
                                                                                                            {
                                                                                                              key:
                                                                                                                "offerings-" +
                                                                                                                item +
                                                                                                                "-highlight-" +
                                                                                                                contains.base +
                                                                                                                "-" +
                                                                                                                modifier,
                                                                                                              staticClass:
                                                                                                                "q-chip-subinfo on-bottom-xs on-left-xs dark-img-invert-100",
                                                                                                              attrs: {
                                                                                                                dense:
                                                                                                                  ""
                                                                                                              }
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  modifier
                                                                                                                )
                                                                                                              )
                                                                                                            ]
                                                                                                          )
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    2
                                                                                                  )
                                                                                                : _vm._e()
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              }
                                                                            )
                                                                          ],
                                                                          2
                                                                        )
                                                                      ]
                                                                    : _vm._e()
                                                                ],
                                                                2
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c("q-card-separator", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.settings_display_cards_mode,
                                                          expression:
                                                            "settings_display_cards_mode"
                                                        }
                                                      ]
                                                    }),
                                                    _vm.settings_display_cards_mode
                                                      ? _c(
                                                          "q-card-main",
                                                          {
                                                            staticClass:
                                                              "q-card-title-columns-wrapper text-family-brand text-subinfo",
                                                            staticStyle: {
                                                              "background-color":
                                                                "#f8f8f8",
                                                              "min-height":
                                                                "96px",
                                                              height: "96px",
                                                              "padding-top": "0"
                                                            }
                                                          },
                                                          [
                                                            _vm
                                                              .offerings_computed[
                                                              item
                                                            ].products
                                                              .length === 1
                                                              ? [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "q-card-title-columns-hero",
                                                                      staticStyle: {
                                                                        "min-height":
                                                                          "auto",
                                                                        top:
                                                                          "0",
                                                                        height:
                                                                          "120px",
                                                                        "padding-left":
                                                                          "20px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-weight-black inline-block",
                                                                          staticStyle: {
                                                                            "font-size":
                                                                              "400%",
                                                                            "margin-bottom":
                                                                              "-20px"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .offerings_computed[
                                                                                item
                                                                              ]
                                                                                .products[0]
                                                                                .nutrition
                                                                                .calories
                                                                            ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-weight-semibold text-subinfo-l block"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "calories"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "q-card-title-columns-content",
                                                                      staticStyle: {
                                                                        "min-height":
                                                                          "auto"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "columns flex justify-between",
                                                                          staticStyle: {
                                                                            "padding-top":
                                                                              "20px",
                                                                            width:
                                                                              "100%"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "pill inline-block bg-gold",
                                                                              style:
                                                                                "width:calc(" +
                                                                                _vm
                                                                                  .offerings_computed[
                                                                                  item
                                                                                ]
                                                                                  .products[0]
                                                                                  .nutrition
                                                                                  .p_portion *
                                                                                  100 +
                                                                                "% - 4px); margin-left: 0px"
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "pill inline-block bg-green",
                                                                              style:
                                                                                "width:calc(" +
                                                                                _vm
                                                                                  .offerings_computed[
                                                                                  item
                                                                                ]
                                                                                  .products[0]
                                                                                  .nutrition
                                                                                  .c_portion *
                                                                                  100 +
                                                                                "% - 4px)"
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "pill inline-block bg-red",
                                                                              style:
                                                                                "width:calc(" +
                                                                                _vm
                                                                                  .offerings_computed[
                                                                                  item
                                                                                ]
                                                                                  .products[0]
                                                                                  .nutrition
                                                                                  .f_portion *
                                                                                  100 +
                                                                                "% - 4px); margin-right: 0px"
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "text-weight-black font-size-120p",
                                                                          staticStyle: {
                                                                            "margin-top":
                                                                              "-20px",
                                                                            display:
                                                                              "flex",
                                                                            "flex-direction":
                                                                              "row",
                                                                            "justify-content":
                                                                              "space-between",
                                                                            "align-items":
                                                                              "flex-start"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticStyle: {
                                                                                    "font-size":
                                                                                      "140%"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .offerings_computed[
                                                                                        item
                                                                                      ]
                                                                                        .products[0]
                                                                                        .nutrition
                                                                                        .macronutrients
                                                                                        .p
                                                                                    ) +
                                                                                      "g"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "block text-weight-semibold text-subinfo-l",
                                                                                  staticStyle: {
                                                                                    "font-size":
                                                                                      "14px",
                                                                                    "margin-top":
                                                                                      "-6px"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "protein"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticStyle: {
                                                                                    "font-size":
                                                                                      "140%"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .offerings_computed[
                                                                                        item
                                                                                      ]
                                                                                        .products[0]
                                                                                        .nutrition
                                                                                        .macronutrients
                                                                                        .c
                                                                                    ) +
                                                                                      "g"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "block text-weight-semibold text-subinfo-l",
                                                                                  staticStyle: {
                                                                                    "font-size":
                                                                                      "14px",
                                                                                    "margin-top":
                                                                                      "-6px"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "carb."
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticStyle: {
                                                                                    "font-size":
                                                                                      "140%"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .offerings_computed[
                                                                                        item
                                                                                      ]
                                                                                        .products[0]
                                                                                        .nutrition
                                                                                        .macronutrients
                                                                                        .f
                                                                                    ) +
                                                                                      "g"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "block text-weight-semibold text-subinfo-l",
                                                                                  staticStyle: {
                                                                                    "font-size":
                                                                                      "14px",
                                                                                    "margin-top":
                                                                                      "-6px"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fat"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              : [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "text-weight-semibold text-disabled",
                                                                      staticStyle: {
                                                                        display:
                                                                          "flex",
                                                                        "flex-direction":
                                                                          "row",
                                                                        "justify-content":
                                                                          "center",
                                                                        "align-items":
                                                                          "center",
                                                                        "font-size":
                                                                          "14px",
                                                                        "margin-bottom":
                                                                          "-16px",
                                                                        width:
                                                                          "100%"
                                                                      }
                                                                    },
                                                                    _vm._l(
                                                                      3,
                                                                      function(
                                                                        i
                                                                      ) {
                                                                        return _c(
                                                                          "hr",
                                                                          {
                                                                            key:
                                                                              "k-hr-dor-" +
                                                                              i,
                                                                            staticClass:
                                                                              "dot",
                                                                            staticStyle: {
                                                                              margin:
                                                                                "4px"
                                                                            }
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                ]
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                    _c("q-card-separator", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: !_vm.$q
                                                            .platform.has.touch,
                                                          expression:
                                                            "!$q.platform.has.touch"
                                                        }
                                                      ]
                                                    }),
                                                    _c(
                                                      "q-card-actions",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: !_vm.$q
                                                              .platform.has
                                                              .touch,
                                                            expression:
                                                              "!$q.platform.has.touch"
                                                          }
                                                        ]
                                                      },
                                                      [
                                                        _c(
                                                          "q-btn",
                                                          {
                                                            staticClass:
                                                              "full-width",
                                                            attrs: {
                                                              color: "primary",
                                                              flat: "",
                                                              rounded: "",
                                                              label: _vm.$t(
                                                                "VIEW"
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.processCardSelection(
                                                                  "item-" +
                                                                    index +
                                                                    "-" +
                                                                    item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "q-tooltip",
                                                              {
                                                                attrs: {
                                                                  delay: 2000,
                                                                  inverted: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "VIEW"
                                                                      )
                                                                    ) +
                                                                    "\n                  "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }),
                                    _c("q-card", {
                                      staticClass:
                                        "overflow-hidden q-card-grouped q-card-widget invisible",
                                      staticStyle: { "min-height": "0" },
                                      attrs: { inline: "" }
                                    })
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        }),
                        _vm._l(_vm.datagroups, function(datagroup, index) {
                          return [
                            !datagroup.types ||
                            (datagroup.types &&
                              datagroup.types.filter(function(el) {
                                return _vm.product.data.business.types.includes(
                                  el
                                )
                              }).length)
                              ? _c(
                                  "div",
                                  {
                                    key:
                                      "product-datagroup-" +
                                      datagroup.id +
                                      "-" +
                                      index,
                                    staticClass:
                                      "row justify-between items-center margin-auto-lr datagroup-container on-top-xl hidden"
                                  },
                                  [
                                    _c(
                                      "transition",
                                      {
                                        attrs: {
                                          appear: "",
                                          "enter-active-class":
                                            "animated fadeInUp animated-d800"
                                        }
                                      },
                                      [
                                        datagroup.renderInList !== false
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "full-width title-group text-family-brand text-weight-bolder text-center uppercase",
                                                class: {
                                                  "on-top-xx": index > 0
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      datagroup.id.toUpperCase() +
                                                        ".LABEL_TT"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._l(datagroup.datafields, function(
                                      datafield,
                                      ix
                                    ) {
                                      return [
                                        datafield.signal.bCheck() !== false &&
                                        datafield.archive !== true &&
                                        datafield.renderInList !== false &&
                                        datagroup.renderInList !== false
                                          ? _c(
                                              "transition",
                                              {
                                                key:
                                                  "product-datagroup-" +
                                                  datagroup.id +
                                                  "-" +
                                                  index +
                                                  "-" +
                                                  datafield.id,
                                                attrs: {
                                                  appear: "",
                                                  "enter-active-class":
                                                    "animated fadeInUp animated-d800",
                                                  "leave-active-class":
                                                    "animated fadeOutDown animated-d800"
                                                }
                                              },
                                              [
                                                (!datafield.types ||
                                                  datafield.types.filter(
                                                    function(el) {
                                                      return _vm.product.data.business.types.includes(
                                                        el
                                                      )
                                                    }
                                                  ).length) &&
                                                (!datafield.notTypes ||
                                                  !datafield.notTypes.filter(
                                                    function(el) {
                                                      return _vm.product.data.business.types.includes(
                                                        el
                                                      )
                                                    }
                                                  ).length)
                                                  ? _c(
                                                      "q-card",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "touch-pan",
                                                            rawName:
                                                              "v-touch-pan.noMouse",
                                                            value: function(
                                                              obj
                                                            ) {
                                                              _vm.setCardIntent(
                                                                obj,
                                                                [
                                                                  datagroup.id,
                                                                  index,
                                                                  datafield.id,
                                                                  ix
                                                                ].join("-"),
                                                                _vm.processCardSelection
                                                              )
                                                            },
                                                            expression:
                                                              "(obj) => { setCardIntent(obj, [datagroup.id, index, datafield.id, ix].join('-'), processCardSelection) }",
                                                            modifiers: {
                                                              noMouse: true
                                                            }
                                                          }
                                                        ],
                                                        ref:
                                                          "product-card-" +
                                                          [
                                                            datagroup.id,
                                                            index,
                                                            datafield.id,
                                                            ix
                                                          ].join("-"),
                                                        refInFor: true,
                                                        staticClass:
                                                          "overflow-hidden q-card-grouped q-card-widget",
                                                        attrs: {
                                                          inline: "",
                                                          "data-index": [
                                                            datagroup.id,
                                                            index,
                                                            datafield.id,
                                                            ix
                                                          ].join("-"),
                                                          disabled:
                                                            datafield.indicates &&
                                                            datafield.indicates
                                                              .requiresValue &&
                                                            !datafield.indicates.aux()
                                                              .value
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "q-card-title",
                                                          {
                                                            staticStyle: {
                                                              "min-height":
                                                                "150px"
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "card-title-bgicon absolute",
                                                              class: {
                                                                "filter-invert-100":
                                                                  _vm.anyDarkmode
                                                              },
                                                              style:
                                                                datafield.brand ===
                                                                true
                                                                  ? "opacity:0.02"
                                                                  : "",
                                                              attrs: {
                                                                src:
                                                                  "/statics/_demo/" +
                                                                  datafield.icon +
                                                                  ".svg"
                                                              }
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "title-label block text-primary q-caption text-family-brand text-weight-semibold uppercase font-size-100p"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                  " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        [
                                                                          datafield.id,
                                                                          "LABEL"
                                                                        ]
                                                                          .join(
                                                                            "."
                                                                          )
                                                                          .toUpperCase()
                                                                      )
                                                                    ) +
                                                                    "\n                "
                                                                )
                                                              ]
                                                            ),
                                                            datafield.indicates &&
                                                            datafield.indicates
                                                              .aux
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-family-brand text-weight-semibold lowercase block font-size-140p ellipsis",
                                                                    class:
                                                                      datafield.indicates.aux()
                                                                        .state ===
                                                                      1
                                                                        ? "text-educate"
                                                                        : "text-attention",
                                                                    staticStyle: {
                                                                      "margin-top":
                                                                        "10px"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                  " +
                                                                        _vm._s(
                                                                          datafield.indicates.aux()
                                                                            .text
                                                                        ) +
                                                                        "\n                "
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "card-title-note text-family-brand text-weight-semibold lowercase block font-size-140p"
                                                              },
                                                              [
                                                                datafield.signal.bCheck() ===
                                                                true
                                                                  ? [
                                                                      _c(
                                                                        "q-icon",
                                                                        {
                                                                          staticClass:
                                                                            "text-educate",
                                                                          attrs: {
                                                                            name:
                                                                              "ion-checkmark-circle"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                    " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "AVAILABLE"
                                                                            )
                                                                          ) +
                                                                          "\n                  "
                                                                      )
                                                                    ]
                                                                  : [
                                                                      _vm._v(
                                                                        "\n                    " +
                                                                          _vm._s(
                                                                            datafield.signal.descriptor()
                                                                          ) +
                                                                          "\n                  "
                                                                      )
                                                                    ]
                                                              ],
                                                              2
                                                            )
                                                          ]
                                                        ),
                                                        _c("q-card-separator", {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: !_vm.$q
                                                                .platform.has
                                                                .touch,
                                                              expression:
                                                                "!$q.platform.has.touch"
                                                            }
                                                          ]
                                                        }),
                                                        _c(
                                                          "q-card-actions",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: !_vm.$q
                                                                  .platform.has
                                                                  .touch,
                                                                expression:
                                                                  "!$q.platform.has.touch"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            datafield.type ===
                                                            "phone"
                                                              ? _c(
                                                                  "q-btn",
                                                                  {
                                                                    staticClass:
                                                                      "full-width",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      flat: "",
                                                                      rounded:
                                                                        "",
                                                                      label: _vm.$t(
                                                                        "CALL"
                                                                      )
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.call(
                                                                          datafield.indicates.aux()
                                                                            .text
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "q-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          delay: 2000,
                                                                          inverted:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                    " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "CALL"
                                                                              )
                                                                            ) +
                                                                            "\n                  "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : datafield.type ===
                                                                "link"
                                                              ? _c(
                                                                  "q-btn",
                                                                  {
                                                                    staticClass:
                                                                      "full-width",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      flat: "",
                                                                      rounded:
                                                                        "",
                                                                      label: _vm.$t(
                                                                        "OPEN"
                                                                      )
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.openURL(
                                                                          datafield.indicates.aux()
                                                                            .value
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "q-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          delay: 2000,
                                                                          inverted:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                    " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "OPEN"
                                                                              )
                                                                            ) +
                                                                            "\n                  "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  "q-btn",
                                                                  {
                                                                    staticClass:
                                                                      "full-width",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      flat: "",
                                                                      rounded:
                                                                        "",
                                                                      label: _vm.$t(
                                                                        "VIEW"
                                                                      )
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.processCardSelection(
                                                                          [
                                                                            datagroup.id,
                                                                            index,
                                                                            datafield.id,
                                                                            ix
                                                                          ].join(
                                                                            "-"
                                                                          )
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "q-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          delay: 2000,
                                                                          inverted:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                    " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "VIEW"
                                                                              )
                                                                            ) +
                                                                            "\n                  "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        })
                      ]
                    : _vm._e(),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: {
                        id: "dialogQueue",
                        position: "bottom",
                        "no-refocus": ""
                      },
                      model: {
                        value: _vm.dialogQueueShow,
                        callback: function($$v) {
                          _vm.dialogQueueShow = $$v
                        },
                        expression: "dialogQueueShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [_c("q-toolbar-title", [_vm._v("Queue")])],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscoll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          on: { click: _vm.dialogQueueClose }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/" +
                                                (_vm.anyDarkmode
                                                  ? "chevron.compact.down_white.svg"
                                                  : "chevron.compact.down_primary.svg"),
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col justify-between items-center margin-auto-lr"
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "text-center text-family-brand"
                                },
                                [
                                  _c("span", { staticClass: "text-shallow" }, [
                                    _vm._v(_vm._s(_vm.requests_user_count))
                                  ]),
                                  _c(
                                    "em",
                                    {
                                      staticClass:
                                        "text-shallow font-size-100p on-left-xs"
                                    },
                                    [_vm._v("of")]
                                  ),
                                  _vm._v(
                                    _vm._s(
                                      (_vm.channel &&
                                        _vm.channel.requests &&
                                        _vm.channel.requests.length) ||
                                        0
                                    ) + "\n        "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-family-brand layout-padding no-padding-tb row justify-center items-center"
                                },
                                [
                                  _vm._l(_vm.channel.requests, function(
                                    request,
                                    ix
                                  ) {
                                    return _vm.channel && _vm.channel.requests
                                      ? [
                                          request.status &&
                                          request.user.publicAddress ===
                                            _vm.account.metadata.publicAddress
                                            ? _c(
                                                "transition",
                                                {
                                                  key:
                                                    "product-requests-" +
                                                    ix +
                                                    "-" +
                                                    request.status +
                                                    "-" +
                                                    request.user.publicAddress,
                                                  attrs: {
                                                    appear: "",
                                                    "enter-active-class":
                                                      "animated fadeInUp animated-d800"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "q-card",
                                                    {
                                                      staticClass:
                                                        "overflow-hidden q-card-grouped q-card-widget",
                                                      staticStyle: {
                                                        "max-width": "100%"
                                                      },
                                                      attrs: { inline: "" }
                                                    },
                                                    [
                                                      _c("q-card-title", [
                                                        _c("img", {
                                                          staticClass:
                                                            "avatar float-left",
                                                          staticStyle: {
                                                            width: "80px",
                                                            height: "80px",
                                                            "border-radius":
                                                              "17px",
                                                            "border-bottom-right-radius":
                                                              "0"
                                                          },
                                                          attrs: {
                                                            src:
                                                              request.item.photo
                                                          }
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "float-left"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "block on-right-sm capitalize text-weight-bold font-size-120p"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      request.info.name.replace(
                                                                        /\_/g,
                                                                        " "
                                                                      )
                                                                    ) +
                                                                    "\n                  "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "block on-right-sm"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "columns flex justify-between",
                                                                    staticStyle: {
                                                                      height:
                                                                        "48px"
                                                                    }
                                                                  },
                                                                  [
                                                                    request.status ===
                                                                    "completed"
                                                                      ? [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col-1"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  staticClass:
                                                                                    "brighten-50 dark-img-invert-50 vertical-middle on-left-sm",
                                                                                  attrs: {
                                                                                    src:
                                                                                      "/statics/_demo/checkmark.circle.fill.svg",
                                                                                    height:
                                                                                      "30"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "em",
                                                                                {
                                                                                  staticStyle: {
                                                                                    padding:
                                                                                      "0",
                                                                                    top:
                                                                                      "-7px",
                                                                                    position:
                                                                                      "relative"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "with"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _c(
                                                                                "b",
                                                                                {
                                                                                  staticClass:
                                                                                    "uppercase font-size-80p",
                                                                                  staticStyle: {
                                                                                    color:
                                                                                      "#004BA3",
                                                                                    position:
                                                                                      "relative",
                                                                                    top:
                                                                                      "-23px"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "CUSTOMER"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      : request.status ===
                                                                        "dropping-off"
                                                                      ? [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col-1"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  staticClass:
                                                                                    "brighten-50 dark-img-invert-50 vertical-middle on-left-sm",
                                                                                  attrs: {
                                                                                    src:
                                                                                      "/statics/_demo/viewfinder.qrcode.svg",
                                                                                    height:
                                                                                      "30"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "em",
                                                                                {
                                                                                  staticStyle: {
                                                                                    padding:
                                                                                      "0",
                                                                                    top:
                                                                                      "-7px",
                                                                                    position:
                                                                                      "relative"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "to"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _c(
                                                                                "b",
                                                                                {
                                                                                  staticClass:
                                                                                    "uppercase font-size-80p",
                                                                                  staticStyle: {
                                                                                    color:
                                                                                      "#004BA3",
                                                                                    position:
                                                                                      "relative",
                                                                                    top:
                                                                                      "-23px"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "PATIO #1"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      : request.status ===
                                                                        "in-progress"
                                                                      ? [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col-1"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  staticClass:
                                                                                    "brighten-50 dark-img-invert-50 vertical-middle on-left-sm animate-spin",
                                                                                  attrs: {
                                                                                    src:
                                                                                      "/statics/_demo/station.globe.svg",
                                                                                    height:
                                                                                      "30"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "em",
                                                                                {
                                                                                  staticStyle: {
                                                                                    padding:
                                                                                      "0",
                                                                                    top:
                                                                                      "-7px",
                                                                                    position:
                                                                                      "relative"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "at"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _c(
                                                                                "b",
                                                                                {
                                                                                  staticClass:
                                                                                    "uppercase font-size-80p",
                                                                                  staticStyle: {
                                                                                    color:
                                                                                      "#004BA3",
                                                                                    position:
                                                                                      "relative",
                                                                                    top:
                                                                                      "-23px"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      request.info.station.replace(
                                                                                        /\_/g,
                                                                                        " "
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      : [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col-1"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  staticClass:
                                                                                    "brighten-50 dark-img-invert-50 vertical-middle on-left-sm",
                                                                                  attrs: {
                                                                                    src:
                                                                                      "/statics/_demo/station.person.svg",
                                                                                    height:
                                                                                      "30"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "em",
                                                                                {
                                                                                  staticStyle: {
                                                                                    padding:
                                                                                      "0",
                                                                                    top:
                                                                                      "-7px",
                                                                                    position:
                                                                                      "relative"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "with"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _c(
                                                                                "b",
                                                                                {
                                                                                  staticClass:
                                                                                    "uppercase font-size-80p",
                                                                                  staticStyle: {
                                                                                    color:
                                                                                      "#004BA3",
                                                                                    position:
                                                                                      "relative",
                                                                                    top:
                                                                                      "-23px"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      request.location
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                  ],
                                                                  2
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "right"
                                                            },
                                                            slot: "right"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  position:
                                                                    "relative",
                                                                  top: "-22px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "block font-size-120p text-family-brand text-primary text-right text-weight-bold"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-subinfo-l"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "#"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        request.id
                                                                      ) +
                                                                        "\n                    "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "q-chip",
                                                                  {
                                                                    staticClass:
                                                                      "on-right-xs",
                                                                    class: {
                                                                      "q-chip-info":
                                                                        request.status ===
                                                                        "in-review",
                                                                      "q-chip-hint":
                                                                        request.status ===
                                                                        "in-progress",
                                                                      "q-chip-pro-fill":
                                                                        request.status ===
                                                                        "dropping-off",
                                                                      "q-chip-educate":
                                                                        request.status ===
                                                                        "completed"
                                                                    },
                                                                    attrs: {
                                                                      dense: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                      " +
                                                                        _vm._s(
                                                                          request.status.replace(
                                                                            /\_/g,
                                                                            " "
                                                                          )
                                                                        ) +
                                                                        "\n                    "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  })
                                ],
                                2
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer- dialog-item",
                      attrs: {
                        id: "dialogProductSend",
                        minimized: "",
                        "no-refocus": ""
                      },
                      model: {
                        value: _vm.dialogProductSendShow,
                        callback: function($$v) {
                          _vm.dialogProductSendShow = $$v
                        },
                        expression: "dialogProductSendShow"
                      }
                    },
                    [
                      _vm.dialogProductItem &&
                      _vm.offerings_computed[_vm.dialogProductItem]
                        ? _c(
                            "q-modal-layout",
                            [
                              _c(
                                "q-toolbar",
                                {
                                  attrs: { slot: "header", inverted: "" },
                                  slot: "header"
                                },
                                [
                                  _c(
                                    "q-toolbar-title",
                                    {
                                      staticStyle: {
                                        "text-transform": "capitalize"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            _vm.offerings.items[
                                              _vm.offerings_computed[
                                                _vm.dialogProductItem
                                              ].offer
                                            ].name
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-family-brand layout-padding no-padding-tb text-center row justify-center items-center",
                                  staticStyle: { "max-width": "500px" }
                                },
                                [
                                  _c("div", [
                                    _c("img", {
                                      staticClass: "avatar",
                                      staticStyle: {
                                        width: "120px",
                                        height: "120px",
                                        "border-radius": "0.3rem"
                                      },
                                      attrs: {
                                        src:
                                          _vm.offerings_computed[
                                            _vm.dialogProductItem
                                          ].products[0].photo
                                      }
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-weight-semibold no-margin-bottom on-top-default"
                                      },
                                      [
                                        _vm._v(
                                          "\n            Send now and it will be ready in\n          "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "full-width no-margin text-empower text-family-brand text-weight-bold",
                                        staticStyle: { "font-size": "44px" }
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.guardian.business.stations[
                                                _vm.offerings.items[
                                                  _vm.offerings_computed[
                                                    _vm.dialogProductItem
                                                  ].offer
                                                ].station
                                              ].times.wait +
                                                _vm.offerings_computed[
                                                  _vm.dialogProductItem
                                                ].products[0].time
                                            ) +
                                            ":00\n            "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-empower font-size-80p text-weight-bold block",
                                        staticStyle: { "margin-top": "-10px" }
                                      },
                                      [_vm._v("minutes")]
                                    )
                                  ]),
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "column margin-auto-lr q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top",
                                      staticStyle: {
                                        "min-height": "80px !important"
                                      }
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "block column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "limit-width-1024 full-width full-height text-family-brand text-weight-bold uppercase font-size-140p",
                                              attrs: {
                                                loading:
                                                  _vm.dialogProductSendSending,
                                                color: "primary",
                                                "text-color": "white",
                                                rounded: "",
                                                disabled:
                                                  !this.account.isLoggedIn ||
                                                  0 >=
                                                    _vm.wallet_amount -
                                                      _vm.price_total(
                                                        _vm.offerings_computed[
                                                          _vm.dialogProductItem
                                                        ].products[0].price
                                                      )
                                              },
                                              on: { click: _vm.sendRequest }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Send\n                "
                                                ),
                                                _c("em", [_vm._v("for")]),
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm._f("nformat")(
                                                        _vm.price_total(
                                                          _vm
                                                            .offerings_computed[
                                                            _vm
                                                              .dialogProductItem
                                                          ].products[0].price
                                                        ),
                                                        "$0,0.00"
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]),
                                              _c("q-spinner-puff", {
                                                attrs: { slot: "loading" },
                                                slot: "loading"
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "full-width" },
                                            [
                                              !this.account.isLoggedIn
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "caption text-weight-semibold text-subinfo no-margin on-top-default bg-empower-light animate-popup-up",
                                                      staticStyle: {
                                                        padding: "20px 0px",
                                                        "border-radius": "1.4em"
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "vertical-middle",
                                                        staticStyle: {
                                                          width: "34px",
                                                          margin: "0 4px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            "/statics/_demo/exclamationmark_attention.svg"
                                                        }
                                                      }),
                                                      _c(
                                                        "q-chip",
                                                        {
                                                          staticClass:
                                                            "q-chip-height-min",
                                                          attrs: {
                                                            color: "red"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                  Email Address\n                "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "block on-top-default font-size-160p text-weight-black"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Almost there!"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "block"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Login with email. No password needed!"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm.wallet_amount -
                                                    _vm.price_total(
                                                      _vm.offerings_computed[
                                                        _vm.dialogProductItem
                                                      ].products[0].price
                                                    ) >=
                                                  0
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "caption text-weight-semibold text-subinfo no-margin on-top-default"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                " +
                                                          _vm._s(
                                                            _vm._f("nformat")(
                                                              _vm.wallet_amount,
                                                              "$0,0.00"
                                                            )
                                                          ) +
                                                          "\n                "
                                                      ),
                                                      _c("img", {
                                                        staticClass:
                                                          "brighten-50 dark-img-invert-100 vertical-middle",
                                                        staticStyle: {
                                                          width: "10px",
                                                          margin: "0 4px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            "/statics/_demo/arrow.right.svg"
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n                " +
                                                          _vm._s(
                                                            _vm._f("nformat")(
                                                              _vm.wallet_amount -
                                                                _vm.price_total(
                                                                  _vm
                                                                    .offerings_computed[
                                                                    _vm
                                                                      .dialogProductItem
                                                                  ].products[0]
                                                                    .price
                                                                ),
                                                              "$0,0.00"
                                                            )
                                                          ) +
                                                          "\n              "
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "caption text-weight-semibold text-subinfo no-margin on-top-default bg-empower-light animate-popup-up",
                                                      staticStyle: {
                                                        padding: "20px 0px",
                                                        "border-radius": "1.4em"
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "vertical-middle",
                                                        staticStyle: {
                                                          width: "34px",
                                                          margin: "0 4px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            "/statics/_demo/exclamationmark_attention.svg"
                                                        }
                                                      }),
                                                      _c(
                                                        "q-chip",
                                                        {
                                                          staticClass:
                                                            "q-chip-height-min",
                                                          attrs: {
                                                            color: "red"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                  " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "nformat"
                                                                )(
                                                                  _vm.wallet_amount,
                                                                  "$0,0.00"
                                                                )
                                                              ) +
                                                              "\n                "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "block on-top-default font-size-160p text-weight-black"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Almost there!"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "block"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Need additional funds to send."
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ]
                                          ),
                                          !this.account.isLoggedIn
                                            ? _c(
                                                "q-btn",
                                                {
                                                  staticClass:
                                                    "on-top-sm limit-width-1024 full-width full-height text-family-brand text-weight-bold uppercase font-size-140p",
                                                  attrs: {
                                                    color: "empower-light",
                                                    "text-color": "tertiary",
                                                    rounded: ""
                                                  },
                                                  on: {
                                                    click: _vm.accountLogin
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              Add Email\n            "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.wallet_amount -
                                            _vm.price_total(
                                              _vm.offerings_computed[
                                                _vm.dialogProductItem
                                              ].products[0].price
                                            ) <
                                          0
                                            ? _c(
                                                "q-btn",
                                                {
                                                  staticClass:
                                                    "on-top-sm limit-width-1024 full-width full-height text-family-brand text-weight-bold uppercase font-size-140p",
                                                  attrs: {
                                                    color: "empower-light",
                                                    "text-color": "tertiary",
                                                    rounded: ""
                                                  },
                                                  on: { click: _vm.walletAdd }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              Add funds\n            "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass:
                        "appLayer dialog-item dialog-grow dialog-grow-full no-title",
                      attrs: {
                        id: "dialogProduct",
                        position: "bottom",
                        "no-refocus": ""
                      },
                      model: {
                        value: _vm.dialogProductShow,
                        callback: function($$v) {
                          _vm.dialogProductShow = $$v
                        },
                        expression: "dialogProductShow"
                      }
                    },
                    [
                      _vm.dialogProductItem &&
                      _vm.offerings_computed[_vm.dialogProductItem]
                        ? _c(
                            "q-modal-layout",
                            [
                              _c(
                                "q-toolbar",
                                {
                                  directives: [
                                    {
                                      name: "touch-pan",
                                      rawName:
                                        "v-touch-pan.vertical.prevent.stop",
                                      value: _vm.modalAdapt,
                                      expression: "modalAdapt",
                                      modifiers: {
                                        vertical: true,
                                        prevent: true,
                                        stop: true
                                      }
                                    }
                                  ],
                                  staticClass: "cursor-grab",
                                  attrs: { slot: "header", inverted: "" },
                                  slot: "header"
                                },
                                [
                                  _c("q-toolbar-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          _vm.offerings.items[
                                            _vm.offerings_computed[
                                              _vm.dialogProductItem
                                            ].offer
                                          ].name
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "q-toolbar",
                                {
                                  staticClass: "toolbar-overscroll-shadow",
                                  attrs: { slot: "header", inverted: "" },
                                  slot: "header"
                                },
                                [
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "toolbar-overscroll-visibility full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-bolder uppercase font-size-120p",
                                              attrs: {
                                                color: "tertiary-light",
                                                "text-color": "tertiary",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.send()
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "on-left-sm",
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/stack.send_" +
                                                    (_vm.anyDarkmode
                                                      ? "white"
                                                      : "tertiary") +
                                                    ".svg",
                                                  height: "24"
                                                }
                                              }),
                                              _vm._v("...\n            ")
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                              attrs: {
                                                color: "primary-light",
                                                "text-color": "primary",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  _vm.dialogProductShow = false
                                                  _vm.soundPlay("tap")
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/" +
                                                    (_vm.anyDarkmode
                                                      ? "chevron.compact.down_white.svg"
                                                      : "chevron.compact.down_primary.svg"),
                                                  height: "10"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center"
                                },
                                [
                                  _c("q-scroll-observable", {
                                    on: {
                                      scroll: _vm.toolbarShadowOnOverscroll
                                    }
                                  }),
                                  _c("img", {
                                    staticClass: "avatar",
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                      "border-radius": "0.3rem"
                                    },
                                    attrs: {
                                      src:
                                        _vm.offerings_computed[
                                          _vm.dialogProductItem
                                        ].products[0].photo
                                    }
                                  }),
                                  _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "full-width no-margin text-weight-semibold"
                                    },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            _vm.offerings.items[
                                              _vm.offerings_computed[
                                                _vm.dialogProductItem
                                              ].offer
                                            ].name
                                          ) +
                                          "\n        "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "q-list",
                                    {
                                      staticClass:
                                        "card text-family-brand full-width on-top-lg",
                                      attrs: {
                                        dark: _vm.anyDarkmode,
                                        "inset-separator": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "q-item",
                                        { staticClass: "no-padding" },
                                        [
                                          _c(
                                            "q-item-main",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "columns items-center justify-between row full-width"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-3" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "on-bottom-xs caption text-shallow uppercase text-weight-black"
                                                        },
                                                        [_vm._v("price")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row justify-center",
                                                          staticStyle: {
                                                            "max-height":
                                                              "40px",
                                                            height: "40px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "q-chip",
                                                            {
                                                              staticClass:
                                                                "q-chip-empower",
                                                              attrs: {
                                                                square: "",
                                                                dense: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "nformat"
                                                                    )(
                                                                      _vm.price_total(
                                                                        _vm
                                                                          .offerings_computed[
                                                                          _vm
                                                                            .dialogProductItem
                                                                        ]
                                                                          .products[0]
                                                                          .price
                                                                      ),
                                                                      "$0,0.00"
                                                                    )
                                                                  ) +
                                                                  "\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-1 bg-shallower",
                                                      staticStyle: {
                                                        height: "40px",
                                                        width: "4px",
                                                        "border-radius": "1rem"
                                                      }
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "col-3",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.scrollToRef(
                                                            "product-item-features-nutrition"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "on-bottom-xs caption text-shallow uppercase text-weight-black"
                                                        },
                                                        [_vm._v("calories")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row justify-center",
                                                          staticStyle: {
                                                            "max-height":
                                                              "40px",
                                                            height: "40px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "q-chip",
                                                            {
                                                              staticClass:
                                                                "q-chip-secondary",
                                                              attrs: {
                                                                square: "",
                                                                dense: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .offerings_computed[
                                                                      _vm
                                                                        .dialogProductItem
                                                                    ]
                                                                      .products[0]
                                                                      .nutrition
                                                                      .calories
                                                                  ) +
                                                                  "\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  _vm.offerings_computed[
                                                    _vm.dialogProductItem
                                                  ].products[0].size
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-1 bg-shallower",
                                                          staticStyle: {
                                                            height: "40px",
                                                            width: "4px",
                                                            "border-radius":
                                                              "1rem"
                                                          }
                                                        },
                                                        [_vm._v(" ")]
                                                      )
                                                    : _vm._e(),
                                                  _vm.offerings_computed[
                                                    _vm.dialogProductItem
                                                  ].products[0].size
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-3"
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "on-bottom-xs caption text-shallow uppercase text-weight-black"
                                                            },
                                                            [_vm._v("size")]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row justify-center",
                                                              staticStyle: {
                                                                "max-height":
                                                                  "40px",
                                                                height: "40px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "q-chip",
                                                                {
                                                                  staticClass:
                                                                    "q-chip-info",
                                                                  attrs: {
                                                                    square: "",
                                                                    dense: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .offerings_computed[
                                                                          _vm
                                                                            .dialogProductItem
                                                                        ]
                                                                          .products[0]
                                                                          .size
                                                                          .value
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .offerings_computed[
                                                                          _vm
                                                                            .dialogProductItem
                                                                        ]
                                                                          .products[0]
                                                                          .size
                                                                          .unit
                                                                      ) +
                                                                      ".\n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.offerings_computed[
                                                    _vm.dialogProductItem
                                                  ].products[0].size
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-1 bg-shallower",
                                                          staticStyle: {
                                                            height: "40px",
                                                            width: "4px",
                                                            "border-radius":
                                                              "1rem"
                                                          }
                                                        },
                                                        [_vm._v(" ")]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.offerings_computed[
                                                    _vm.dialogProductItem
                                                  ].products[0].size
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-3"
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "on-bottom-xs caption text-shallow uppercase text-weight-black"
                                                            },
                                                            [_vm._v("qty")]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row justify-center",
                                                              staticStyle: {
                                                                "max-height":
                                                                  "40px",
                                                                height: "40px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "q-chip",
                                                                {
                                                                  staticClass:
                                                                    "q-chip-info",
                                                                  attrs: {
                                                                    square: "",
                                                                    dense: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        ×" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .offerings_computed[
                                                                          _vm
                                                                            .dialogProductItem
                                                                        ]
                                                                          .products[0]
                                                                          .qty
                                                                      ) +
                                                                      "  \n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.offerings_computed[
                                    _vm.dialogProductItem
                                  ].products[0].personas.indexOf("bison") >= 0
                                    ? _c(
                                        "q-list",
                                        {
                                          staticClass:
                                            "card text-family-brand full-width on-top-lg",
                                          attrs: {
                                            dark: _vm.anyDarkmode,
                                            "inset-separator": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "q-list-header",
                                            {
                                              staticClass:
                                                "font-size-120p text-secondary text-center",
                                              attrs: { "compact-left": "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n            options\n          "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "q-item",
                                            [
                                              _c("q-item-side", [
                                                _c("img", {
                                                  staticClass:
                                                    "brighten-50 dark-img-invert-100 on-left-sm",
                                                  staticStyle: {
                                                    width: "40px"
                                                  },
                                                  attrs: {
                                                    src:
                                                      "/statics/_demo/options.temperature.svg"
                                                  }
                                                })
                                              ]),
                                              _c(
                                                "q-item-main",
                                                {
                                                  staticClass:
                                                    "font-size-160p text-weight-semibold"
                                                },
                                                [
                                                  _c(
                                                    "q-item-tile",
                                                    {
                                                      staticClass:
                                                        "font-size-100p text-educate",
                                                      attrs: { sublabel: "" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                Meat Temperature\n              "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "q-item-tile",
                                                    {
                                                      staticClass:
                                                        "font-size-100p capitalize",
                                                      attrs: { label: "" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                Medium Rare\n              "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-side",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: {
                                                    width: "80px",
                                                    height: "60px"
                                                  },
                                                  attrs: { right: "" }
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      "margin-top": "-15px",
                                                      width: "auto",
                                                      height: "auto",
                                                      "max-width": "90px",
                                                      "max-height": "90px"
                                                    },
                                                    attrs: {
                                                      src:
                                                        "/statics/_demo/optons.temperature.medium-rare.svg"
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item",
                                            [
                                              _c("q-item-side", [
                                                _c("img", {
                                                  staticClass:
                                                    "brighten-50 dark-img-invert-100 on-left-sm",
                                                  staticStyle: {
                                                    width: "40px"
                                                  },
                                                  attrs: {
                                                    src:
                                                      "/statics/_demo/options.side.svg"
                                                  }
                                                })
                                              ]),
                                              _c(
                                                "q-item-main",
                                                {
                                                  staticClass:
                                                    "font-size-160p text-weight-semibold"
                                                },
                                                [
                                                  _c(
                                                    "q-item-tile",
                                                    {
                                                      staticClass:
                                                        "font-size-100p text-educate",
                                                      attrs: { sublabel: "" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                Side item\n              "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "q-item-tile",
                                                    {
                                                      staticClass:
                                                        "font-size-100p capitalize text-subinfo-l",
                                                      attrs: { label: "" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                None\n              "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item",
                                            [
                                              _c(
                                                "q-item-main",
                                                [
                                                  _c(
                                                    "q-card",
                                                    {
                                                      staticClass:
                                                        "q-card-grouped text-center no-shadow no-border no-background flex-auto relative-position z-top margin-auto-lr"
                                                    },
                                                    [
                                                      _c(
                                                        "q-card-main",
                                                        {
                                                          staticClass:
                                                            "column justify-center full-height",
                                                          staticStyle: {
                                                            "padding-left": "0",
                                                            "padding-right":
                                                              "0",
                                                            "padding-bottom":
                                                              "8px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "q-btn",
                                                            {
                                                              staticClass:
                                                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                "text-color":
                                                                  "white",
                                                                rounded: ""
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.send()
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "absolute",
                                                                staticStyle: {
                                                                  left: "20px"
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    "/statics/_demo/stack.send_white.svg",
                                                                  height: "30"
                                                                }
                                                              }),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-size-140p text-weight-bolder"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "SEND ..."
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "q-card",
                                        {
                                          staticClass:
                                            "q-card-grouped text-center no-shadow no-border no-background flex-auto relative-position z-top margin-auto-lr"
                                        },
                                        [
                                          _c(
                                            "q-card-main",
                                            {
                                              staticClass:
                                                "column justify-center full-height",
                                              staticStyle: {
                                                "padding-left": "0",
                                                "padding-right": "0",
                                                "padding-bottom": "8px"
                                              }
                                            },
                                            [
                                              _c(
                                                "q-btn",
                                                {
                                                  staticClass:
                                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                                  attrs: {
                                                    color: "primary",
                                                    "text-color": "white",
                                                    rounded: ""
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.send()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "absolute",
                                                    staticStyle: {
                                                      left: "20px"
                                                    },
                                                    attrs: {
                                                      src:
                                                        "/statics/_demo/stack.send_white.svg",
                                                      height: "30"
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-size-140p text-weight-bolder"
                                                    },
                                                    [_vm._v("SEND ...")]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "hidden no-padding-top q-card-grouped text-center no-shadow no-border no-background flex-auto relative-position z-top margin-auto-lr"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "no-padding column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                              attrs: {
                                                color: "empower-light",
                                                "text-color": "empower",
                                                rounded: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-size-140p text-weight-bolder"
                                                },
                                                [_vm._v("Concierge")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-list",
                                    {
                                      staticClass:
                                        "card text-family-brand full-width on-top-lg",
                                      attrs: {
                                        dark: _vm.anyDarkmode,
                                        "inset-separator": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "q-list-header",
                                        {
                                          staticClass:
                                            "font-size-120p text-secondary text-center",
                                          attrs: { "compact-left": "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n          station\n          "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "q-item",
                                        [
                                          _c("q-item-side", [
                                            _c("img", {
                                              staticClass:
                                                "brighten-50 dark-img-invert-50 on-left-sm animate-spin",
                                              staticStyle: { width: "36px" },
                                              attrs: {
                                                src:
                                                  "/statics/_demo/station.globe.svg"
                                              }
                                            })
                                          ]),
                                          _c(
                                            "q-item-main",
                                            {
                                              staticClass:
                                                "font-size-160p text-weight-semibold"
                                            },
                                            [
                                              _c(
                                                "q-item-tile",
                                                {
                                                  staticClass:
                                                    "font-size-100p capitalize",
                                                  attrs: { label: "" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.offerings.items[
                                                          _vm
                                                            .offerings_computed[
                                                            _vm
                                                              .dialogProductItem
                                                          ].offer
                                                        ].station
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "q-item-tile",
                                                {
                                                  staticClass: "font-size-100p",
                                                  attrs: { sublabel: "" }
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.channel[
                                                      _vm.offerings.items[
                                                        _vm.offerings_computed[
                                                          _vm.dialogProductItem
                                                        ].offer
                                                      ].station + "_staff"
                                                    ],
                                                    function(staff) {
                                                      return _c("img", {
                                                        key: "staff-" + staff,
                                                        staticClass:
                                                          "brighten-50 dark-img-invert-100 on-left-sm",
                                                        staticStyle: {
                                                          width: "20px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            "/statics/_demo/station.person.svg"
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  _c(
                                                    "q-chip",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            0 ===
                                                            _vm.channel[
                                                              _vm.offerings
                                                                .items[
                                                                _vm
                                                                  .offerings_computed[
                                                                  _vm
                                                                    .dialogProductItem
                                                                ].offer
                                                              ].station +
                                                                "_training"
                                                            ],
                                                          expression:
                                                            "0 === channel[`${offerings.items[offerings_computed[dialogProductItem].offer].station}_training`]"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "q-chip-subinfo on-bottom-xs on-left-xs dark-img-invert-100",
                                                      attrs: { dense: "" }
                                                    },
                                                    [_vm._v("Training")]
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item",
                                        [
                                          _c("q-item-side", [
                                            _c("img", {
                                              staticClass:
                                                "brighten-50 dark-img-invert-50 on-left-sm",
                                              staticStyle: { width: "36px" },
                                              attrs: {
                                                src:
                                                  "/statics/_demo/station.wait.svg"
                                              }
                                            })
                                          ]),
                                          _c(
                                            "q-item-main",
                                            {
                                              staticClass:
                                                "font-size-160p text-weight-semibold"
                                            },
                                            [
                                              _c(
                                                "q-item-tile",
                                                {
                                                  staticClass: "font-size-100p",
                                                  attrs: { label: "" }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.guardian
                                                              .business
                                                              .stations[
                                                              _vm.offerings
                                                                .items[
                                                                _vm
                                                                  .offerings_computed[
                                                                  _vm
                                                                    .dialogProductItem
                                                                ].offer
                                                              ].station
                                                            ].times.wait +
                                                              _vm
                                                                .offerings_computed[
                                                                _vm
                                                                  .dialogProductItem
                                                              ].products[0]
                                                                .time >=
                                                            35,
                                                          expression:
                                                            "(guardian.business.stations[offerings.items[offerings_computed[dialogProductItem].offer].station].times.wait + offerings_computed[dialogProductItem].products[0].time) >= 35"
                                                        }
                                                      ]
                                                    },
                                                    [_vm._v(">")]
                                                  ),
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.guardian.business
                                                          .stations[
                                                          _vm.offerings.items[
                                                            _vm
                                                              .offerings_computed[
                                                              _vm
                                                                .dialogProductItem
                                                            ].offer
                                                          ].station
                                                        ].times.wait +
                                                          _vm
                                                            .offerings_computed[
                                                            _vm
                                                              .dialogProductItem
                                                          ].products[0].time
                                                      ) +
                                                      " minutes\n              "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "q-item-tile",
                                                {
                                                  staticClass: "font-size-100p",
                                                  attrs: { sublabel: "" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                 Estimated service time\n              "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-list",
                                    {
                                      ref: "product-item-features-nutrition",
                                      staticClass:
                                        "card text-family-brand full-width on-top-lg",
                                      attrs: {
                                        dark: _vm.anyDarkmode,
                                        "inset-separator": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "q-list-header",
                                        {
                                          staticClass:
                                            "font-size-120p text-secondary text-center",
                                          attrs: { "compact-left": "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n            nutrition\n          "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "q-item",
                                        { staticClass: "no-padding" },
                                        [
                                          _c(
                                            "q-item-main",
                                            {
                                              staticClass:
                                                "font-size-160p text-weight-semibold"
                                            },
                                            [
                                              _c(
                                                "q-card",
                                                {
                                                  staticClass:
                                                    "no-background no-border no-shadow overflow-hidden q-card-grouped q-card-widget2 q-card-title-columns",
                                                  staticStyle: { width: "100%" }
                                                },
                                                [
                                                  _c(
                                                    "q-card-main",
                                                    {
                                                      staticClass:
                                                        "q-card-title-columns-wrapper text-family-brand text-subinfo",
                                                      staticStyle: {
                                                        "padding-top": "0",
                                                        "padding-bottom":
                                                          "0text-align:left"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "q-card-title-columns-hero",
                                                          staticStyle: {
                                                            "min-height":
                                                              "auto",
                                                            top: "0",
                                                            height: "120px",
                                                            "padding-left":
                                                              "10px"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-weight-black inline-block",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "400%",
                                                                "margin-bottom":
                                                                  "-20px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .offerings_computed[
                                                                    _vm
                                                                      .dialogProductItem
                                                                  ].products[0]
                                                                    .nutrition
                                                                    .calories
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-weight-semibold text-subinfo-l block"
                                                            },
                                                            [_vm._v("calories")]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "q-card-title-columns-content",
                                                          staticStyle: {
                                                            "min-height": "auto"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "columns flex justify-between",
                                                              staticStyle: {
                                                                "padding-top":
                                                                  "20px",
                                                                width: "100%"
                                                              }
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "pill inline-block bg-gold",
                                                                style:
                                                                  "width:calc(" +
                                                                  _vm
                                                                    .offerings_computed[
                                                                    _vm
                                                                      .dialogProductItem
                                                                  ].products[0]
                                                                    .nutrition
                                                                    .p_portion *
                                                                    100 +
                                                                  "% - 4px); margin-left: 0px"
                                                              }),
                                                              _c("span", {
                                                                staticClass:
                                                                  "pill inline-block bg-green",
                                                                style:
                                                                  "width:calc(" +
                                                                  _vm
                                                                    .offerings_computed[
                                                                    _vm
                                                                      .dialogProductItem
                                                                  ].products[0]
                                                                    .nutrition
                                                                    .c_portion *
                                                                    100 +
                                                                  "% - 4px)"
                                                              }),
                                                              _c("span", {
                                                                staticClass:
                                                                  "pill inline-block bg-red",
                                                                style:
                                                                  "width:calc(" +
                                                                  _vm
                                                                    .offerings_computed[
                                                                    _vm
                                                                      .dialogProductItem
                                                                  ].products[0]
                                                                    .nutrition
                                                                    .f_portion *
                                                                    100 +
                                                                  "% - 4px); margin-right: 0px"
                                                              })
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-weight-black font-size-120p",
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "-20px",
                                                                display: "flex",
                                                                "flex-direction":
                                                                  "row",
                                                                "justify-content":
                                                                  "space-between",
                                                                "align-items":
                                                                  "flex-start"
                                                              }
                                                            },
                                                            [
                                                              _c("div", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      "font-size":
                                                                        "140%"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .offerings_computed[
                                                                          _vm
                                                                            .dialogProductItem
                                                                        ]
                                                                          .products[0]
                                                                          .nutrition
                                                                          .macronutrients
                                                                          .p
                                                                      ) + "g"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "block text-weight-semibold text-subinfo-l",
                                                                    staticStyle: {
                                                                      "font-size":
                                                                        "14px",
                                                                      "margin-top":
                                                                        "-6px"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "protein"
                                                                    )
                                                                  ]
                                                                )
                                                              ]),
                                                              _c("div", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      "font-size":
                                                                        "140%"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .offerings_computed[
                                                                          _vm
                                                                            .dialogProductItem
                                                                        ]
                                                                          .products[0]
                                                                          .nutrition
                                                                          .macronutrients
                                                                          .c
                                                                      ) + "g"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "block text-weight-semibold text-subinfo-l",
                                                                    staticStyle: {
                                                                      "font-size":
                                                                        "14px",
                                                                      "margin-top":
                                                                        "-6px"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "carb."
                                                                    )
                                                                  ]
                                                                )
                                                              ]),
                                                              _c("div", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      "font-size":
                                                                        "140%"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .offerings_computed[
                                                                          _vm
                                                                            .dialogProductItem
                                                                        ]
                                                                          .products[0]
                                                                          .nutrition
                                                                          .macronutrients
                                                                          .f
                                                                      ) + "g"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "block text-weight-semibold text-subinfo-l",
                                                                    staticStyle: {
                                                                      "font-size":
                                                                        "14px",
                                                                      "margin-top":
                                                                        "-6px"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fat"
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(
                                    [
                                      "styles",
                                      "groups",
                                      "brands",
                                      "labels",
                                      "practices",
                                      "ingredients"
                                    ],
                                    function(type) {
                                      return [
                                        _vm.itemContainsGroup(
                                          _vm.dialogProductItem,
                                          type
                                        )
                                          ? _c(
                                              "q-list",
                                              {
                                                key:
                                                  "product-item-section-" +
                                                  type,
                                                staticClass:
                                                  "card text-family-brand full-width on-top-lg",
                                                attrs: {
                                                  "link--": "",
                                                  dark: _vm.anyDarkmode,
                                                  "inset-separator": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "q-list-header",
                                                  {
                                                    staticClass:
                                                      "font-size-120p text-secondary text-center",
                                                    attrs: {
                                                      "compact-left": ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n              " +
                                                        _vm._s(type) +
                                                        "\n            "
                                                    )
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.offerings_computed[
                                                    _vm.dialogProductItem
                                                  ].products[0].contains,
                                                  function(contains, ix) {
                                                    return [
                                                      _vm.offerings.personas
                                                        .bases[contains.base] &&
                                                      _vm.offerings.personas
                                                        .bases[contains.base]
                                                        .group === type
                                                        ? [
                                                            _c(
                                                              "q-item",
                                                              {
                                                                key: [
                                                                  contains,
                                                                  ix
                                                                ].join(),
                                                                attrs: {
                                                                  "link--": ""
                                                                },
                                                                nativeOn: {
                                                                  "click--": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.personaEdit(
                                                                      contains.base,
                                                                      {
                                                                        status: null
                                                                      }
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "q-item-side",
                                                                  [
                                                                    _c("img", {
                                                                      staticClass:
                                                                        "brighten-50 dark-img-invert-100 on-left-sm",
                                                                      staticStyle: {
                                                                        width:
                                                                          "40px"
                                                                      },
                                                                      attrs: {
                                                                        src:
                                                                          "/statics/_demo/persona." +
                                                                          _vm
                                                                            .offerings
                                                                            .personas
                                                                            .bases[
                                                                            contains
                                                                              .base
                                                                          ]
                                                                            .group +
                                                                          "." +
                                                                          contains.base +
                                                                          ".svg"
                                                                      }
                                                                    })
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "q-item-main",
                                                                  {
                                                                    staticClass:
                                                                      "font-size-160p text-weight-semibold"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "q-item-tile",
                                                                      {
                                                                        staticClass:
                                                                          "font-size-100p capitalize",
                                                                        attrs: {
                                                                          label:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                      " +
                                                                            _vm._s(
                                                                              contains.base.replace(
                                                                                /\_/g,
                                                                                " "
                                                                              )
                                                                            ) +
                                                                            "\n                    "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "q-item-tile",
                                                                      [
                                                                        contains.qty >
                                                                        1
                                                                          ? _c(
                                                                              "q-chip",
                                                                              {
                                                                                staticClass:
                                                                                  "dark-img-invert-100 q-chip-info on-left-xs",
                                                                                attrs: {
                                                                                  dense:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "×" +
                                                                                    _vm._s(
                                                                                      contains.qty
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._l(
                                                                          contains.modifiers,
                                                                          function(
                                                                            modifier
                                                                          ) {
                                                                            return _c(
                                                                              "q-chip",
                                                                              {
                                                                                key:
                                                                                  "offerings-" +
                                                                                  _vm.dialogProductItem +
                                                                                  "-highlight-" +
                                                                                  contains.base +
                                                                                  "-" +
                                                                                  modifier,
                                                                                staticClass:
                                                                                  "dark-img-invert-100 on-left-xs q-chip-info",
                                                                                attrs: {
                                                                                  dense:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    modifier.replace(
                                                                                      /\_/g,
                                                                                      " "
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          }
                                                                        )
                                                                      ],
                                                                      2
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm
                                                                  .guardian_personas
                                                                  .data[type] &&
                                                                _vm
                                                                  .guardian_personas
                                                                  .data[type]
                                                                  .data[
                                                                  contains.base
                                                                ] &&
                                                                _vm
                                                                  .guardian_personas
                                                                  .data[type]
                                                                  .data[
                                                                  contains.base
                                                                ].status
                                                                  ? _c(
                                                                      "q-item-side",
                                                                      {
                                                                        staticClass:
                                                                          "text-center",
                                                                        staticStyle: {
                                                                          width:
                                                                            "80px",
                                                                          height:
                                                                            "60px"
                                                                        },
                                                                        attrs: {
                                                                          right:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm
                                                                          .guardian_personas
                                                                          .data[
                                                                          type
                                                                        ].data[
                                                                          contains
                                                                            .base
                                                                        ]
                                                                          .status ===
                                                                        "health"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "dialogProductItemPersona" +
                                                                                  type +
                                                                                  "Status_health"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "auto",
                                                                                      height:
                                                                                        "auto",
                                                                                      "max-width":
                                                                                        "40px",
                                                                                      "max-height":
                                                                                        "50px"
                                                                                    },
                                                                                    attrs: {
                                                                                      src:
                                                                                        "/statics/_demo/avoid_protect.svg"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-margin text-weight-bold text-protect font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "AVOID"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm
                                                                              .guardian_personas
                                                                              .data[
                                                                              type
                                                                            ]
                                                                              .data[
                                                                              contains
                                                                                .base
                                                                            ]
                                                                              .status ===
                                                                            "avoid"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "dialogProductItemPersona" +
                                                                                  type +
                                                                                  "Status_avoid"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "auto",
                                                                                      height:
                                                                                        "auto",
                                                                                      "max-width":
                                                                                        "40px",
                                                                                      "max-height":
                                                                                        "50px"
                                                                                    },
                                                                                    attrs: {
                                                                                      src:
                                                                                        "/statics/_demo/nosign_attention.svg"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-margin text-weight-bold text-attention font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "AVOID"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm
                                                                              .guardian_personas
                                                                              .data[
                                                                              type
                                                                            ]
                                                                              .data[
                                                                              contains
                                                                                .base
                                                                            ]
                                                                              .status ===
                                                                            "good"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "dialogProductItemPersona" +
                                                                                  type +
                                                                                  "Status_good"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "auto",
                                                                                      height:
                                                                                        "auto",
                                                                                      "max-width":
                                                                                        "40px",
                                                                                      "max-height":
                                                                                        "50px"
                                                                                    },
                                                                                    attrs: {
                                                                                      src:
                                                                                        "/statics/_demo/heart_educate.svg"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-margin text-weight-bold text-educate font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "WANT"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-margin text-weight-bold text-educate font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ]
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer",
                      attrs: { id: "dialogItem", position: "bottom" },
                      model: {
                        value: _vm.dialogPerspectiveShow,
                        callback: function($$v) {
                          _vm.dialogPerspectiveShow = $$v
                        },
                        expression: "dialogPerspectiveShow"
                      }
                    },
                    [
                      this.dialogPerspectiveItem
                        ? _c(
                            "q-modal-layout",
                            {
                              style:
                                "background-image: url(/statics/_demo/" +
                                this.dialogPerspectiveItem.icon +
                                ".svg);"
                            },
                            [
                              _c(
                                "q-toolbar",
                                {
                                  attrs: { slot: "header", inverted: "" },
                                  slot: "header"
                                },
                                [
                                  _c("q-toolbar-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          (
                                            this.dialogPerspectiveItem.id +
                                            ".LABEL"
                                          ).toUpperCase()
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "q-toolbar",
                                {
                                  staticClass: "toolbar-overscroll-shadow",
                                  attrs: { slot: "header", inverted: "" },
                                  slot: "header"
                                },
                                [
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                              attrs: {
                                                color: "primary-light",
                                                "text-color": "primary",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  _vm.dialogPerspectiveShow = false
                                                  _vm.soundPlay("tap")
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/" +
                                                    (_vm.anyDarkmode
                                                      ? "chevron.compact.down_white.svg"
                                                      : "chevron.compact.down_primary.svg"),
                                                  height: "10"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "layout-padding no-padding-top"
                                },
                                [
                                  _c("q-scroll-observable", {
                                    on: {
                                      scroll: _vm.toolbarShadowOnOverscroll
                                    }
                                  }),
                                  _c(
                                    "q-list",
                                    {
                                      staticClass: "text-family-brand",
                                      attrs: {
                                        "no-border": "",
                                        link: "",
                                        dark: _vm.anyDarkmode
                                      }
                                    },
                                    [
                                      _vm.dialogPerspectiveItem.indicates &&
                                      _vm.dialogPerspectiveItem.indicates.aux
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-family-brand text-weight-semibold text-center font-size-160p",
                                              class: {
                                                lowercase: !_vm.dialogPerspectiveItem.indicates.aux()
                                                  .unformat,
                                                "text-educate":
                                                  _vm.dialogPerspectiveItem.indicates.aux()
                                                    .state === 1,
                                                "text-attention":
                                                  _vm.dialogPerspectiveItem.indicates.aux()
                                                    .state !== 1
                                              },
                                              style: {
                                                "word-break": _vm.dialogPerspectiveItem.indicates.aux()
                                                  .unformat
                                                  ? "normal"
                                                  : "break-all"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n            " +
                                                  _vm._s(
                                                    _vm.dialogPerspectiveItem.indicates.aux()
                                                      .text ||
                                                      _vm.dialogPerspectiveItem.indicates.aux()
                                                        .value
                                                  ) +
                                                  "\n            "
                                              ),
                                              _vm.dialogPerspectiveItem.type ===
                                              "link"
                                                ? _c("q-btn", {
                                                    attrs: {
                                                      flat: "",
                                                      round: "",
                                                      icon: "ion-link"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.openURL(
                                                          _vm.dialogPerspectiveItem.indicates.aux()
                                                            .value ||
                                                            _vm.dialogPerspectiveItem.indicates.aux()
                                                              .text
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "q-title layout-padding no-padding-top text-subinfo slideInDown",
                                          staticStyle: {
                                            "padding-bottom": "20px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.dialogPerspectiveItem.label
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._l(
                                        this.dialogPerspectiveItem.value
                                          .options,
                                        function(option, ix) {
                                          return [
                                            _c(
                                              "q-item",
                                              {
                                                key: [option, ix].join("-"),
                                                staticClass:
                                                  "no-pointer-events",
                                                class: {
                                                  "opacity-4":
                                                    _vm.dialogPerspectiveItem
                                                      .value.option !== ix
                                                },
                                                attrs: {
                                                  tag: "span",
                                                  readonly: "",
                                                  disabeld: ""
                                                }
                                              },
                                              [
                                                _c("q-item-side", [
                                                  _vm.dialogPerspectiveItem
                                                    .value.option === ix
                                                    ? _c("img", {
                                                        attrs: {
                                                          src:
                                                            "/statics/_demo/checkmark_primary.svg"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]),
                                                _c(
                                                  "q-item-main",
                                                  {
                                                    staticClass:
                                                      "text-family-brand"
                                                  },
                                                  [
                                                    _c(
                                                      "q-item-tile",
                                                      {
                                                        staticClass:
                                                          "font-size-160p text-weight-semibold",
                                                        attrs: { label: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(option.label)
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "q-item-tile",
                                                      {
                                                        attrs: {
                                                          sublabel: "",
                                                          lines: "3"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                option.sublabel
                                                              )
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: {
                        id: "dialogPersonalize",
                        position: "bottom",
                        "no-refocus": ""
                      },
                      model: {
                        value: _vm.dialogPersonalizeShow,
                        callback: function($$v) {
                          _vm.dialogPersonalizeShow = $$v
                        },
                        expression: "dialogPersonalizeShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(_vm._s(_vm.$t("PERSONALIZE.LABEL_TT")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase font-size-120p",
                                          attrs: {
                                            color: "tertiary-light",
                                            "text-color": "tertiary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.personalizeList()
                                              _vm.soundPlay("sheet_up")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "on-left-sm",
                                            attrs: {
                                              src:
                                                "/statics/_demo/add.fill_" +
                                                (_vm.anyDarkmode
                                                  ? "white"
                                                  : "tertiary") +
                                                ".svg",
                                              height: "24"
                                            }
                                          }),
                                          _vm._v(
                                            "\n              Add\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogPersonalizeShow = false
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/chevron.compact.down_" +
                                                (_vm.anyDarkmode
                                                  ? "white"
                                                  : "primary") +
                                                ".svg",
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center"
                            },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "q-title text-subinfo slideInDown on-top-lg",
                                  staticStyle: {
                                    "padding-bottom": "20px",
                                    "line-height": "1.4em"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          Personalize your experience by adding your lifestyle preferences.\n        "
                                  )
                                ]
                              ),
                              !_vm.guardian_personas.count
                                ? [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-center text-primary text-weight-bold font-size-120p animated800 flipInY animatedtimingCubic cursor-pointer",
                                        on: { click: _vm.personalizeList }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              "/statics/_demo/state.empty_heart" +
                                              (_vm.anyDarkmode ? "@dark" : "") +
                                              ".svg"
                                          }
                                        }),
                                        _c("br"),
                                        _vm._v(
                                          "Add your first personalization\n          "
                                        )
                                      ]
                                    )
                                  ]
                                : _vm._e(),
                              _vm._l(_vm.guardian_personas.data, function(
                                personas,
                                group_id
                              ) {
                                return [
                                  personas.count
                                    ? _c(
                                        "q-list",
                                        {
                                          key: group_id,
                                          staticClass:
                                            "card text-family-brand full-width on-top-lg",
                                          attrs: { "no-border": "", link: "" }
                                        },
                                        [
                                          personas.count
                                            ? [
                                                _c(
                                                  "q-list-header",
                                                  {
                                                    staticClass:
                                                      "font-size-120p text-secondary text-center",
                                                    attrs: {
                                                      "compact-left": ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(group_id) +
                                                        "\n              "
                                                    )
                                                  ]
                                                ),
                                                _vm._l(personas.data, function(
                                                  payload,
                                                  persona
                                                ) {
                                                  return [
                                                    _c(
                                                      "transition",
                                                      {
                                                        key: [
                                                          "transition",
                                                          group_id,
                                                          persona,
                                                          payload
                                                        ].join("-"),
                                                        attrs: {
                                                          mode: "out-in",
                                                          appear: "",
                                                          "enter-active-class":
                                                            "animated800 fadeIn",
                                                          "leave-active-class":
                                                            "fadeOut animated400"
                                                        }
                                                      },
                                                      [
                                                        payload.status
                                                          ? _c(
                                                              "q-item",
                                                              {
                                                                key: [
                                                                  group_id,
                                                                  persona,
                                                                  payload
                                                                ].join("-"),
                                                                attrs: {
                                                                  link: ""
                                                                },
                                                                nativeOn: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.personaEdit(
                                                                      persona,
                                                                      payload
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "q-item-side",
                                                                  [
                                                                    _c("img", {
                                                                      staticClass:
                                                                        "brighten-50 dark-img-invert-100 on-left-sm",
                                                                      staticStyle: {
                                                                        width:
                                                                          "40px"
                                                                      },
                                                                      attrs: {
                                                                        src:
                                                                          "/statics/_demo/persona." +
                                                                          _vm
                                                                            .offerings
                                                                            .personas
                                                                            .bases[
                                                                            persona
                                                                          ]
                                                                            .group +
                                                                          "." +
                                                                          persona +
                                                                          ".svg"
                                                                      }
                                                                    })
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "q-item-main",
                                                                  {
                                                                    staticClass:
                                                                      "font-size-160p text-weight-semibold"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "q-item-tile",
                                                                      {
                                                                        staticClass:
                                                                          "font-size-100p capitalize",
                                                                        attrs: {
                                                                          label:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            persona.replace(
                                                                              /\_/g,
                                                                              " "
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "q-item-side",
                                                                  {
                                                                    staticClass:
                                                                      "text-center",
                                                                    staticStyle: {
                                                                      width:
                                                                        "80px",
                                                                      height:
                                                                        "60px"
                                                                    },
                                                                    attrs: {
                                                                      right: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "transition",
                                                                      {
                                                                        attrs: {
                                                                          mode:
                                                                            "out-in",
                                                                          appear:
                                                                            "",
                                                                          "enter-active-class":
                                                                            "animated400 " +
                                                                            (payload.status ===
                                                                            null
                                                                              ? "fadeIn"
                                                                              : "flipInY"),
                                                                          "leave-active-class":
                                                                            "animated200 " +
                                                                            (payload.status ===
                                                                            null
                                                                              ? "fadeOut"
                                                                              : "flipOutY")
                                                                        }
                                                                      },
                                                                      [
                                                                        payload.status ===
                                                                        "health"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "health"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "auto",
                                                                                      height:
                                                                                        "auto",
                                                                                      "max-width":
                                                                                        "40px",
                                                                                      "max-height":
                                                                                        "50px"
                                                                                    },
                                                                                    attrs: {
                                                                                      src:
                                                                                        "/statics/_demo/avoid_protect.svg"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-margin text-weight-bold text-protect font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "AVOID"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          : payload.status ===
                                                                            "avoid"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "avoid"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "auto",
                                                                                      height:
                                                                                        "auto",
                                                                                      "max-width":
                                                                                        "40px",
                                                                                      "max-height":
                                                                                        "50px"
                                                                                    },
                                                                                    attrs: {
                                                                                      src:
                                                                                        "/statics/_demo/nosign_attention.svg"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-margin text-weight-bold text-attention font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "AVOID"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          : payload.status ===
                                                                            "good"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "good"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "auto",
                                                                                      height:
                                                                                        "auto",
                                                                                      "max-width":
                                                                                        "40px",
                                                                                      "max-height":
                                                                                        "50px"
                                                                                    },
                                                                                    attrs: {
                                                                                      src:
                                                                                        "/statics/_demo/heart_educate.svg"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "no-margin text-weight-bold text-educate font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "WANT"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "null"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "auto",
                                                                                      height:
                                                                                        "auto",
                                                                                      "max-width":
                                                                                        "40px",
                                                                                      "max-height":
                                                                                        "40px",
                                                                                      opacity:
                                                                                        "0.5"
                                                                                    },
                                                                                    attrs: {
                                                                                      width:
                                                                                        "40",
                                                                                      src:
                                                                                        "/statics/_demo/ellipsis.circle.fill_tertiary.svg"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-faded no-margin text-weight-bold text-tertiary font-size-80p"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "NOT SET"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                })
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: {
                        id: "dialogPersonalizeList",
                        position: "bottom",
                        "no-refocus": ""
                      },
                      model: {
                        value: _vm.dialogPersonalizeListShow,
                        callback: function($$v) {
                          _vm.dialogPersonalizeListShow = $$v
                        },
                        expression: "dialogPersonalizeListShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(
                                  _vm._s(_vm.$t("PERSONALIZE_LIST.LABEL_TT"))
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _vm.settings_voice
                                ? _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-bolder uppercase font-size-120p",
                                              attrs: {
                                                color: "tertiary-light",
                                                "text-color": "tertiary",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.personalizeVoice()
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "on-left-sm",
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/mic_" +
                                                    (_vm.anyDarkmode
                                                      ? "white"
                                                      : "tertiary") +
                                                    ".svg",
                                                  height: "24"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.personalizeList()
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/chevron.compact.down_" +
                                                (_vm.anyDarkmode
                                                  ? "white"
                                                  : "primary") +
                                                ".svg",
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center"
                            },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _vm._l(_vm.guardian_personas.data, function(
                                personas,
                                group_id
                              ) {
                                return _c(
                                  "q-list",
                                  {
                                    key: group_id,
                                    staticClass:
                                      "card text-family-brand full-width on-top-lg",
                                    attrs: { "no-border": "", link: "" }
                                  },
                                  [
                                    _c(
                                      "q-list-header",
                                      {
                                        staticClass:
                                          "font-size-120p text-secondary text-center",
                                        attrs: { "compact-left": "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(group_id) +
                                            "\n          "
                                        )
                                      ]
                                    ),
                                    _vm._l(personas.data, function(
                                      payload,
                                      persona
                                    ) {
                                      return [
                                        _c(
                                          "q-item",
                                          {
                                            key: [
                                              group_id,
                                              persona,
                                              payload
                                            ].join("-"),
                                            class: {
                                              "opacity-6 text-emphasis":
                                                payload.status === null
                                            },
                                            attrs: { link: "" },
                                            nativeOn: {
                                              click: function($event) {
                                                return _vm.personaEdit(
                                                  persona,
                                                  payload
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("q-item-side", [
                                              _c("img", {
                                                staticClass:
                                                  "brighten-50 dark-img-invert-100 on-left-sm",
                                                staticStyle: { width: "40px" },
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/persona." +
                                                    _vm.offerings.personas
                                                      .bases[persona].group +
                                                    "." +
                                                    persona +
                                                    ".svg"
                                                }
                                              })
                                            ]),
                                            _c(
                                              "q-item-main",
                                              {
                                                staticClass:
                                                  "font-size-160p text-weight-semibold"
                                              },
                                              [
                                                _c(
                                                  "q-item-tile",
                                                  {
                                                    staticClass:
                                                      "font-size-100p capitalize",
                                                    attrs: { label: "" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        persona.replace(
                                                          /\_/g,
                                                          " "
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-side",
                                              {
                                                staticClass: "text-center",
                                                staticStyle: {
                                                  width: "80px",
                                                  height: "60px"
                                                },
                                                attrs: { right: "" }
                                              },
                                              [
                                                _c(
                                                  "transition",
                                                  {
                                                    attrs: {
                                                      mode: "out-in",
                                                      appear: "",
                                                      "enter-active-class":
                                                        "animated400 " +
                                                        (payload.status === null
                                                          ? "fadeIn"
                                                          : "flipInY"),
                                                      "leave-active-class":
                                                        "animated200 " +
                                                        (payload.status === null
                                                          ? "fadeOut"
                                                          : "flipOutY")
                                                    }
                                                  },
                                                  [
                                                    payload.status === "health"
                                                      ? _c(
                                                          "div",
                                                          { key: "health" },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                width: "auto",
                                                                height: "auto",
                                                                "max-width":
                                                                  "40px",
                                                                "max-height":
                                                                  "50px"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  "/statics/_demo/avoid_protect.svg"
                                                              }
                                                            }),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "no-margin text-weight-bold text-protect font-size-80p"
                                                              },
                                                              [_vm._v("AVOID")]
                                                            )
                                                          ]
                                                        )
                                                      : payload.status ===
                                                        "avoid"
                                                      ? _c(
                                                          "div",
                                                          { key: "avoid" },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                width: "auto",
                                                                height: "auto",
                                                                "max-width":
                                                                  "40px",
                                                                "max-height":
                                                                  "50px"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  "/statics/_demo/nosign_attention.svg"
                                                              }
                                                            }),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "no-margin text-weight-bold text-attention font-size-80p"
                                                              },
                                                              [_vm._v("AVOID")]
                                                            )
                                                          ]
                                                        )
                                                      : payload.status ===
                                                        "good"
                                                      ? _c(
                                                          "div",
                                                          { key: "good" },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                width: "auto",
                                                                height: "auto",
                                                                "max-width":
                                                                  "40px",
                                                                "max-height":
                                                                  "50px"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  "/statics/_demo/heart_educate.svg"
                                                              }
                                                            }),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "no-margin text-weight-bold text-educate font-size-80p"
                                                              },
                                                              [_vm._v("WANT")]
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          { key: "null" },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                width: "auto",
                                                                height: "auto",
                                                                "max-width":
                                                                  "40px",
                                                                "max-height":
                                                                  "40px",
                                                                opacity: "0.5"
                                                              },
                                                              attrs: {
                                                                width: "40",
                                                                src:
                                                                  "/statics/_demo/ellipsis.circle.fill_tertiary.svg"
                                                              }
                                                            }),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-faded no-margin text-weight-bold text-tertiary font-size-80p"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "NOT SET"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item dialog-mini",
                      attrs: {
                        id: "dialogPersonalizeVoice",
                        position: "bottom",
                        "no-refocus": ""
                      },
                      model: {
                        value: _vm.dialogPersonalizeVoiceShow,
                        callback: function($$v) {
                          _vm.dialogPersonalizeVoiceShow = $$v
                        },
                        expression: "dialogPersonalizeVoiceShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm.dialogPersonalizeVoiceStatus <= 1
                                  ? _c("span", [_vm._v("Loading...")])
                                  : _vm._e(),
                                _vm.dialogPersonalizeVoiceStatus === 2
                                  ? _c("span", [_vm._v("Listening...")])
                                  : _vm._e(),
                                _vm.dialogPersonalizeVoiceStatus === 3
                                  ? _c("span", [_vm._v("Processing...")])
                                  : _vm._e(),
                                _vm.dialogPersonalizeVoiceStatus === 4
                                  ? _c("hr", { staticClass: "pill" })
                                  : _vm._e()
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-family-brand layout-padding row justify-center"
                            },
                            [
                              _vm.dialogPersonalizeVoiceStatus <= 1
                                ? _c("p", [_vm._v("Loading...")])
                                : _vm._e(),
                              this.dialogPersonalizeVoiceStatus === 2
                                ? _c("q-spinner-bars", {
                                    attrs: { color: "primary", size: "10vh" }
                                  })
                                : _vm._e(),
                              this.dialogPersonalizeVoiceStatus === 3
                                ? _c("q-progress", {
                                    staticStyle: {
                                      height: "4px",
                                      width: "100%",
                                      "max-width": "80%",
                                      "margin-top": "4vh"
                                    },
                                    attrs: {
                                      color: "tertiary",
                                      height: "10vh",
                                      indeterminate: "",
                                      animate: "",
                                      stripe: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm.dialogPersonalizeVoiceStatus === 4 &&
                              _vm.dialogPersonalizeVoiceText
                                ? _vm._l(
                                    _vm.dialogPersonalizeVoiceText.split(" "),
                                    function(t, i) {
                                      return _c(
                                        "transition",
                                        {
                                          key: "listen-" + i + "-" + t,
                                          attrs: {
                                            appear: "",
                                            "enter-active-class":
                                              "animated fadeInUp animated" +
                                              (i + 1) * 200
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font-size-140p lowercase on-left-sm",
                                              class: {
                                                "text-weight-semibold": i % 2
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(t) +
                                                  "\n            "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.product.data.channel.online
                    ? _c(
                        "q-modal",
                        {
                          staticClass: "appLayer dialog-item",
                          attrs: {
                            id: "dialogDropoffScan",
                            position: "bottom"
                          },
                          model: {
                            value: _vm.dialogDropoffScanShow,
                            callback: function($$v) {
                              _vm.dialogDropoffScanShow = $$v
                            },
                            expression: "dialogDropoffScanShow"
                          }
                        },
                        [
                          _c(
                            "q-modal-layout",
                            [
                              _c(
                                "q-toolbar",
                                {
                                  directives: [
                                    {
                                      name: "touch-pan",
                                      rawName:
                                        "v-touch-pan.vertical.prevent.stop",
                                      value: _vm.modalAdapt,
                                      expression: "modalAdapt",
                                      modifiers: {
                                        vertical: true,
                                        prevent: true,
                                        stop: true
                                      }
                                    }
                                  ],
                                  staticClass: "cursor-grab",
                                  attrs: { slot: "header", inverted: "" },
                                  slot: "header"
                                },
                                [
                                  _c("q-toolbar-title", [
                                    _vm._v(_vm._s(_vm.$t("WINGLET.SCAN.L")))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "q-toolbar",
                                {
                                  staticClass: "toolbar-overscroll-shadow",
                                  attrs: { slot: "header", inverted: "" },
                                  slot: "header"
                                },
                                [
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "disabled margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                              attrs: {
                                                color: "tertiary-light",
                                                "text-color": "subinfo",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  _vm.scanFlashToggle()
                                                  _vm.soundPlay("tap_disabled")
                                                }
                                              }
                                            },
                                            [
                                              _c("q-icon", {
                                                attrs: {
                                                  size: "2em",
                                                  name: "ion-flash"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "disabled margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                              attrs: {
                                                color: "tertiary-light",
                                                "text-color": "subinfo",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  _vm.scanCameraToggle()
                                                  _vm.soundPlay("tap_disabled")
                                                }
                                              }
                                            },
                                            [
                                              _c("q-icon", {
                                                attrs: {
                                                  size: "2em",
                                                  name: "ion-reverse-camera"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                              attrs: {
                                                color: "primary-light",
                                                "text-color": "primary",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  _vm.scanOff()
                                                  _vm.soundPlay("tap")
                                                  _vm.soundFade(
                                                    "pinging",
                                                    0.2,
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/" +
                                                    (_vm.anyDarkmode
                                                      ? "chevron.compact.down_white.svg"
                                                      : "chevron.compact.down_primary.svg"),
                                                  height: "10"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "layout-padding no-padding-top text-center text-family-brand"
                                },
                                [
                                  _c("q-scroll-observable", {
                                    on: {
                                      scroll: _vm.toolbarShadowOnOverscroll
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "overflow-hidden",
                                      staticStyle: {
                                        height: "50vh",
                                        border: "4px solid #3d4042",
                                        "border-radius": "1em"
                                      },
                                      attrs: { id: "wingletDecodeContainer" }
                                    },
                                    [
                                      _vm.dialogDropoffScanShow &&
                                      _vm.dialogDropoffScanStreamShow
                                        ? _c("qrcode-stream", {
                                            staticClass: "scanning",
                                            on: {
                                              decode: _vm.wingletOnDecode,
                                              init: _vm.wingletOnInit
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: {
                        id: "dialogBusiness",
                        position: "bottom",
                        "enter-class": "slideInUp animated800",
                        "leave-class": "slideOutDown animated400"
                      },
                      model: {
                        value: _vm.dialogBusinessShow,
                        callback: function($$v) {
                          _vm.dialogBusinessShow = $$v
                        },
                        expression: "dialogBusinessShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(_vm._s(_vm.$t("YOU")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogAccountShow = false
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/" +
                                                (_vm.anyDarkmode
                                                  ? "chevron.compact.down_white.svg"
                                                  : "chevron.compact.down_primary.svg"),
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "layout-padding no-padding-top text-center text-family-brand"
                            },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: {
                        id: "dialogAccount",
                        position: "bottom",
                        "enter-class": "slideInUp animated800",
                        "leave-class": "slideOutDown animated400"
                      },
                      model: {
                        value: _vm.dialogAccountShow,
                        callback: function($$v) {
                          _vm.dialogAccountShow = $$v
                        },
                        expression: "dialogAccountShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(_vm._s(_vm.$t("YOU")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogAccountShow = false
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/" +
                                                (_vm.anyDarkmode
                                                  ? "chevron.compact.down_white.svg"
                                                  : "chevron.compact.down_primary.svg"),
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center"
                            },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _c(
                                "q-list",
                                {
                                  staticClass:
                                    "card text-family-brand full-width on-top-lg",
                                  attrs: { "no-border": "", link: "" }
                                },
                                [
                                  _c(
                                    "q-list-header",
                                    { attrs: { compact: "" } },
                                    [
                                      _vm._v(
                                        "\n            Account\n          "
                                      )
                                    ]
                                  ),
                                  !_vm.account.isLoggedIn
                                    ? _c(
                                        "q-item",
                                        [
                                          _c(
                                            "q-item-main",
                                            [
                                              _c("vue-phone-number-input", {
                                                attrs: {
                                                  dark: _vm.anyDarkmode,
                                                  "default-country-code": "AE",
                                                  clearable: ""
                                                },
                                                on: {
                                                  update: _vm.updatePhoneNumber
                                                },
                                                model: {
                                                  value:
                                                    _vm.dialogAccountPhoneNumber,
                                                  callback: function($$v) {
                                                    _vm.dialogAccountPhoneNumber = $$v
                                                  },
                                                  expression:
                                                    "dialogAccountPhoneNumber"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.account.isLoggedIn
                                    ? _c(
                                        "q-item",
                                        {
                                          attrs: { link: "", tag: "label" },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.accountLogin()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "q-item-main",
                                            {
                                              staticClass:
                                                "font-size-160p text-weight-semibold text-center"
                                            },
                                            [
                                              !_vm.dialogAccountPhoneNumberProcessing
                                                ? _c(
                                                    "q-item-tile",
                                                    {
                                                      attrs: {
                                                        label: "",
                                                        disabled:
                                                          !_vm.dialogAccountPhoneNumberPayload ||
                                                          !_vm
                                                            .dialogAccountPhoneNumberPayload
                                                            .isValid
                                                      }
                                                    },
                                                    [_vm._v("Login")]
                                                  )
                                                : _vm._e(),
                                              _vm.dialogAccountPhoneNumberProcessing
                                                ? _c(
                                                    "q-item-tile",
                                                    { attrs: { label: "" } },
                                                    [
                                                      _c("q-progress", {
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "-8px",
                                                          width: "300px",
                                                          "margin-left": "auto",
                                                          "margin-right": "auto"
                                                        },
                                                        attrs: {
                                                          stripe: "",
                                                          indeterminate: "",
                                                          color: "hint",
                                                          height: "8px"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "q-item",
                                        {
                                          attrs: { link: "", tag: "label" },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.accountLogout()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "q-item-main",
                                            {
                                              staticClass:
                                                "font-size-160p text-weight-semibold text-center"
                                            },
                                            [
                                              !_vm.dialogAccountPhoneNumberProcessing
                                                ? _c(
                                                    "q-item-tile",
                                                    { attrs: { label: "" } },
                                                    [_vm._v("Logout")]
                                                  )
                                                : _vm._e(),
                                              !_vm.dialogAccountPhoneNumberProcessing &&
                                              _vm.account.email
                                                ? _c(
                                                    "q-item-tile",
                                                    {
                                                      staticClass:
                                                        "lowercase font-size-80p",
                                                      attrs: { sublabel: "" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.account.email
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !_vm.dialogAccountPhoneNumberProcessing &&
                                              _vm.account.phoneNumber
                                                ? _c(
                                                    "q-item-tile",
                                                    {
                                                      staticClass:
                                                        "lowercase font-size-80p",
                                                      attrs: { sublabel: "" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.account
                                                            .phoneNumber
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.dialogAccountPhoneNumberProcessing
                                                ? _c(
                                                    "q-item-tile",
                                                    { attrs: { label: "" } },
                                                    [
                                                      _c("q-progress", {
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "-8px",
                                                          width: "300px",
                                                          "margin-left": "auto",
                                                          "margin-right": "auto"
                                                        },
                                                        attrs: {
                                                          stripe: "",
                                                          indeterminate: "",
                                                          color: "hint",
                                                          height: "8px"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              ),
                              _vm.account.isLoggedIn
                                ? _c(
                                    "q-list",
                                    {
                                      staticClass:
                                        "card text-family-brand full-width on-top-lg",
                                      attrs: { "no-border": "", link: "" }
                                    },
                                    [
                                      _c(
                                        "q-list-header",
                                        { attrs: { compact: "" } },
                                        [
                                          _vm._v(
                                            "\n            Wallet\n          "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "q-item",
                                        {
                                          attrs: { link: "", tag: "label" },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.walletOpen()
                                            }
                                          }
                                        },
                                        [
                                          _c("q-item-side", [
                                            _c("img", {
                                              staticClass:
                                                "brighten-50 dark-img-invert-100 on-left-sm",
                                              staticStyle: { width: "40px" },
                                              attrs: {
                                                src:
                                                  "/statics/_demo/lifestyle.app.wallet.dollar.svg"
                                              }
                                            })
                                          ]),
                                          _c(
                                            "q-item-main",
                                            {
                                              staticClass:
                                                "font-size-160p text-weight-semibold"
                                            },
                                            [
                                              _c(
                                                "q-item-tile",
                                                { attrs: { label: "" } },
                                                [_vm._v("U.S. Dollars")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-side",
                                            {
                                              staticClass:
                                                "font-size-180p text-weight-semibold text-empower",
                                              attrs: { right: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("nformat")(
                                                      _vm.wallet_amount,
                                                      "$0,0.00"
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item",
                                        {
                                          attrs: {
                                            link: "",
                                            tag: "label",
                                            disabled: ""
                                          }
                                        },
                                        [
                                          _c("q-item-side", [
                                            _c("img", {
                                              staticClass:
                                                "brighten-50 dark-img-invert-100 on-left-sm",
                                              staticStyle: { width: "40px" },
                                              attrs: {
                                                src:
                                                  "/statics/_demo/lifestyle.app.wallet.bitcoin.svg"
                                              }
                                            })
                                          ]),
                                          _c(
                                            "q-item-main",
                                            {
                                              staticClass:
                                                "font-size-160p text-weight-semibold"
                                            },
                                            [
                                              _c(
                                                "q-item-tile",
                                                { attrs: { label: "" } },
                                                [_vm._v("Bitcoin")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-side",
                                            { attrs: { right: "" } },
                                            [
                                              _c(
                                                "q-chip",
                                                {
                                                  staticClass: "q-chip-na",
                                                  attrs: { dense: "" }
                                                },
                                                [_vm._v("N/A")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "q-list",
                                {
                                  staticClass:
                                    "card text-family-brand full-width on-top-lg",
                                  attrs: { "no-border": "", link: "" }
                                },
                                [
                                  _c(
                                    "q-list-header",
                                    { attrs: { compact: "" } },
                                    [
                                      _vm._v(
                                        "\n            Settings\n          "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "q-item",
                                    { attrs: { link: "", tag: "label" } },
                                    [
                                      _c("q-item-side", [
                                        _c("img", {
                                          staticClass:
                                            "brighten-50 dark-img-invert-100 on-left-sm",
                                          staticStyle: { width: "40px" },
                                          attrs: {
                                            src:
                                              "/statics/_demo/lifestyle.app.description.svg"
                                          }
                                        })
                                      ]),
                                      _c(
                                        "q-item-main",
                                        {
                                          staticClass:
                                            "font-size-160p text-weight-semibold"
                                        },
                                        [
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "font-size-100p capitalize",
                                              attrs: { label: "" }
                                            },
                                            [_vm._v("Group details")]
                                          ),
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass: "font-size-80p",
                                              attrs: { sublabel: "" }
                                            },
                                            [
                                              _vm._v(
                                                "Display group description"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-side",
                                        { attrs: { right: "" } },
                                        [
                                          _c("q-toggle", {
                                            attrs: { dark: _vm.anyDarkmode },
                                            model: {
                                              value:
                                                _vm.settings_display_groups_description,
                                              callback: function($$v) {
                                                _vm.settings_display_groups_description = $$v
                                              },
                                              expression:
                                                "settings_display_groups_description"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    { attrs: { link: "", tag: "label" } },
                                    [
                                      _c("q-item-side", [
                                        _c("img", {
                                          staticClass:
                                            "brighten-50 dark-img-invert-100 on-left-sm",
                                          staticStyle: { width: "40px" },
                                          attrs: {
                                            src:
                                              "/statics/_demo/lifestyle.app.display.svg"
                                          }
                                        })
                                      ]),
                                      _c(
                                        "q-item-main",
                                        {
                                          staticClass:
                                            "font-size-160p text-weight-semibold"
                                        },
                                        [
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "font-size-100p capitalize",
                                              attrs: { label: "" }
                                            },
                                            [_vm._v("Nutritional details")]
                                          ),
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass: "font-size-80p",
                                              attrs: { sublabel: "" }
                                            },
                                            [_vm._v("Display macronutrients")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-side",
                                        { attrs: { right: "" } },
                                        [
                                          _c("q-toggle", {
                                            attrs: { dark: _vm.anyDarkmode },
                                            model: {
                                              value:
                                                _vm.settings_display_cards_mode,
                                              callback: function($$v) {
                                                _vm.settings_display_cards_mode = $$v
                                              },
                                              expression:
                                                "settings_display_cards_mode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    { attrs: { link: "", tag: "label" } },
                                    [
                                      _c("q-item-side", [
                                        _c("img", {
                                          staticClass:
                                            "brighten-50 dark-img-invert-100 on-left-sm",
                                          staticStyle: { width: "40px" },
                                          attrs: {
                                            src:
                                              "/statics/_demo/lifestyle.app.effects.svg"
                                          }
                                        })
                                      ]),
                                      _c(
                                        "q-item-main",
                                        {
                                          staticClass:
                                            "font-size-160p text-weight-semibold"
                                        },
                                        [
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "font-size-100p capitalize",
                                              attrs: { label: "" }
                                            },
                                            [_vm._v("Effects")]
                                          ),
                                          _c(
                                            "q-item-tile",
                                            { attrs: { sublabel: "" } },
                                            [
                                              _vm._v(
                                                "Shadows, blur, and motion effects"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-side",
                                        { attrs: { right: "" } },
                                        [
                                          _c("q-toggle", {
                                            attrs: { dark: _vm.anyDarkmode },
                                            model: {
                                              value:
                                                _vm.settings_display_effects,
                                              callback: function($$v) {
                                                _vm.settings_display_effects = $$v
                                              },
                                              expression:
                                                "settings_display_effects"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    { attrs: { link: "", tag: "label" } },
                                    [
                                      _c("q-item-side", [
                                        _c("img", {
                                          staticClass:
                                            "brighten-50 dark-img-invert-100 on-left-sm",
                                          staticStyle: { width: "40px" },
                                          attrs: {
                                            src:
                                              "/statics/_demo/lifestyle.app.sound.svg"
                                          }
                                        })
                                      ]),
                                      _c(
                                        "q-item-main",
                                        {
                                          staticClass:
                                            "font-size-160p text-weight-semibold"
                                        },
                                        [
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "font-size-100p capitalize",
                                              attrs: { label: "" }
                                            },
                                            [_vm._v("Sounds")]
                                          ),
                                          _c(
                                            "q-item-tile",
                                            { attrs: { sublabel: "" } },
                                            [_vm._v("Immersive accent sounds")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-side",
                                        { attrs: { right: "" } },
                                        [
                                          _c("q-toggle", {
                                            attrs: { dark: _vm.anyDarkmode },
                                            model: {
                                              value: _vm.settings_sound,
                                              callback: function($$v) {
                                                _vm.settings_sound = $$v
                                              },
                                              expression: "settings_sound"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    { attrs: { link: "", tag: "label" } },
                                    [
                                      _c("q-item-side", [
                                        _c("img", {
                                          staticClass:
                                            "brighten-50 dark-img-invert-100 on-left-sm",
                                          staticStyle: { width: "40px" },
                                          attrs: {
                                            src:
                                              "/statics/_demo/lifestyle.app.voice.svg"
                                          }
                                        })
                                      ]),
                                      _c(
                                        "q-item-main",
                                        {
                                          staticClass:
                                            "font-size-160p text-weight-semibold"
                                        },
                                        [
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "font-size-100p capitalize",
                                              attrs: { label: "" }
                                            },
                                            [
                                              _c(
                                                "q-chip",
                                                {
                                                  staticClass: "q-chip-pro",
                                                  attrs: { dense: "" }
                                                },
                                                [_vm._v("PRO")]
                                              ),
                                              _vm._v("  Voice A.I.")
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-tile",
                                            { attrs: { sublabel: "" } },
                                            [
                                              _vm._v(
                                                "Enable voice-driven interface"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-side",
                                        { attrs: { right: "" } },
                                        [
                                          _c("q-toggle", {
                                            attrs: { dark: _vm.anyDarkmode },
                                            model: {
                                              value: _vm.settings_voice,
                                              callback: function($$v) {
                                                _vm.settings_voice = $$v
                                              },
                                              expression: "settings_voice"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.product.data.channel.online
                    ? _c(
                        "q-modal",
                        {
                          staticClass: "appLayer dialog-item",
                          attrs: {
                            id: "dialogDropoff",
                            position: "bottom",
                            "enter-class": "animated slideInUp animated-d400",
                            "leave-class": "animated slideOutDown animated-d400"
                          },
                          model: {
                            value: _vm.dialogDropoffShow,
                            callback: function($$v) {
                              _vm.dialogDropoffShow = $$v
                            },
                            expression: "dialogDropoffShow"
                          }
                        },
                        [
                          _c(
                            "q-modal-layout",
                            [
                              _c(
                                "q-toolbar",
                                {
                                  directives: [
                                    {
                                      name: "touch-pan",
                                      rawName:
                                        "v-touch-pan.vertical.prevent.stop",
                                      value: _vm.modalAdapt,
                                      expression: "modalAdapt",
                                      modifiers: {
                                        vertical: true,
                                        prevent: true,
                                        stop: true
                                      }
                                    }
                                  ],
                                  staticClass: "cursor-grab",
                                  attrs: { slot: "header", inverted: "" },
                                  slot: "header"
                                },
                                [
                                  _c("q-toolbar-title", [
                                    _vm._v(_vm._s(_vm.$t("DROPOFF.LABEL_TT")))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "q-toolbar",
                                {
                                  staticClass: "toolbar-overscroll-shadow",
                                  attrs: { slot: "header", inverted: "" },
                                  slot: "header"
                                },
                                [
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                              attrs: {
                                                color: "tertiary-light",
                                                "text-color": "subinfo",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  _vm.soundPlay("sheet_up")
                                                  _vm.scan()
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/" +
                                                    (_vm.anyDarkmode
                                                      ? "scan_white.svg"
                                                      : "scan_tertiary.svg"),
                                                  height: "24"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-card",
                                    {
                                      staticClass:
                                        "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                    },
                                    [
                                      _c(
                                        "q-card-main",
                                        {
                                          staticClass:
                                            "column justify-center full-height"
                                        },
                                        [
                                          _c(
                                            "q-btn",
                                            {
                                              staticClass:
                                                "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                              attrs: {
                                                color: "primary-light",
                                                "text-color": "primary",
                                                rounded: ""
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  _vm.dialogDropoffShow = false
                                                  _vm.soundPlay("tap")
                                                  _vm.soundStop("pinging")
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/" +
                                                    (_vm.anyDarkmode
                                                      ? "chevron.compact.down_white.svg"
                                                      : "chevron.compact.down_primary.svg"),
                                                  height: "10"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "layout-padding no-padding-top text-center text-family-brand"
                                },
                                [
                                  _c("q-scroll-observable", {
                                    on: {
                                      scroll: _vm.toolbarShadowOnOverscroll
                                    }
                                  }),
                                  _vm.intentions.dropoffs.lastscan
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "animated fadeInUp" },
                                          [
                                            _c("div", [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "q-spinner text-gold",
                                                  staticStyle: {
                                                    "margin-top": "-110px",
                                                    "margin-bottom": "-187px",
                                                    opacity: "0.2",
                                                    "max-width": "90vw"
                                                  },
                                                  attrs: {
                                                    stroke: "currentColor",
                                                    width: "20em",
                                                    height: "20em",
                                                    viewBox: "0 0 44 44",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "g",
                                                    {
                                                      attrs: {
                                                        fill: "none",
                                                        "fill-rule": "evenodd",
                                                        "stroke-width": "2"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "circle",
                                                        {
                                                          attrs: {
                                                            cx: "22",
                                                            cy: "22",
                                                            r: "16.0582"
                                                          }
                                                        },
                                                        [
                                                          _c("animate", {
                                                            attrs: {
                                                              attributeName:
                                                                "r",
                                                              begin: "0s",
                                                              dur: "2s",
                                                              values: "1; 20",
                                                              calcMode:
                                                                "spline",
                                                              keyTimes: "0; 1",
                                                              keySplines:
                                                                "0.165, 0.84, 0.44, 1",
                                                              repeatCount:
                                                                "indefinite"
                                                            }
                                                          }),
                                                          _c("animate", {
                                                            attrs: {
                                                              attributeName:
                                                                "stroke-opacity",
                                                              begin: "0s",
                                                              dur: "2s",
                                                              values: "1; 0",
                                                              calcMode:
                                                                "spline",
                                                              keyTimes: "0; 1",
                                                              keySplines:
                                                                "0.3, 0.61, 0.355, 1",
                                                              repeatCount:
                                                                "indefinite"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]),
                                            _vm.intentions.dropoffs.lastscan
                                              .type === "winglet"
                                              ? _c("img", {
                                                  staticClass:
                                                    "block margin-auto-lr relative-position",
                                                  attrs: {
                                                    src:
                                                      "/statics/_demo/letsbutterfly-wings-hw-winglet.svg",
                                                    height: "60"
                                                  }
                                                })
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "wings-hw-winglet-standing"
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "block margin-auto-lr",
                                                      attrs: {
                                                        src: require("assets/letsbutterfly-wings-winglet.png"),
                                                        height: "60"
                                                      }
                                                    })
                                                  ]
                                                )
                                          ]
                                        ),
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "no-margin text-secondary"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.intentions.dropoffs.lastscan
                                                  .name
                                              )
                                            )
                                          ]
                                        ),
                                        _c("p", [
                                          _vm._v(
                                            "You are at a dropoff spot for "
                                          ),
                                          _c("br"),
                                          _c(
                                            "strong",
                                            {
                                              staticClass:
                                                "text-weight-semibold font-size-120p"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.productFriendlyName)
                                              )
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-shallow text-weight-semibold no-margin-bottom"
                                          },
                                          [
                                            _vm._v(
                                              "\n            This spot is reserved for\n          "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "no-margin text-empower text-family-brand text-weight-bold",
                                            staticStyle: { "font-size": "44px" }
                                          },
                                          [
                                            _vm._v("\n            5"),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "inline-block",
                                                staticStyle: {
                                                  position: "relative",
                                                  top: "-4px"
                                                }
                                              },
                                              [_vm._v(":")]
                                            ),
                                            _vm._v("00\n          ")
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-empower font-size-80p text-weight-bold block",
                                            staticStyle: {
                                              "margin-top": "-10px"
                                            }
                                          },
                                          [_vm._v("minutes")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "columns items-center justify-around row"
                                          },
                                          [
                                            _c(
                                              "q-card",
                                              {
                                                staticClass:
                                                  "width-50p column margin-auto-lr q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top",
                                                staticStyle: {
                                                  "min-height":
                                                    "80px !important"
                                                }
                                              },
                                              [
                                                _c(
                                                  "q-card-main",
                                                  {
                                                    staticClass:
                                                      "block column justify-center full-height"
                                                  },
                                                  [
                                                    _c(
                                                      "q-btn",
                                                      {
                                                        staticClass:
                                                          "q-btn-stack limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                                        attrs: {
                                                          color: "empower",
                                                          "text-color": "white",
                                                          rounded: ""
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.soundPlay(
                                                              "sheet_up"
                                                            )
                                                            _vm.scan()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src:
                                                              "/statics/_demo/dropoff.swap_white.svg"
                                                          }
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "block"
                                                          },
                                                          [_vm._v("Swap")]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-card",
                                              {
                                                staticClass:
                                                  "width-50p column margin-auto-lr q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top",
                                                staticStyle: {
                                                  "min-height":
                                                    "80px !important"
                                                }
                                              },
                                              [
                                                _c(
                                                  "q-card-main",
                                                  {
                                                    staticClass:
                                                      "column justify-center full-height"
                                                  },
                                                  [
                                                    _c(
                                                      "q-btn",
                                                      {
                                                        staticClass:
                                                          "q-btn-stack limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                                        attrs: {
                                                          color: "shadow-l",
                                                          "text-color": "core",
                                                          rounded: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.intentions.dropoffs.lastscan = null
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src:
                                                              "/statics/_demo/dropoff.cancel_tertiary.svg"
                                                          }
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "block"
                                                          },
                                                          [_vm._v("Clear")]
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "transition",
                                            {
                                              attrs: {
                                                appear: "",
                                                "enter-active-class":
                                                  "animated fadeInUp animated-d800",
                                                "leave-active-class":
                                                  "animated fadeOutDown animated-d800"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.product.data.channel
                                                          .online,
                                                      expression:
                                                        "product.data.channel.online"
                                                    }
                                                  ]
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticStyle: {
                                                        "margin-bottom":
                                                          "-112px",
                                                        "padding-bottom": "20px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v("SCAN "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "lowercase text-weight-regular inline-block"
                                                        },
                                                        [_vm._v("FOR")]
                                                      ),
                                                      _vm._v(" SERVICE")
                                                    ]
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "svg",
                                                      {
                                                        staticClass:
                                                          "q-spinner text-gold",
                                                        class: {
                                                          invisible: !_vm.dialogDropoffShow
                                                        },
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "-13em",
                                                          opacity: "0.2",
                                                          "max-width": "90vw"
                                                        },
                                                        attrs: {
                                                          stroke:
                                                            "currentColor",
                                                          width: "20em",
                                                          height: "20em",
                                                          viewBox: "0 0 44 44",
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "g",
                                                          {
                                                            attrs: {
                                                              fill: "none",
                                                              "fill-rule":
                                                                "evenodd",
                                                              "stroke-width":
                                                                "2"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "circle",
                                                              {
                                                                attrs: {
                                                                  cx: "22",
                                                                  cy: "22",
                                                                  r: "16.0582"
                                                                }
                                                              },
                                                              [
                                                                _c("animate", {
                                                                  attrs: {
                                                                    attributeName:
                                                                      "r",
                                                                    begin: "0s",
                                                                    dur: "1.8s",
                                                                    values:
                                                                      "1; 20",
                                                                    calcMode:
                                                                      "spline",
                                                                    keyTimes:
                                                                      "0; 1",
                                                                    keySplines:
                                                                      "0.165, 0.84, 0.44, 1",
                                                                    repeatCount:
                                                                      "indefinite"
                                                                  }
                                                                }),
                                                                _c("animate", {
                                                                  attrs: {
                                                                    attributeName:
                                                                      "stroke-opacity",
                                                                    begin: "0s",
                                                                    dur: "1.8s",
                                                                    values:
                                                                      "1; 0",
                                                                    calcMode:
                                                                      "spline",
                                                                    keyTimes:
                                                                      "0; 1",
                                                                    keySplines:
                                                                      "0.3, 0.61, 0.355, 1",
                                                                    repeatCount:
                                                                      "indefinite"
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _c(
                                                    "q-btn",
                                                    {
                                                      staticClass:
                                                        "block margin-auto-lr",
                                                      staticStyle: {
                                                        "margin-bottom": "20px",
                                                        width: "100px"
                                                      },
                                                      attrs: { rounded: "" },
                                                      on: { click: _vm.scan }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "block margin-auto-lr",
                                                        attrs: {
                                                          src: require("assets/letsbutterfly-wings-winglet.png"),
                                                          height: "100"
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "no-margin"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Tap above to scan a dropoff spot for "
                                                      ),
                                                      _c("br"),
                                                      _c(
                                                        "strong",
                                                        {
                                                          staticClass:
                                                            "text-weight-semibold font-size-120p"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.productFriendlyName
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                  _c("hr", { staticClass: "dot" }),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "caption text-weight-regular text-subinfo"
                                    },
                                    [
                                      _vm._v(
                                        "\n          The list of services offered is communicated and fulfilled by " +
                                          _vm._s(_vm.productFriendlyName) +
                                          " located at " +
                                          _vm._s(
                                            _vm.product.data.business.address
                                              .full
                                          ) +
                                          ".\n        "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: { id: "dialogQRCode", position: "bottom" },
                      model: {
                        value: _vm.dialogQRCodeShow,
                        callback: function($$v) {
                          _vm.dialogQRCodeShow = $$v
                        },
                        expression: "dialogQRCodeShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        {
                          staticStyle: {
                            "background-image":
                              "url(/statics/_demo/qrcode_primary.svg)"
                          }
                        },
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(_vm._s(_vm.$t("QRCODE.LABEL")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogQRCodeShow = false
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/" +
                                                (_vm.anyDarkmode
                                                  ? "chevron.compact.down_white.svg"
                                                  : "chevron.compact.down_primary.svg"),
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "layout-padding no-padding-top text-center"
                            },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    appear: "",
                                    "enter-active-class":
                                      "animated fadeInUp animated-d800"
                                  }
                                },
                                [
                                  _vm.product.data.qrcode_ref
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "95%",
                                          "border-radius": "2rem",
                                          "max-width": "53vh"
                                        },
                                        attrs: {
                                          src: _vm.product.data.qrcode_ref
                                        }
                                      })
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    appear: "",
                                    "enter-active-class":
                                      "animated fadeInUp animated-d800"
                                  }
                                },
                                [
                                  _vm.product.data.qrcode_ref
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-family-brand text-weight-semibold text-center font-size-100p text-attention",
                                          staticStyle: {
                                            "word-break": "break-all",
                                            "margin-bottom": "-20px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n          " +
                                              _vm._s(
                                                _vm.product.data.shortlink.replace(
                                                  "ltsbtrf.ly",
                                                  "mywin.gs"
                                                )
                                              ) +
                                              "\n          "
                                          ),
                                          _c("q-btn", {
                                            attrs: {
                                              flat: "",
                                              round: "",
                                              icon: "ion-link"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.openURL(
                                                  _vm.product.data.shortlink.replace(
                                                    "ltsbtrf.ly",
                                                    "mywin.gs"
                                                  )
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: { id: "dialogAbout", position: "bottom" },
                      model: {
                        value: _vm.dialogAboutShow,
                        callback: function($$v) {
                          _vm.dialogAboutShow = $$v
                        },
                        expression: "dialogAboutShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        {
                          staticStyle: {
                            "background-image": "url(/statics/_demo/info.svg)"
                          }
                        },
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(_vm._s(_vm.$t("DRAWER.ITEM.ABOUT.L")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogAboutShow = false
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/" +
                                                (_vm.anyDarkmode
                                                  ? "chevron.compact.down_white.svg"
                                                  : "chevron.compact.down_primary.svg"),
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "layout-padding no-padding-top" },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _c("p", {
                                staticClass:
                                  "text-family-brand q-title layout-padding no-padding-top text-subinfo slideInDown",
                                staticStyle: { "padding-bottom": "20px" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.product.data.business.metas.description
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "q-modal",
                    {
                      staticClass: "appLayer dialog-item",
                      attrs: { id: "dialogShare", position: "bottom" },
                      model: {
                        value: _vm.dialogShareShow,
                        callback: function($$v) {
                          _vm.dialogShareShow = $$v
                        },
                        expression: "dialogShareShow"
                      }
                    },
                    [
                      _c(
                        "q-modal-layout",
                        {
                          staticStyle: {
                            "background-image":
                              "url(/statics/_demo/square.and.arrow.up.fill_primary.svg)"
                          }
                        },
                        [
                          _c(
                            "q-toolbar",
                            {
                              directives: [
                                {
                                  name: "touch-pan",
                                  rawName: "v-touch-pan.vertical.prevent.stop",
                                  value: _vm.modalAdapt,
                                  expression: "modalAdapt",
                                  modifiers: {
                                    vertical: true,
                                    prevent: true,
                                    stop: true
                                  }
                                }
                              ],
                              staticClass: "cursor-grab",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c("q-toolbar-title", [
                                _vm._v(_vm._s(_vm.$t("SHARE")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-toolbar",
                            {
                              staticClass: "toolbar-overscroll-shadow",
                              attrs: { slot: "header", inverted: "" },
                              slot: "header"
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "primary-light",
                                            "text-color": "primary",
                                            rounded: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.dialogShareShow = false
                                              _vm.soundPlay("tap")
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/" +
                                                (_vm.anyDarkmode
                                                  ? "chevron.compact.down_white.svg"
                                                  : "chevron.compact.down_primary.svg"),
                                              height: "10"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "layout-padding no-padding-top" },
                            [
                              _c("q-scroll-observable", {
                                on: { scroll: _vm.toolbarShadowOnOverscroll }
                              }),
                              _c(
                                "q-list",
                                {
                                  staticClass: "text-family-brand",
                                  attrs: {
                                    "no-border": "",
                                    link: "",
                                    dark: _vm.anyDarkmode
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "q-title layout-padding no-padding-top text-subinfo slideInDown",
                                      staticStyle: { "padding-bottom": "20px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n            Share this channel using any of the following methods and platforms for everyone to view.\n          "
                                      )
                                    ]
                                  ),
                                  _vm.shareSheetSupport()
                                    ? _c(
                                        "q-item",
                                        {
                                          attrs: { item: "" },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.shareSheet($event)
                                            }
                                          }
                                        },
                                        [
                                          _c("q-item-main", {
                                            staticClass:
                                              "font-size-160p text-weight-semibold",
                                            attrs: {
                                              label: _vm.$t("SHARESHEET.LABEL")
                                            }
                                          }),
                                          _c(
                                            "q-item-side",
                                            {
                                              staticClass:
                                                "text-center on-top-sm"
                                            },
                                            [
                                              _c("img", {
                                                class: {
                                                  "filter-invert-80":
                                                    _vm.anyDarkmode
                                                },
                                                staticStyle: { height: "33px" },
                                                attrs: {
                                                  src:
                                                    "/statics/_demo/rectangle.stack.fill.svg"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "q-item",
                                    {
                                      attrs: { item: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.openURL(_vm.productFullURI)
                                        }
                                      }
                                    },
                                    [
                                      _c("q-item-main", {
                                        staticClass:
                                          "font-size-160p text-weight-semibold",
                                        attrs: {
                                          label: _vm.$t("WEBLINK.LABEL")
                                        }
                                      }),
                                      _c(
                                        "q-item-side",
                                        {
                                          staticClass: "text-center on-top-sm"
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: { height: "33px" },
                                            attrs: {
                                              src:
                                                "/statics/_demo/square.and.arrow.up.fill_primary.svg"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    {
                                      attrs: { item: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.qrcode($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("q-item-main", {
                                        staticClass:
                                          "font-size-160p text-weight-semibold",
                                        attrs: { label: _vm.$t("QRCODE.LABEL") }
                                      }),
                                      _c(
                                        "q-item-side",
                                        {
                                          staticClass: "text-center on-top-sm"
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: { height: "33px" },
                                            attrs: {
                                              src:
                                                "/statics/_demo/qrcode_primary.svg"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { height: "40px" } }),
                  _c("l-footer", { attrs: { simple: "" } })
                ],
                2
              )
            ]
          ),
          _c(
            "q-page-sticky",
            { attrs: { position: "bottom", offset: [0, 30] } },
            [
              _c(
                "transition",
                {
                  attrs: {
                    appear: "",
                    "enter-active-class": "animated fadeInUp animated400",
                    "leave-active-class": "animated fadeOutDown animated400"
                  }
                },
                [
                  _vm.bag.items.length && _vm.stickyButtonOrderShow
                    ? _c(
                        "q-btn",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          staticClass:
                            "shadow-24 text-weight-bold animated800 animated-c1",
                          class: { "animate-bounce": _vm.bagUpdated },
                          staticStyle: { height: "60px" },
                          attrs: {
                            rounded: "",
                            size: "lg",
                            push: "",
                            color: "primary"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.dialogBagOpen()
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "filter-invert-100",
                            attrs: {
                              src: "/statics/_demo/bag.fill.svg",
                              width: "22"
                            }
                          }),
                          _vm._v(
                            "   " +
                              _vm._s(
                                _vm._f("nformat")(_vm.bag.total, "$0,0.00")
                              ) +
                              "\n    "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-page-sticky",
            { attrs: { position: "bottom-right", offset: [20, 30] } },
            [
              _c(
                "transition",
                {
                  attrs: {
                    mode: "out-in",
                    appear: "",
                    "enter-active-class": "animated fadeInUp animated400",
                    "leave-active-class": "animated fadeOutDown animated400"
                  }
                },
                [
                  _vm.settings_voice
                    ? _c(
                        "q-btn",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          key: "mic",
                          staticClass: "shadow-24",
                          attrs: {
                            size: "lg",
                            round: "",
                            push: "",
                            color: "primary"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.personalizeVoice()
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/statics/_demo/mic_white.svg",
                              width: "22"
                            }
                          })
                        ]
                      )
                    : _c(
                        "q-btn",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          key: "heart",
                          staticClass: "shadow-24",
                          attrs: {
                            size: "lg",
                            round: "",
                            push: "",
                            color: "primary"
                          },
                          nativeOn: {
                            click: function($event) {
                              _vm.personalizeList()
                              _vm.soundPlay("sheet_up")
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/statics/_demo/heart.fill_white.svg",
                              width: "22"
                            }
                          })
                        ]
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }