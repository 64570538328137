<template>
  <div v-if="ready"><q-pull-to-refresh :handler="channelInit" refresh-icon="ion-sync" v-bind:release-message="$t('PTR.RELEASE')" v-bind:refresh-message="$t('PTR.REFRESH')" v-bind:pull-message="$t('PTR.PULL')" color="faded">

  <div class="product column items-center">
    <q-scroll-observable @scroll="scrolled"/>

    <q-card class="q-card-flat text-center" @click.native="contentInfoShow = !contentInfoShow">
      <q-card-title>
        <!-- <img v-if="productBanner || productIcon" class="margin-auto-lr block on-bottom" :class="{'banner-image': productBanner}" :src="productBanner || productIcon" style="max-height:150px"> -->
        <span :class="{hidden: productNameHidden}" class="font-size-140p text-weight-semibold inline-block" style="line-height: 1.1em">
          {{ paramName }}
        </span>
        <div class="block text-family-brand text-subinfo-l capitalize font-size-90p" style="margin-top: -4px">
          <span class="text-weight-semibold uppercase inline-block ellipsis" style="line-height:1em;width:90vw" v-html="product.data.business.address.html"/>
        </div>
      </q-card-title>
    </q-card>

    <!-- info -->
    <transition v-if="contentInfoShow" appear enter-active-class="animated zoomIn animated-d400" leave-active-class="animated zoomOut animated-d200">
      <div class="row no-wrap justify-center items-center relative-position" style="max-width: 400px; height: 70px" key="info">
        <q-btn v-if="shareSheetSupport()" size="1.4em" :text-color="anyDarkmode ? 'shallow' : 'subinfo_l'" round @click.native="shareSheet" style="margin: 0px 10px">
          <img src="/statics/_demo/square.and.arrow.up.fill_primary.svg" width="28px" :style="anyDarkmode ? 'filter: invert(.5)' : ''">
        </q-btn>
        <q-btn size="1.4em" :text-color="anyDarkmode ? 'shallow' : 'subinfo_l'" round @click.native="qrcode" style="margin: 0px 10px">
          <img src="/statics/_demo/qrcode_primary.svg" width="34px" :style="anyDarkmode ? 'filter: invert(.5)' : ''">
        </q-btn>
        <q-btn v-if="channel && channel.description === 0" size="1.4em" :text-color="anyDarkmode ? 'shallow' : 'subinfo_l'" round @click.native="about" style="margin: 0px 10px">
          <img src="/statics/_demo/info_primary.svg" width="34px" :style="anyDarkmode ? 'filter: invert(.5)' : ''">
        </q-btn>
      </div>
    </transition>

    <!-- guardian -->
    <q-card inline class="limit-width-420 overflow-hidden q-card-grouped q-card-flat on-top-default">
      <q-card-title class="no-padding">
        <div class="row justify-start items-center">
          <transition mode="out-in" appear enter-active-class="animated slideInUp animated-d400" leave-active-class="animated slideOutDown animated-d200">
            <div key="closed" v-if="channel && channel.online === 0" class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-red">
              Closed
            </div>
            <div :key="`current-${channel.orders}`" v-else-if="channel && channel.online === 1" class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder" :class="{ 'text-educate': channel.orders === 0, 'text-attention': channel.orders === 1 }">
              {{ (datagroupDataValue('services', 'orders', channel.orders)) }}
            </div>
            <div key="others" v-else class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder" :class="{ 'text-educate': channel && channel.orders === 0, 'text-attention': channel && channel.orders === 1 }">
              &nbsp;
            </div>
          </transition>
        </div>
      </q-card-title>
    </q-card>

    <transition appear enter-active-class="animated fadeInUp animated-d800" leave-active-class="animated fadeOutUp animated-d800">
      <q-card key="guardian" inline class="no-border limit-width-420 overflow-hidden q-card-grouped q-card-widget"
        :class="{
          'q-card-widget-guard': !channel || (channel && !channel.online),
          'q-card-widget-guard-online': channel && channel.online,
          'q-card-widget-guard-warning': channel && channel.online && channel.orders === 1
        }"
        style="margin-top:10px"
        ref="product-card-guardian" v-touch-pan.noMouse="(obj) => { setCardIntent(obj, 'guardian', () => { personalizeList(); /*personalize();*/ soundPlay('sheet_up') }) }">
        <template>
          <q-progress v-if="channel === null" stripe indeterminate color="hint" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="channel === false" stripe :percentage="100" color="hint" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="channel.online && channel.orders === 1" stripe indeterminate color="black" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="channel.online" stripe :percentage="100" indeterminate color="green-d" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else :percentage="100" stripe color="protect" height="8px" style="margin-bottom:-8px"/>
        </template>
        <q-card-title>
          <transition mode="out-in" appear enter-active-class="animated fadeIn animated-d400" leave-active-class="animated fadeOut animated-d200">
          <div :key="channel ? `guardian-info-img-${channel.online}-${channel.orders}` : `guardian-info-img-null`" class="row justify-start items-center">
            <div class="left on-left-lg items-center row text-center justify-center" style="width:55px;height:70px;">
              <img v-if="channel === false" src="/statics/_demo/circle.b3line_white.svg" width="48" class="brighten-50 dark-brighten-100">
              <img v-else-if="!channel" src="/statics/_demo/circles4_white.svg" width="48" class="brighten-50 dark-brighten-100">
              <img v-else-if="channel.online && channel.orders === 1" src="/statics/_demo/exclamationmark_white.svg" width="48">
              <img v-else-if="channel.online" src="/statics/_demo/heart_white.svg" width="48">
              <img v-else src="/statics/_demo/xmark_white.svg" height="48" class="brighten-50 dark-img-brighten-50 dark-img-invert-100">
            </div>
            <div :key="channel ? `guardian-info-text-${channel.online}-${channel.orders}` : `guardian-info-text-null`" class="float-left line-height-sm">
              <div class="font-size-140p uppercase">
                <span v-if="channel === null">{{ $t('CONNECTING') }}</span>
                <span v-else-if="channel.online === 1 && channel.orders === 0">ON</span>
                <span v-else-if="channel.online === 1 && channel.orders === 1">ATTENTION</span>
                <span v-else-if="channel === false">{{ $t('NEW') }}</span>
                <span v-else>{{ $t('Off') }}</span>
              </div>
              <div v-if="channel === false" slot="subtitle" class="text-weight-regular font-size-100p block on-top-sm">
                {{ $t('EMPTY_COMMUNICATION') }}
              </div>
              <div v-if="channel" slot="subtitle" class="text-weight-regular font-size-100p block on-top-sm">
                <span v-if="channel.online">Personalize your lifestyle</span>
                <span v-else>Check back next time.</span>
              </div>
            </div>
          </div>
          </transition>
          <span slot="right" class="absolute text-family-brand text-weight-semibold absolute-top-right on-left-lg on-top-lg">
            <transition appear enter-active-class="animated bounceIn animated-d800" leave-active-class="animated bounceOut animated-d800">
              <span v-if="channel && channel.online === 1" class="chip-live">LIVE</span>
              <span v-else-if="channel && channel.online === false" class="hidden text-subinfo-l">3:22 hours ago</span>
            </transition>
          </span>
        </q-card-title>
        <q-card-separator v-if="channel !== null && channel !== false && channel.online" v-show="!$q.platform.has.touch"/>
        <q-card-actions  v-if="channel !== null && channel !== false && channel.online" v-show="!$q.platform.has.touch">
          <q-btn class="full-width" color="white" flat rounded :label="$t('VIEW')" @click.native="personalizeList(); soundPlay('sheet_up')">
            <q-tooltip :delay="2000" inverted>
              {{ $t('VIEW') }}
            </q-tooltip>
          </q-btn>
        </q-card-actions>
      </q-card>
    </transition>

    <!-- order / drop off communication -->
    <template v-if="channel !== null && channel !== false">
      <!-- status -->
      <transition appear enter-active-class="animated fadeInUp animated-d800" leave-active-class="animated fadeOutUp animated-d800">
        <p v-if="channel === null || channel === false" class="on-top-xl text-family-brand text-weight-semibold text-shallow capitalize">--</p>
        <p v-if="!channel || !channel.online" class="on-top-xl text-family-brand text-weight-semibold text-shallow capitalize">{{ $t('COMMUNICATION.LAST') }}</p>
        <p v-if="channel && channel.online" class="on-top-xl text-family-brand text-weight-semibold text-shallow capitalize">{{ $t('COMMUNICATION.LIVE') }}</p>
      </transition>
      <transition appear enter-active-class="animated fadeInUp animated-d800" leave-active-class="animated fadeOutDown animated-d800">
        <div class="full-width limit-width-420 dev-width-breath text-family-brand text-center row justify-around items-center on-top-default">
          <q-card :class="{ 'animate-bounce': bagUpdated }" :disabled="!channel || !channel.online" inline class="overflow-hidden q-card-grouped q-card-widget animated800 animated-c1" style="width:46%;min-height:164px"
            :ref="`product-card-queue`" v-touch-pan.noMouse="(obj) => { setCardIntent(obj, 'queue', dialogQueueOpen) }"
          >
            <q-card-title style="height: 96px">
              <img src="/statics/_demo/stack.processing_black.svg" style="width: 54px; margin-top: 18px" :class="{ 'filter-invert-80': anyDarkmode }">
            </q-card-title>
            <q-card-main style="padding: 8px 0px 8px 0px">
              <span dir="ltr" class="font-size-180p text-weight-bold text-empower" v-if="channel && channel.service_time >= 0">
                QUEUE
                <br><span class="text-shallow">{{ requests_user_count }}</span><em class="text-shallow font-size-100p on-left-xs">of</em>{{ (channel && channel.requests && channel.requests.length) || 0 }}
              </span>
              <span class="block text-shallow text-weight-bold uppercase">items</span>
            </q-card-main>
            <q-card-separator v-show="!$q.platform.has.touch"/>
            <q-card-actions v-show="!$q.platform.has.touch">
              <q-btn class="full-width" color="primary" flat rounded :label="$t('VIEW')" @click="dialogQueueOpen">
                <q-tooltip :delay="2000" inverted>
                  {{ $t('VIEW') }}
                </q-tooltip>
              </q-btn>
            </q-card-actions>
          </q-card>
          <q-card :disabled="!channel || !channel.online" inline class="overflow-hidden q-card-grouped q-card-widget" style="width:46%;margin-top:0;min-height:164px"
            :ref="`product-card-order-winglet`" v-touch-pan.noMouse="(obj) => { setCardIntent(obj, 'order-winglet', wingletDialogOpen) }"
          >
            <q-card-title style="height: 96px">
              <template v-if="intentions.dropoffs.lastscan">
                <div>
                  <svg stroke="currentColor" width="20em" height="20em" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" class="q-spinner text-gold" 
                    style="opacity: 0.2; max-width: 100%; position: absolute; top: -30px; left: 0; height: 170px"><g fill="none" fill-rule="evenodd" stroke-width="2"
                  ><circle cx="22" cy="22" r="16.0582"><animate attributeName="r" begin="0s" dur="2s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate><animate attributeName="stroke-opacity" begin="0s" dur="2s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate></circle></g></svg>
                </div>
                <img src="~/assets/letsbutterfly-wings-winglet.png" height="60" class="block margin-auto-lr" style="position: absolute; top: 25px; left: calc(50% - 29px);">
              </template>
              <img v-else src="/statics/_demo/winglet.svg" style="width: 65px; margin-top: 12px" :class="{ 'filter-invert-80': anyDarkmode }">
            </q-card-title>
            <q-card-main style="padding: 8px 0px 8px 0px">
              <span dir="ltr" class="font-size-180p text-weight-bold text-secondary" v-if="channel && intentions.dropoffs.lastscan">
                {{ intentions.dropoffs.lastscan.name.split(' ')[0] }}
                <br>{{ intentions.dropoffs.lastscan.name.split(' ')[1] }}
              </span>
              <span class="font-size-180p text-weight-bold text-shallow" v-else>--<br>&nbsp;</span>
              <span class="block text-shallow text-weight-bold uppercase">{{ $t('DROPOFF.LABEL_TT') }}</span>
            </q-card-main>
            <q-card-separator v-show="!$q.platform.has.touch"/>
            <q-card-actions v-show="!$q.platform.has.touch">
              <q-btn class="full-width" color="primary" flat rounded :label="$t('VIEW')" @click.native="wingletDialogOpen">
                <q-tooltip :delay="2000" inverted>
                  {{ $t('VIEW') }}
                </q-tooltip>
              </q-btn>
            </q-card-actions>
          </q-card>
        </div>
      </transition>

      <!-- offerings -->
      <template v-for="(group, index) of offerings.groups">
        <div v-if="group.list" :key="`product-groups-${index}`" class="row justify-between items-center margin-auto-lr datagroup-container on-top-xl">
          <transition appear enter-active-class="animated fadeInUp animated-d800">
            <div class="full-width title-group text-family-brand text-weight-bolder text-center uppercase">
              <img :src="`/statics/_demo/persona.styles.${index}.svg`" class="block margin-auto-lr opacity-3" width="40" style="margin-bottom: -8px">
              {{ $t(index.toUpperCase() + '.LABEL') }}
              <!-- <p v-show="settings_display_groups_description" v-if="group.description" class="title-group capitalize" style="margin: 0; margin-top: -8px; margin-bottom: 18px; font-size: 22px">{{ group.description }}</p> -->
            </div>
          </transition>
          <p v-show="settings_display_groups_description" v-if="group.description" class="full-width text-center text-family-brand text-weight-bold font-size-140p text-shallow" style="margin: 10px auto 0px auto">
            {{ group.description }}
          </p>
          <template v-for="item of group.list">
            <transition v-if="offerings_computed[item] && channel[`${offerings.items[offerings_computed[item].offer].station}_staff`] !== 0" :key="`product-groups-${index}-${item}`" appear enter-active-class="animated fadeInUp animated-d800">
              <q-card inline class="overflow-hidden q-card-grouped q-card-widget q-card-title-columns"
                :data-index="['item', index, item].join('-')" :data-item="item"
                :ref="`product-card-` + ['item', index, item].join('-')"
                v-touch-pan.noMouse="(obj) => { setCardIntent(obj, ['item', index, item].join('-'), processCardSelection) }"
              >
                <q-card-title>
                  <div class="q-card-title-columns-wrapper">
                    <div class="q-card-title-columns-hero" :style="`background-image: url(${offerings_computed[item].products[0].photo});`"></div>
                    <div class="q-card-title-columns-content text-weight-semibold font-size-180p capitalize">
                      <!-- signature -->
                      <span v-if="offerings_computed[item].products[0].signature" class="q-chip q-chip-card q-chip-info">PH</span>

                      <!-- top-details -->
                      <div style="padding-top: 10px">
                        <!-- personas -->
                        <div v-if="offerings_computed[item].products.length === 1" class="hidden">
                          <template v-for="persona in offerings_computed[item].products[0].personas">
                            <img v-if="offerings.personas.bases[persona]" :key="`offerings-${item}-${persona}`" :src="`/statics/_demo/persona.${offerings.personas.bases[persona].group}.${persona}.svg`" class="brighten-50 dark-img-invert-100 on-left-sm" style="height: 30px"/>
                          </template>
                        </div>
                        <div v-else class="q-card-detail-chips hidden">
                          <q-chip dense text-color="white" color="purple-l2" class="inline-block lowercase" style="margin-top:-30px">
                            multiple <span class="text-weight-regular">options</span>
                          </q-chip>
                        </div>
                        <!-- name, margin-bottom: -10px; -->
                        <div style="margin-top: 4px;">{{ offerings.items[offerings_computed[item].offer].name }}</div>
                        <!-- size -->
                        <div v-if="offerings_computed[item].products[0].size">
                          <q-chip class="q-chip-info" dense>{{ offerings_computed[item].products[0].size.value }} {{ offerings_computed[item].products[0].size.unit }}.</q-chip>
                        </div>
                        <!-- computes -->
                        <div v-if="offerings_computed[item].products[0].computes" class="q-card-detail-chips">
                          <q-chip dense text-color="white" color="value" class="inline-block lowercase" style="margin-bottom:0px" v-if="offerings_computed[item].products[0].computes.lowcarb">
                            lowcarb
                          </q-chip>
                        </div>
                      </div>
                      <!-- options/highlights -->
                      <div class="text-weight-semibold on-right-xs font-size-60p" style="margin-bottom: -4px; transform: scale(0.85); margin-left: -10px">
                        <!-- multiple-options -->
                        <template v-if="offerings_computed[item].products.length > 1">
                          <div class="q-card-detail-chips">
                            <q-chip dense text-color="white" color="purple-l2" class="inline-block lowercase" style="margin-left:-20px;margin-top:-30px;border-top-left-radius:0;border-bottom-left-radius:0">
                            multiple <span class="text-weight-regular">options</span>
                            </q-chip>
                          </div>
                          <q-list dense no-border class="denser" style="width:99%">
                            <template v-for="(product, ip) in offerings_computed[item].products">
                              <template v-for="(contains, ic) in product.contains">
                                <q-item v-if="contains.mutator" :key="`offerings-${item}-options-${contains.base}-${ip}${ic}`" class="font-size-80p">
                                  <q-item-main>
                                    <span class="text-purple-l2 text-weight-bolder inline-block" style="width:16px">{{ ip + 1 }}.</span>
                                    {{ contains.modifiers.join() }} {{ contains.base.replace(/\_/g, ' ') }}
                                  </q-item-main>
                                </q-item>
                              </template>
                            </template>
                          </q-list>
                        </template>
                        <!-- highlights -->
                        <template v-if="offerings_computed[item].products.length === 1">
                          <p class="no-margin text-subinfo-l lowercase font-size-80p">highlights</p>
                          <q-list dense no-border class="denser" style="width:99%;margin-top:-4px">
                            <template v-for="(contains) in offerings_computed[item].products[0].contains">
                              <q-item v-if="contains.highlight" :key="`offerings-${item}-highlight-${contains.base}-${contains.modifiers && contains.modifiers.join('_') || ''}`" class="font-size-100p">
                                <q-item-main>
                                  <img :src="`/statics/_demo/persona.${offerings.personas.bases[contains.base].group}.${offerings.personas.bases[contains.base].family || contains.base}.svg`" class="brighten-50 dark-img-invert-100 vertical-middle on-left-sm float-left" style="width: 30px"/>
                                  <span class="inline-block" style="padding: 4px 4px 0px 0px">{{ contains.base.replace(/\_/g, ' ') }}</span>
                                  <div v-if="settings_display_cards_mode" style="clear: both; padding-left: 34px;">
                                    <q-chip v-if="contains.qty > 1" class="dark-img-invert-100 q-chip-subinfo on-left-xs" dense>&times;{{ contains.qty }}</q-chip>
                                    <q-chip v-for="modifier in contains.modifiers" :key="`offerings-${item}-highlight-${contains.base}-${modifier}`" class="q-chip-subinfo on-bottom-xs on-left-xs dark-img-invert-100" dense>{{ modifier }}</q-chip>
                                  </div>
                                </q-item-main>
                              </q-item>
                            </template>
                          </q-list>
                        </template>
                      </div>
                    </div>
                  </div>
                </q-card-title>
                <q-card-separator v-show="settings_display_cards_mode"/>
                <q-card-main v-if="settings_display_cards_mode" class="q-card-title-columns-wrapper text-family-brand text-subinfo" style="background-color:#f8f8f8;min-height:96px;height:96px;padding-top:0">
                  <template v-if="offerings_computed[item].products.length === 1">
                    <div class="q-card-title-columns-hero" style="min-height: auto; top: 0; height: 120px; padding-left: 20px;">
                      <span class="text-weight-black inline-block" style="font-size:400%;margin-bottom:-20px">{{ offerings_computed[item].products[0].nutrition.calories }}&nbsp;</span>
                      <span class="text-weight-semibold text-subinfo-l block">calories</span>
                    </div>
                    <div class="q-card-title-columns-content" style="min-height:auto">
                      <div class="columns flex justify-between" style="padding-top: 20px; width: 100%">
                        <!-- 177px -->
                        <span :style="`width:calc(${(offerings_computed[item].products[0].nutrition.p_portion * 100)}% - 4px); margin-left: 0px`" class="pill inline-block bg-gold"></span>
                        <span :style="`width:calc(${(offerings_computed[item].products[0].nutrition.c_portion * 100)}% - 4px)`" class="pill inline-block bg-green"></span>
                        <span :style="`width:calc(${(offerings_computed[item].products[0].nutrition.f_portion * 100)}% - 4px); margin-right: 0px`" class="pill inline-block bg-red"></span>
                      </div>
                      <div class="text-weight-black font-size-120p" style="
                        margin-top: -20px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-start;
                      ">
                        <div>
                          <span style="font-size:140%">{{ offerings_computed[item].products[0].nutrition.macronutrients.p }}g</span>
                          <span style="font-size:14px;margin-top:-6px" class="block text-weight-semibold text-subinfo-l">protein</span>
                        </div>
                        <div>
                          <span style="font-size:140%">{{ offerings_computed[item].products[0].nutrition.macronutrients.c }}g</span>
                          <span style="font-size:14px;margin-top:-6px" class="block text-weight-semibold text-subinfo-l">carb.</span>
                        </div>
                        <div>
                          <span style="font-size:140%">{{ offerings_computed[item].products[0].nutrition.macronutrients.f }}g</span>
                          <span style="font-size:14px;margin-top:-6px" class="block text-weight-semibold text-subinfo-l">fat</span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      font-size: 14px;
                      margin-bottom: -16px;
                      width: 100%"
                      class="text-weight-semibold text-disabled"
                    >
                      <hr v-for="i in 3" :key="`k-hr-dor-${i}`" class="dot" style="margin: 4px"/>
                    </div>
                  </template>
                </q-card-main>
                <q-card-separator v-show="!$q.platform.has.touch"/>
                <q-card-actions v-show="!$q.platform.has.touch">
                  <q-btn class="full-width" color="primary" flat rounded :label="$t('VIEW')" @click="processCardSelection(`item-${index}-${item}`)">
                    <q-tooltip :delay="2000" inverted>
                      {{ $t('VIEW') }}
                    </q-tooltip>
                  </q-btn>
                </q-card-actions>
              </q-card>
            </transition>
          </template>
          <!-- add width helper (hidden) -->
          <q-card inline class="overflow-hidden q-card-grouped q-card-widget invisible" style="min-height:0"></q-card>
        </div>
      </template>

      <!-- datagroups / datafields -->
      <template v-for="(datagroup, index) of datagroups">
        <div v-if="!datagroup.types || (datagroup.types && (datagroup.types.filter(el => product.data.business.types.includes(el))).length)" :key="`product-datagroup-${datagroup.id}-${index}`" class="row justify-between items-center margin-auto-lr datagroup-container on-top-xl hidden">
          <transition appear enter-active-class="animated fadeInUp animated-d800">
            <div v-if="datagroup.renderInList !== false" :class="{ 'on-top-xx': index > 0 }" class="full-width title-group text-family-brand text-weight-bolder text-center uppercase">{{ $t((datagroup.id).toUpperCase() + '.LABEL_TT') }}</div>
          </transition>
          <template v-for="(datafield, ix) of datagroup.datafields">
            <transition v-if="datafield.signal.bCheck() !== false && datafield.archive !== true && datafield.renderInList !== false && datagroup.renderInList !== false" :key="`product-datagroup-${datagroup.id}-${index}-${datafield.id}`" appear enter-active-class="animated fadeInUp animated-d800" leave-active-class="animated fadeOutDown animated-d800">
              <q-card
                v-if="(!datafield.types || datafield.types.filter(el => product.data.business.types.includes(el)).length) && (!datafield.notTypes || !datafield.notTypes.filter(el => product.data.business.types.includes(el)).length)"
                inline class="overflow-hidden q-card-grouped q-card-widget"
                :data-index="[datagroup.id, index, datafield.id, ix].join('-')"
                :ref="`product-card-` + [datagroup.id, index, datafield.id, ix].join('-')"
                :disabled="datafield.indicates && datafield.indicates.requiresValue && !datafield.indicates.aux().value"
                v-touch-pan.noMouse="(obj) => { setCardIntent(obj, [datagroup.id, index, datafield.id, ix].join('-'), processCardSelection) }">
                <q-card-title style="min-height: 150px">
                  <img :src="`/statics/_demo/${datafield.icon}.svg`" class="card-title-bgicon absolute" :style="datafield.brand === true ? 'opacity:0.02' : ''" :class="{ 'filter-invert-100': anyDarkmode }">
                  <span class="title-label block text-primary q-caption text-family-brand text-weight-semibold uppercase font-size-100p">
                    {{ $t([datafield.id, 'LABEL'].join('.').toUpperCase()) }}
                  </span>
                  <span v-if="datafield.indicates && datafield.indicates.aux" :class="datafield.indicates.aux().state === 1 ? 'text-educate' : 'text-attention'" class="text-family-brand text-weight-semibold lowercase block font-size-140p ellipsis" style="margin-top: 10px">
                    {{ datafield.indicates.aux().text }}
                  </span>
                  <span class="card-title-note text-family-brand text-weight-semibold lowercase block font-size-140p">
                    <template v-if="datafield.signal.bCheck() === true">
                      <q-icon name="ion-checkmark-circle" class="text-educate"/>
                      {{ $t('AVAILABLE') }}
                    </template>
                    <template v-else>
                      {{ datafield.signal.descriptor() }}
                    </template>
                  </span>
                </q-card-title>
                <q-card-separator v-show="!$q.platform.has.touch"/>
                <q-card-actions v-show="!$q.platform.has.touch">
                  <q-btn v-if="datafield.type === 'phone'" class="full-width" color="primary" flat rounded :label="$t('CALL')" @click="call(datafield.indicates.aux().text)">
                    <q-tooltip :delay="2000" inverted>
                      {{ $t('CALL') }}
                    </q-tooltip>
                  </q-btn>
                  <q-btn v-else-if="datafield.type === 'link'" class="full-width" color="primary" flat rounded :label="$t('OPEN')" @click="openURL(datafield.indicates.aux().value)">
                    <q-tooltip :delay="2000" inverted>
                      {{ $t('OPEN') }}
                    </q-tooltip>
                  </q-btn>
                  <q-btn v-else class="full-width" color="primary" flat rounded :label="$t('VIEW')" @click="processCardSelection([datagroup.id, index, datafield.id, ix].join('-'))">
                    <q-tooltip :delay="2000" inverted>
                      {{ $t('VIEW') }}
                    </q-tooltip>
                  </q-btn>
                </q-card-actions>
              </q-card>
            </transition>
          </template>
        </div>
      </template>
    </template>

    <!--
      MODAL: Queue
    -->
    <q-modal id="dialogQueue" v-model="dialogQueueShow" position="bottom" class="appLayer dialog-item" no-refocus>
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>Queue</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscoll-shadow">
          <q-card class="full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click="dialogQueueClose">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="col justify-between items-center margin-auto-lr">
          <h3 class="text-center text-family-brand"><span class="text-shallow">{{ requests_user_count }}</span><em class="text-shallow font-size-100p on-left-xs">of</em>{{ (channel && channel.requests && channel.requests.length) || 0 }}
          </h3>
        <div class="text-family-brand layout-padding no-padding-tb row justify-center items-center">
          <template v-if="channel && channel.requests" v-for="(request, ix) of channel.requests">
            <transition v-if="request.status && request.user.publicAddress === account.metadata.publicAddress" :key="`product-requests-${ix}-${request.status}-${request.user.publicAddress}`" appear enter-active-class="animated fadeInUp animated-d800">
              <q-card inline class="overflow-hidden q-card-grouped q-card-widget" style="max-width:100%">
                <q-card-title>
                  <img :src="request.item.photo" class="avatar float-left" style="width: 80px; height: 80px; border-radius: 17px; border-bottom-right-radius: 0;">
                  <div class="float-left">
                    <span class="block on-right-sm capitalize text-weight-bold font-size-120p">
                      {{ request.info.name.replace(/\_/g, ' ') }}
                    </span>
                    <span class="block on-right-sm">
                      <div class="columns flex justify-between" style="height: 48px">
                      <template v-if="request.status === 'completed'">
                        <div class="col-1">
                          <img src="/statics/_demo/checkmark.circle.fill.svg" height="30" class="brighten-50 dark-img-invert-50 vertical-middle on-left-sm"/>
                        </div>
                        <div class="col">
                          <em style="padding: 0; top: -7px; position: relative;">with</em><br><b class="uppercase font-size-80p" style="color: #004BA3; position: relative; top: -23px">CUSTOMER</b>
                        </div>
                      </template>
                      <template v-else-if="request.status === 'dropping-off'">
                        <div class="col-1">
                          <img src="/statics/_demo/viewfinder.qrcode.svg" height="30" class="brighten-50 dark-img-invert-50 vertical-middle on-left-sm"/>
                        </div>
                        <div class="col">
                          <em style="padding: 0; top: -7px; position: relative;">to</em><br><b class="uppercase font-size-80p" style="color: #004BA3; position: relative; top: -23px">PATIO #1</b>
                        </div>
                      </template>
                      <template v-else-if="request.status === 'in-progress'">
                        <div class="col-1">
                          <img src="/statics/_demo/station.globe.svg" height="30" class="brighten-50 dark-img-invert-50 vertical-middle on-left-sm animate-spin"/>
                        </div>
                        <div class="col">
                          <em style="padding: 0; top: -7px; position: relative;">at</em><br><b class="uppercase font-size-80p" style="color: #004BA3; position: relative; top: -23px">{{ request.info.station.replace(/\_/g, ' ') }}</b>
                        </div>
                      </template>
                      <template v-else>
                        <div class="col-1">
                          <img src="/statics/_demo/station.person.svg" height="30" class="brighten-50 dark-img-invert-50 vertical-middle on-left-sm"/>
                        </div>
                        <div class="col">
                          <em style="padding: 0; top: -7px; position: relative;">with</em><br><b class="uppercase font-size-80p" style="color: #004BA3; position: relative; top: -23px">{{ request.location }}</b>
                        </div>
                      </template>
                      </div>
                    </span>
                  </div>
                  <!--
                  <span class="on-right-sm capitalize text-weight-bold font-size-120p">
                    {{ request.info.name.replace(/\_/g, ' ') }}
                    <span class="block">
                      <q-chip dense class="q-chip-info" style="margin-left: 4px; margin-top: -22px; border: 0; padding: 0">
                        <b style="color: #004BA3">{{ request.info.station.replace(/\_/g, ' ') }}</b>
                      </q-chip>
                    </span>
                    <span class="font-size-40p caption">{{ request.user.publicAddress }}</span>
                  </span>
                  -->
                  <div slot="right">
                    <div style="position: relative; top: -22px">
                      <span class="block font-size-120p text-family-brand text-primary text-right text-weight-bold">
                        <span class="text-subinfo-l">#</span>{{ request.id }}
                      </span>
                      <q-chip dense class="on-right-xs" :class="{
                        'q-chip-info': request.status === 'in-review',
                        'q-chip-hint': request.status === 'in-progress',
                        'q-chip-pro-fill': request.status === 'dropping-off',
                        'q-chip-educate': request.status === 'completed'
                      }">
                        {{ request.status.replace(/\_/g, ' ') }}
                      </q-chip>
                    </div>
                  </div>
                </q-card-title>
              </q-card>
            </transition>
          </template>
        </div>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Product Item: Send
    -->
    <q-modal id="dialogProductSend" minimized v-model="dialogProductSendShow" class="appLayer- dialog-item" no-refocus>
      <q-modal-layout v-if="dialogProductItem && offerings_computed[dialogProductItem]">
        <q-toolbar slot="header" inverted>
          <q-toolbar-title style="text-transform: capitalize">{{ $t(offerings.items[offerings_computed[dialogProductItem].offer].name) }}</q-toolbar-title>
        </q-toolbar>
        <div class="text-family-brand layout-padding no-padding-tb text-center row justify-center items-center" style="max-width: 500px">
          <div>
            <img class="avatar" :src="offerings_computed[dialogProductItem].products[0].photo" style="width: 120px; height: 120px; border-radius: 0.3rem">
            <p class="text-weight-semibold no-margin-bottom on-top-default">
              Send now and it will be ready in
            </p>
            <p class="full-width no-margin text-empower text-family-brand text-weight-bold" style="font-size: 44px">
              {{ (guardian.business.stations[offerings.items[offerings_computed[dialogProductItem].offer].station].times.wait + offerings_computed[dialogProductItem].products[0].time) }}:00
              <!-- {{ guardian.business.stations[offerings.items[offerings_computed[dialogProductItem].offer].station].times.wait + offerings_computed[dialogProductItem].products[0].time }} -->
            </p>
            <span class="text-empower font-size-80p text-weight-bold block" style="margin-top:-10px">minutes</span>
          </div>
          <q-card class="column margin-auto-lr q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top" style="min-height:80px !important">
            <q-card-main class="block column justify-center full-height">
              <q-btn @click="sendRequest" :loading="dialogProductSendSending" class="limit-width-1024 full-width full-height text-family-brand text-weight-bold uppercase font-size-140p" color="primary" text-color="white" rounded
                :disabled="!this.account.isLoggedIn || (0 >= (wallet_amount - price_total(offerings_computed[dialogProductItem].products[0].price)))">
                <span>Send
                  <em>for</em>
                  {{ price_total(offerings_computed[dialogProductItem].products[0].price) | nformat('$0,0.00') }}
                  <!-- <span class="opacity-7">+tax</span> -->
                </span>
                <q-spinner-puff slot="loading"/>
              </q-btn>
              <!-- <hr class="dot" v-show="(wallet_amount - price_total(offerings_computed[dialogProductItem].products[0].price)) < 0"> -->
              <!-- post-send $ -->
              <div class="full-width">
                <p v-if="!this.account.isLoggedIn" class="caption text-weight-semibold text-subinfo no-margin on-top-default bg-empower-light animate-popup-up"
                  style="padding: 20px 0px;border-radius: 1.4em;">
                  <img style="width:34px;margin:0 4px" src="/statics/_demo/exclamationmark_attention.svg" class="vertical-middle"/>
                  <q-chip class="q-chip-height-min" color="red">
                    Email Address
                  </q-chip>
                  <span class="block on-top-default font-size-160p text-weight-black">Almost there!</span>
                  <span class="block">Login with email. No password needed!</span>
                </p>
                <p v-else-if="(wallet_amount - price_total(offerings_computed[dialogProductItem].products[0].price)) >= 0" class="caption text-weight-semibold text-subinfo no-margin on-top-default">
                  {{ wallet_amount | nformat('$0,0.00') }}
                  <img style="width:10px;margin:0 4px" src="/statics/_demo/arrow.right.svg" class="brighten-50 dark-img-invert-100 vertical-middle"/>
                  {{ wallet_amount - price_total(offerings_computed[dialogProductItem].products[0].price) | nformat('$0,0.00') }}
                </p>
                <p v-else class="caption text-weight-semibold text-subinfo no-margin on-top-default bg-empower-light animate-popup-up"
                  style="padding: 20px 0px;border-radius: 1.4em;">
                  <img style="width:34px;margin:0 4px" src="/statics/_demo/exclamationmark_attention.svg" class="vertical-middle"/>
                  <q-chip class="q-chip-height-min" color="red">
                    {{ wallet_amount | nformat('$0,0.00') }}
                  </q-chip>
                  <span class="block on-top-default font-size-160p text-weight-black">Almost there!</span>
                  <span class="block">Need additional funds to send.</span>
                </p>
              </div>
              <q-btn @click="accountLogin" class="on-top-sm limit-width-1024 full-width full-height text-family-brand text-weight-bold uppercase font-size-140p" color="empower-light" text-color="tertiary" rounded
                v-if="!this.account.isLoggedIn">
                Add Email
              </q-btn>
              <q-btn @click="walletAdd" class="on-top-sm limit-width-1024 full-width full-height text-family-brand text-weight-bold uppercase font-size-140p" color="empower-light" text-color="tertiary" rounded
                v-if="(wallet_amount - price_total(offerings_computed[dialogProductItem].products[0].price)) < 0">
                Add funds
              </q-btn>
            </q-card-main>
          </q-card>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Product Item
    -->
    <q-modal id="dialogProduct" v-model="dialogProductShow" position="bottom" class="appLayer dialog-item dialog-grow dialog-grow-full no-title" no-refocus>
      <q-modal-layout v-if="dialogProductItem && offerings_computed[dialogProductItem]">
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t(offerings.items[offerings_computed[dialogProductItem].offer].name) }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="toolbar-overscroll-visibility full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-bolder uppercase font-size-120p" color="tertiary-light" text-color="tertiary" rounded @click.native="send()">
                <img :src="'/statics/_demo/stack.send_' + (anyDarkmode ? 'white' : 'tertiary') + '.svg'" height="24" class="on-left-sm">...
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogProductShow = false; soundPlay('tap')">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>

          <!-- photo -->
          <img class="avatar" :src="offerings_computed[dialogProductItem].products[0].photo" style="width: 100px; height: 100px; border-radius: 0.3rem">

          <!-- name -->
          <h1 class="full-width no-margin text-weight-semibold">
            {{ offerings.items[offerings_computed[dialogProductItem].offer].name }}
          </h1>

          <!-- features -->
          <q-list class="card text-family-brand full-width on-top-lg" :dark="anyDarkmode" inset-separator>
            <q-item class="no-padding">
              <q-item-main class="text-center">
                <div class="columns items-center justify-between row full-width">
                  <!-- price -->
                  <div class="col-3">
                    <p class="on-bottom-xs caption text-shallow uppercase text-weight-black">price</p>
                    <div class="row justify-center" style="max-height: 40px; height: 40px;">
                      <q-chip class="q-chip-empower" square dense>
                        {{ price_total(offerings_computed[dialogProductItem].products[0].price) | nformat('$0,0.00') }}
                      </q-chip>
                    </div>
                  </div>
                  <!-- calories -->
                  <!-- [sep] --> <div class="col-1 bg-shallower" style="height:40px;width:4px;border-radius:1rem">&nbsp;</div>
                  <div class="col-3" @click="scrollToRef('product-item-features-nutrition')">
                    <p class="on-bottom-xs caption text-shallow uppercase text-weight-black">calories</p>
                    <div class="row justify-center" style="max-height: 40px; height: 40px;">
                      <q-chip class="q-chip-secondary" square dense>
                        {{ offerings_computed[dialogProductItem].products[0].nutrition.calories }}
                      </q-chip>
                    </div>
                  </div>
                  <!-- size -->
                  <!-- [sep] --> <div v-if="offerings_computed[dialogProductItem].products[0].size" class="col-1 bg-shallower" style="height:40px;width:4px;border-radius:1rem">&nbsp;</div>
                  <div class="col-3" v-if="offerings_computed[dialogProductItem].products[0].size">
                    <p class="on-bottom-xs caption text-shallow uppercase text-weight-black">size</p>
                    <div class="row justify-center" style="max-height: 40px; height: 40px;">
                      <q-chip class="q-chip-info" square dense>
                        {{ offerings_computed[dialogProductItem].products[0].size.value }} {{ offerings_computed[dialogProductItem].products[0].size.unit }}.
                      </q-chip>
                    </div>
                  </div>
                  <!-- qty -->
                  <!-- [sep] --> <div v-if="!offerings_computed[dialogProductItem].products[0].size" class="col-1 bg-shallower" style="height:40px;width:4px;border-radius:1rem">&nbsp;</div>
                  <div class="col-3" v-if="!offerings_computed[dialogProductItem].products[0].size">
                    <p class="on-bottom-xs caption text-shallow uppercase text-weight-black">qty</p>
                    <div class="row justify-center" style="max-height: 40px; height: 40px;">
                      <q-chip class="q-chip-info" square dense>
                        &nbsp; &times;{{ offerings_computed[dialogProductItem].products[0].qty }} &nbsp;
                      </q-chip>
                    </div>
                  </div>
                </div>
              </q-item-main>
            </q-item>
          </q-list>

          <!-- options -->
          <q-list v-if="offerings_computed[dialogProductItem].products[0].personas.indexOf('bison') >= 0" class="card text-family-brand full-width on-top-lg" :dark="anyDarkmode" inset-separator>
            <q-list-header compact-left class="font-size-120p text-secondary text-center">
              options
            </q-list-header>
            <!-- meat temperature -->
            <q-item>
              <q-item-side>
                <img style="width:40px" :src="`/statics/_demo/options.temperature.svg`" class="brighten-50 dark-img-invert-100 on-left-sm"/>
              </q-item-side>
              <q-item-main class="font-size-160p text-weight-semibold">
                <q-item-tile sublabel class="font-size-100p text-educate">
                  Meat Temperature
                </q-item-tile>
                <q-item-tile label class="font-size-100p capitalize">
                  Medium Rare
                </q-item-tile>
              </q-item-main>
              <q-item-side right class="text-center" style="width:80px;height:60px">
                <img src="/statics/_demo/optons.temperature.medium-rare.svg" style="margin-top: -15px; width: auto; height: auto; max-width: 90px; max-height: 90px;"/>
              </q-item-side>
            </q-item>
            <!-- side -->
            <q-item>
              <q-item-side>
                <img style="width:40px" :src="`/statics/_demo/options.side.svg`" class="brighten-50 dark-img-invert-100 on-left-sm"/>
              </q-item-side>
              <q-item-main class="font-size-160p text-weight-semibold">
                <q-item-tile sublabel class="font-size-100p text-educate">
                  Side item
                </q-item-tile>
                <q-item-tile label class="font-size-100p capitalize text-subinfo-l">
                  None
                </q-item-tile>
              </q-item-main>
            </q-item>
            <!-- send -->
            <q-item>
              <q-item-main>
                <q-card class="q-card-grouped text-center no-shadow no-border no-background flex-auto relative-position z-top margin-auto-lr">
                  <q-card-main class="column justify-center full-height" style="padding-left:0;padding-right:0;padding-bottom:8px">
                    <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary" text-color="white" rounded @click.native="send()">
                      <img  src="/statics/_demo/stack.send_white.svg" height="30" class="absolute" style="left:20px">
                      <span class="font-size-140p text-weight-bolder">SEND ...</span>
                    </q-btn>
                  </q-card-main>
                </q-card>
              </q-item-main>
            </q-item>
          </q-list>

          <!-- send -->
          <q-card v-else class="q-card-grouped text-center no-shadow no-border no-background flex-auto relative-position z-top margin-auto-lr">
            <q-card-main class="column justify-center full-height" style="padding-left:0;padding-right:0;padding-bottom:8px">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary" text-color="white" rounded @click.native="send()">
                <img  src="/statics/_demo/stack.send_white.svg" height="30" class="absolute" style="left:20px">
                <span class="font-size-140p text-weight-bolder">SEND ...</span>
              </q-btn>
            </q-card-main>
          </q-card>

          <!-- concierge -->
          <q-card class="hidden no-padding-top q-card-grouped text-center no-shadow no-border no-background flex-auto relative-position z-top margin-auto-lr">
            <q-card-main class="no-padding column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded>
                <span class="font-size-140p text-weight-bolder">Concierge</span>
              </q-btn>
            </q-card-main>
          </q-card>

          <!-- station -->
          <q-list class="card text-family-brand full-width on-top-lg" :dark="anyDarkmode" inset-separator>
            <q-list-header compact-left class="font-size-120p text-secondary text-center">
            station
            </q-list-header>
            <q-item>
              <q-item-side>
                <img style="width:36px" :src="`/statics/_demo/station.globe.svg`" class="brighten-50 dark-img-invert-50 on-left-sm animate-spin"/>
              </q-item-side>
              <q-item-main class="font-size-160p text-weight-semibold">
                <q-item-tile label class="font-size-100p capitalize">
                  {{ offerings.items[offerings_computed[dialogProductItem].offer].station }}
                </q-item-tile>
                <q-item-tile sublabel class="font-size-100p">
                  <template>
                    <img v-for="staff in channel[`${offerings.items[offerings_computed[dialogProductItem].offer].station}_staff`]" :key="`staff-${staff}`" style="width:20px" :src="`/statics/_demo/station.person.svg`" class="brighten-50 dark-img-invert-100 on-left-sm"/>
                  </template>
                  <!-- <img style="width:20px" :src="`/statics/_demo/station.person.svg`" class="brighten-50 dark-img-invert-100 on-left-sm"/> -->
                  <q-chip v-show="0 === channel[`${offerings.items[offerings_computed[dialogProductItem].offer].station}_training`]" class="q-chip-subinfo on-bottom-xs on-left-xs dark-img-invert-100" dense>Training</q-chip>
                </q-item-tile>
              </q-item-main>
            </q-item>
            <q-item>
              <q-item-side>
                <img style="width:36px" :src="`/statics/_demo/station.wait.svg`" class="brighten-50 dark-img-invert-50 on-left-sm"/>
              </q-item-side>
              <q-item-main class="font-size-160p text-weight-semibold">
                <q-item-tile label class="font-size-100p">
                  <span v-show="(guardian.business.stations[offerings.items[offerings_computed[dialogProductItem].offer].station].times.wait + offerings_computed[dialogProductItem].products[0].time) >= 35">&gt;</span>
                  {{ guardian.business.stations[offerings.items[offerings_computed[dialogProductItem].offer].station].times.wait + offerings_computed[dialogProductItem].products[0].time }} minutes
                </q-item-tile>
                <q-item-tile sublabel class="font-size-100p">
                   Estimated service time
                </q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>

          <!-- nutrition -->
          <q-list ref="product-item-features-nutrition" class="card text-family-brand full-width on-top-lg" :dark="anyDarkmode" inset-separator>
            <q-list-header compact-left class="font-size-120p text-secondary text-center">
              nutrition
            </q-list-header>
            <q-item class="no-padding">
              <q-item-main class="font-size-160p text-weight-semibold">
                <!-- macro-nutrition -->
                <q-card class="no-background no-border no-shadow overflow-hidden q-card-grouped q-card-widget2 q-card-title-columns" style="width:100%">
                  <q-card-main class="q-card-title-columns-wrapper text-family-brand text-subinfo" style="padding-top:0;padding-bottom:0text-align:left">
                    <div class="q-card-title-columns-hero" style="min-height: auto; top: 0; height: 120px; padding-left: 10px">
                      <span class="text-weight-black inline-block" style="font-size:400%;margin-bottom:-20px">{{ offerings_computed[dialogProductItem].products[0].nutrition.calories }}</span>
                      <span class="text-weight-semibold text-subinfo-l block">calories</span>
                    </div>
                    <div class="q-card-title-columns-content" style="min-height:auto;">
                      <div class="columns flex justify-between" style="padding-top: 20px; width: 100%">
                        <!-- 177px -->
                        <span :style="`width:calc(${(offerings_computed[dialogProductItem].products[0].nutrition.p_portion * 100)}% - 4px); margin-left: 0px`" class="pill inline-block bg-gold"></span>
                        <span :style="`width:calc(${(offerings_computed[dialogProductItem].products[0].nutrition.c_portion * 100)}% - 4px)`" class="pill inline-block bg-green"></span>
                        <span :style="`width:calc(${(offerings_computed[dialogProductItem].products[0].nutrition.f_portion * 100)}% - 4px); margin-right: 0px`" class="pill inline-block bg-red"></span>
                      </div>
                      <div class="text-weight-black font-size-120p" style="
                        margin-top: -20px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-start;
                      ">
                        <div>
                          <span style="font-size:140%">{{ offerings_computed[dialogProductItem].products[0].nutrition.macronutrients.p }}g</span>
                          <span style="font-size:14px;margin-top:-6px" class="block text-weight-semibold text-subinfo-l">protein</span>
                        </div>
                        <div>
                          <span style="font-size:140%">{{ offerings_computed[dialogProductItem].products[0].nutrition.macronutrients.c }}g</span>
                          <span style="font-size:14px;margin-top:-6px" class="block text-weight-semibold text-subinfo-l">carb.</span>
                        </div>
                        <div>
                          <span style="font-size:140%">{{ offerings_computed[dialogProductItem].products[0].nutrition.macronutrients.f }}g</span>
                          <span style="font-size:14px;margin-top:-6px" class="block text-weight-semibold text-subinfo-l">fat</span>
                        </div>
                      </div>
                    </div>
                  </q-card-main>
                </q-card>
              </q-item-main>
            </q-item>
          </q-list>

          <!-- [ groups, brands, labels, ingredients ] -->
          <template v-for="type in ['styles', 'groups', 'brands', 'labels', 'practices', 'ingredients']">
            <q-list v-if="itemContainsGroup(dialogProductItem, type)" link-- :key="`product-item-section-${type}`" class="card text-family-brand full-width on-top-lg" :dark="anyDarkmode" inset-separator>
              <q-list-header compact-left class="font-size-120p text-secondary text-center">
                {{ type }}
              </q-list-header>
              <template v-for="(contains, ix) in offerings_computed[dialogProductItem].products[0].contains">
                <template v-if="offerings.personas.bases[contains.base] && offerings.personas.bases[contains.base].group === type">
                  <q-item :key="[contains, ix].join()" link-- @click--.native="personaEdit(contains.base, { status: null })">
                    <q-item-side>
                      <img style="width:40px" :src="`/statics/_demo/persona.${offerings.personas.bases[contains.base].group}.${contains.base}.svg`" class="brighten-50 dark-img-invert-100 on-left-sm"/>
                    </q-item-side>
                    <q-item-main class="font-size-160p text-weight-semibold">
                      <q-item-tile label class="font-size-100p capitalize">
                        {{ contains.base.replace(/\_/g, ' ') }}
                      </q-item-tile>
                      <q-item-tile>
                        <q-chip v-if="contains.qty > 1" class="dark-img-invert-100 q-chip-info on-left-xs" dense>&times;{{ contains.qty }}</q-chip>
                        <q-chip v-for="modifier in contains.modifiers" :key="`offerings-${dialogProductItem}-highlight-${contains.base}-${modifier}`" class="dark-img-invert-100 on-left-xs q-chip-info" dense>{{ modifier.replace(/\_/g, ' ') }}</q-chip>
                      </q-item-tile>
                    </q-item-main>
                    <q-item-side v-if="guardian_personas.data[type] && guardian_personas.data[type].data[contains.base] && guardian_personas.data[type].data[contains.base].status" right class="text-center" style="width:80px;height:60px">
                      <div :key="`dialogProductItemPersona${type}Status_health`" v-if="guardian_personas.data[type].data[contains.base].status === 'health'">
                        <img src="/statics/_demo/avoid_protect.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                        <p class="no-margin text-weight-bold text-protect font-size-80p">AVOID</p>
                      </div>
                      <div :key="`dialogProductItemPersona${type}Status_avoid`" v-else-if="guardian_personas.data[type].data[contains.base].status === 'avoid'">
                        <img src="/statics/_demo/nosign_attention.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                        <p class="no-margin text-weight-bold text-attention font-size-80p">AVOID</p>
                      </div>
                      <div :key="`dialogProductItemPersona${type}Status_good`" v-else-if="guardian_personas.data[type].data[contains.base].status === 'good'">
                        <img src="/statics/_demo/heart_educate.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                        <p class="no-margin text-weight-bold text-educate font-size-80p">WANT</p>
                      </div>
                      <div v-else>
                        <p class="no-margin text-weight-bold text-educate font-size-80p">&nbsp;</p>
                      </div>
                    </q-item-side>
                  </q-item>
                </template>
              </template>
            </q-list>
          </template>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: (Any)
    -->
    <q-modal id="dialogItem" v-model="dialogPerspectiveShow" position="bottom" class="appLayer">
      <q-modal-layout v-if="this.dialogPerspectiveItem" :style="`background-image: url(/statics/_demo/${this.dialogPerspectiveItem.icon}.svg);`">
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t((this.dialogPerspectiveItem.id + '.LABEL').toUpperCase()) }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogPerspectiveShow = false; soundPlay('tap')">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list class="text-family-brand" no-border link :dark="anyDarkmode">
            <p v-if="dialogPerspectiveItem.indicates && dialogPerspectiveItem.indicates.aux"
              :class="{ 'lowercase': !dialogPerspectiveItem.indicates.aux().unformat, 'text-educate': dialogPerspectiveItem.indicates.aux().state === 1, 'text-attention': dialogPerspectiveItem.indicates.aux().state !== 1 }"
              class="text-family-brand text-weight-semibold text-center font-size-160p"
              :style="{ 'word-break': dialogPerspectiveItem.indicates.aux().unformat ? 'normal' : 'break-all' }"
            >
              {{ dialogPerspectiveItem.indicates.aux().text || dialogPerspectiveItem.indicates.aux().value }}
              <q-btn v-if="dialogPerspectiveItem.type === 'link'" flat round icon="ion-link" @click="openURL(dialogPerspectiveItem.indicates.aux().value || dialogPerspectiveItem.indicates.aux().text)"/>
            </p>
            <p class="q-title layout-padding no-padding-top text-subinfo slideInDown" style="padding-bottom:20px">{{ this.dialogPerspectiveItem.label }}</p>

            <template v-for="(option, ix) in this.dialogPerspectiveItem.value.options">
              <q-item :key="[option, ix].join('-')" tag="span" readonly disabeld :class="{ 'opacity-4': dialogPerspectiveItem.value.option !== ix }" class="no-pointer-events">
                <q-item-side>
                  <img v-if="dialogPerspectiveItem.value.option === ix" src="/statics/_demo/checkmark_primary.svg">
                </q-item-side>
                <q-item-main class="text-family-brand">
                  <q-item-tile class="font-size-160p text-weight-semibold" label>{{ $t(option.label) }}</q-item-tile>
                  <q-item-tile sublabel lines="3">
                    {{ $t(option.sublabel) }}
                  </q-item-tile>
                </q-item-main>
              </q-item>
            </template>

          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Personalize
    -->
    <q-modal id="dialogPersonalize" v-model="dialogPersonalizeShow" position="bottom" class="appLayer dialog-item" no-refocus>
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('PERSONALIZE.LABEL_TT') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase font-size-120p" color="tertiary-light" text-color="tertiary" rounded @click.native="personalizeList(); soundPlay('sheet_up')">
                <img :src="'/statics/_demo/add.fill_' + (anyDarkmode ? 'white' : 'tertiary') + '.svg'" height="24" class="on-left-sm">
                Add
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogPersonalizeShow = false; soundPlay('tap')">
                <img :src="'/statics/_demo/chevron.compact.down_' + (anyDarkmode ? 'white' : 'primary') + '.svg'" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <p class="q-title text-subinfo slideInDown on-top-lg" style="padding-bottom:20px;line-height: 1.4em">
            Personalize your experience by adding your lifestyle preferences.
          </p>
          <!-- display consumer personas (if any) -->
          <template v-if="!guardian_personas.count">
            <p class="text-center text-primary text-weight-bold font-size-120p animated800 flipInY animatedtimingCubic cursor-pointer" @click="personalizeList">
              <img :src="'/statics/_demo/state.empty_heart' + (anyDarkmode ? '@dark': '') + '.svg'">
              <br>Add your first personalization
            </p>
          </template>
          <template v-for="(personas, group_id) in guardian_personas.data">
            <q-list v-if="personas.count" :key="group_id" class="card text-family-brand full-width on-top-lg" no-border link>
              <template v-if="personas.count">
                <q-list-header compact-left class="font-size-120p text-secondary text-center">
                  {{ group_id }}
                </q-list-header>
                <template v-for="(payload, persona) in personas.data">
                  <transition :key="['transition', group_id, persona, payload].join('-')" mode="out-in" appear enter-active-class="animated800 fadeIn" leave-active-class="fadeOut animated400">
                  <q-item v-if="payload.status" :key="[group_id, persona, payload].join('-')" link @click.native="personaEdit(persona, payload)">
                    <q-item-side>
                      <img style="width:40px" :src="`/statics/_demo/persona.${offerings.personas.bases[persona].group}.${persona}.svg`"
                      class="brighten-50 dark-img-invert-100 on-left-sm"/>
                    </q-item-side>
                    <q-item-main class="font-size-160p text-weight-semibold">
                      <q-item-tile label class="font-size-100p capitalize">{{ persona.replace(/\_/g, ' ') }}</q-item-tile>
                    </q-item-main>
                    <q-item-side right class="text-center" style="width:80px;height:60px">
                      <transition mode="out-in" appear :enter-active-class="`animated400 ${payload.status === null ? 'fadeIn' : 'flipInY'}`" :leave-active-class="`animated200 ${payload.status === null ? 'fadeOut' : 'flipOutY'}`">
                      <div key="health" v-if="payload.status === 'health'">
                        <img src="/statics/_demo/avoid_protect.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                        <p class="no-margin text-weight-bold text-protect font-size-80p">AVOID</p>
                      </div>
                      <div key="avoid" v-else-if="payload.status === 'avoid'">
                        <img src="/statics/_demo/nosign_attention.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                        <p class="no-margin text-weight-bold text-attention font-size-80p">AVOID</p>
                      </div>
                      <div key="good" v-else-if="payload.status === 'good'">
                        <img src="/statics/_demo/heart_educate.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                        <p class="no-margin text-weight-bold text-educate font-size-80p">WANT</p>
                      </div>
                      <div key="null" v-else>
                        <img width="40" src="/statics/_demo/ellipsis.circle.fill_tertiary.svg" style="width: auto; height: auto; max-width: 40px; max-height: 40px; opacity: 0.5"/>
                        <p class="text-faded no-margin text-weight-bold text-tertiary font-size-80p">NOT SET</p>
                      </div>
                      </transition>
                    </q-item-side>
                  </q-item>
                  </transition>
                </template>
              </template>
            </q-list>
          </template>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Personalize: List
    -->
    <q-modal id="dialogPersonalizeList" v-model="dialogPersonalizeListShow" position="bottom" class="appLayer dialog-item" no-refocus>
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('PERSONALIZE_LIST.LABEL_TT') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card v-if="settings_voice" class="full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-bolder uppercase font-size-120p" color="tertiary-light" text-color="tertiary" rounded @click.native="personalizeVoice()">
                <img :src="'/statics/_demo/mic_' + (anyDarkmode ? 'white' : 'tertiary') + '.svg'" height="24" class="on-left-sm">
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="full-width q-card-grouped text-center no-margin no-padding no-shadow no-border no-background relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="personalizeList(); soundPlay('tap')">
                <img :src="'/statics/_demo/chevron.compact.down_' + (anyDarkmode ? 'white' : 'primary') + '.svg'" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list v-for="(personas, group_id) in guardian_personas.data" :key="group_id" class="card text-family-brand full-width on-top-lg" no-border link>
            <q-list-header compact-left class="font-size-120p text-secondary text-center">
              {{ group_id }}
            </q-list-header>
            <template v-for="(payload, persona) in personas.data">
              <q-item :key="[group_id, persona, payload].join('-')" link @click.native="personaEdit(persona, payload)" :class="{ 'opacity-6 text-emphasis': payload.status === null }">
                <q-item-side>
                  <img style="width:40px" :src="`/statics/_demo/persona.${offerings.personas.bases[persona].group}.${persona}.svg`"
                  class="brighten-50 dark-img-invert-100 on-left-sm"/>
                </q-item-side>
                <q-item-main class="font-size-160p text-weight-semibold">
                  <q-item-tile label class="font-size-100p capitalize">{{ persona.replace(/\_/g, ' ') }}</q-item-tile>
                </q-item-main>
                <q-item-side right class="text-center" style="width:80px;height:60px">
                  <transition mode="out-in" appear :enter-active-class="`animated400 ${payload.status === null ? 'fadeIn' : 'flipInY'}`" :leave-active-class="`animated200 ${payload.status === null ? 'fadeOut' : 'flipOutY'}`">
                  <div key="health" v-if="payload.status === 'health'">
                    <img src="/statics/_demo/avoid_protect.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                    <p class="no-margin text-weight-bold text-protect font-size-80p">AVOID</p>
                  </div>
                  <div key="avoid" v-else-if="payload.status === 'avoid'">
                    <img src="/statics/_demo/nosign_attention.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                    <p class="no-margin text-weight-bold text-attention font-size-80p">AVOID</p>
                  </div>
                  <div key="good" v-else-if="payload.status === 'good'">
                    <img src="/statics/_demo/heart_educate.svg" style="width: auto; height: auto; max-width: 40px; max-height: 50px;"/>
                    <p class="no-margin text-weight-bold text-educate font-size-80p">WANT</p>
                  </div>
                  <div key="null" v-else>
                    <img width="40" src="/statics/_demo/ellipsis.circle.fill_tertiary.svg" style="width: auto; height: auto; max-width: 40px; max-height: 40px; opacity: 0.5"/>
                    <p class="text-faded no-margin text-weight-bold text-tertiary font-size-80p">NOT SET</p>
                  </div>
                  </transition>
                </q-item-side>
              </q-item>
            </template>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Personalize: Voice
    -->
    <q-modal id="dialogPersonalizeVoice" v-model="dialogPersonalizeVoiceShow" position="bottom" class="appLayer dialog-item dialog-mini" no-refocus>
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>
            <span v-if="dialogPersonalizeVoiceStatus <= 1">Loading...</span>
            <span v-if="dialogPersonalizeVoiceStatus === 2">Listening...</span>
            <span v-if="dialogPersonalizeVoiceStatus === 3">Processing...</span>
            <hr v-if="dialogPersonalizeVoiceStatus === 4" class="pill"/>
          </q-toolbar-title>
        </q-toolbar>
        <div class="text-family-brand layout-padding row justify-center">
          <p v-if="dialogPersonalizeVoiceStatus <= 1">Loading...</p>
          <q-spinner-bars v-if="this.dialogPersonalizeVoiceStatus === 2" color="primary" size="10vh"/>
          <q-progress v-if="this.dialogPersonalizeVoiceStatus === 3" color="tertiary" height="10vh" indeterminate animate stripe
            style="height: 4px; width: 100%; max-width: 80%; margin-top: 4vh;"
          />
          <template v-if="dialogPersonalizeVoiceStatus === 4 && dialogPersonalizeVoiceText">
            <transition v-for="(t, i) in dialogPersonalizeVoiceText.split(' ')" :key="`listen-${i}-${t}`" appear :enter-active-class="`animated fadeInUp animated${(i + 1) * 200}`">
              <p class="font-size-140p lowercase on-left-sm" :class="{ 'text-weight-semibold': i % 2 }">
                {{ t }}
              </p>
            </transition>
          </template>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Dropff Scan
    -->
    <q-modal id="dialogDropoffScan" v-if="product.data.channel.online" v-model="dialogDropoffScanShow" class="appLayer dialog-item" position="bottom">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('WINGLET.SCAN.L') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="disabled margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="tertiary-light" text-color="subinfo" rounded @click.native="scanFlashToggle(); soundPlay('tap_disabled')">
                <q-icon size="2em" name="ion-flash"/>
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="disabled margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="tertiary-light" text-color="subinfo" rounded @click.native="scanCameraToggle(); soundPlay('tap_disabled')">
                <q-icon size="2em" name="ion-reverse-camera"/>
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="scanOff(); soundPlay('tap'); soundFade('pinging', 0.2, 1);">
                <!-- {{ $t('DONE') }} -->
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-center text-family-brand">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <div id="wingletDecodeContainer" class="overflow-hidden" style="height:50vh; border: 4px solid #3d4042; border-radius: 1em">
            <qrcode-stream v-if="dialogDropoffScanShow && dialogDropoffScanStreamShow" @decode="wingletOnDecode" @init="wingletOnInit" class="scanning"/>
          </div>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Business
    -->
    <q-modal id="dialogBusiness" v-model="dialogBusinessShow" position="bottom" class="appLayer dialog-item" enter-class="slideInUp animated800" leave-class="slideOutDown animated400">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('YOU') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogAccountShow = false; soundPlay('tap')">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-center text-family-brand">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>

        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Account (You)
    -->
    <q-modal id="dialogAccount" v-model="dialogAccountShow" position="bottom" class="appLayer dialog-item"  enter-class="slideInUp animated800" leave-class="slideOutDown animated400">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('YOU') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogAccountShow = false; soundPlay('tap')">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>

        <div class="q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <!-- <p class="q-title layout-padding no-padding text-subinfo slideInDown on-top-lg" style="padding-bottom:20px;line-height: 1.4em">
            Setup your account and preferences for the best experience.
          </p> -->

          <q-list class="card text-family-brand full-width on-top-lg" no-border link>
            <q-list-header compact>
              Account
            </q-list-header>
            <q-item v-if="!account.isLoggedIn">
              <q-item-main>
                <vue-phone-number-input v-model="dialogAccountPhoneNumber" :dark="anyDarkmode" default-country-code="AE" @update="updatePhoneNumber" clearable/>
              </q-item-main>
            </q-item>
            <q-item v-if="!account.isLoggedIn" link tag="label" @click.native="accountLogin()">
              <q-item-main class="font-size-160p text-weight-semibold text-center">
                <q-item-tile label v-if="!dialogAccountPhoneNumberProcessing" :disabled="!dialogAccountPhoneNumberPayload || !dialogAccountPhoneNumberPayload.isValid">Login</q-item-tile>
                <q-item-tile label v-if="dialogAccountPhoneNumberProcessing">
                  <q-progress stripe indeterminate color="hint" height="8px" style="margin-bottom:-8px;width:300px;margin-left:auto;margin-right:auto;"/>
                </q-item-tile>
              </q-item-main>
            </q-item>
            <q-item v-else link tag="label" @click.native="accountLogout()">
              <q-item-main class="font-size-160p text-weight-semibold text-center">
                <q-item-tile label v-if="!dialogAccountPhoneNumberProcessing">Logout</q-item-tile>
                <q-item-tile sublabel v-if="!dialogAccountPhoneNumberProcessing && account.email" class="lowercase font-size-80p">{{ account.email }}</q-item-tile>
                <q-item-tile sublabel v-if="!dialogAccountPhoneNumberProcessing && account.phoneNumber" class="lowercase font-size-80p">{{ account.phoneNumber }}</q-item-tile>
                <q-item-tile label v-if="dialogAccountPhoneNumberProcessing">
                  <q-progress stripe indeterminate color="hint" height="8px" style="margin-bottom:-8px;width:300px;margin-left:auto;margin-right:auto;"/>
                </q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>

          <q-list v-if="account.isLoggedIn" class="card text-family-brand full-width on-top-lg" no-border link>
            <q-list-header compact>
              Wallet
            </q-list-header>
            <q-item link tag="label" @click.native="walletOpen()">
              <q-item-side>
                <img style="width:40px" src="/statics/_demo/lifestyle.app.wallet.dollar.svg" class="brighten-50 dark-img-invert-100 on-left-sm"/>
              </q-item-side>
              <q-item-main class="font-size-160p text-weight-semibold">
                <q-item-tile label>U.S. Dollars</q-item-tile>
              </q-item-main>
              <q-item-side right class="font-size-180p text-weight-semibold text-empower">
                {{ wallet_amount | nformat('$0,0.00') }}
              </q-item-side>
            </q-item>
            <q-item link tag="label" disabled>
              <q-item-side>
                <img style="width:40px" src="/statics/_demo/lifestyle.app.wallet.bitcoin.svg" class="brighten-50 dark-img-invert-100 on-left-sm"/>
              </q-item-side>
              <q-item-main class="font-size-160p text-weight-semibold">
                <q-item-tile label>Bitcoin</q-item-tile>
                <!-- <q-item-tile sublabel><small>Not available</small></q-item-tile> -->
              </q-item-main>
              <q-item-side right>
                <q-chip class="q-chip-na" dense>N/A</q-chip>
              </q-item-side>
            </q-item>
          </q-list>

          <q-list class="card text-family-brand full-width on-top-lg" no-border link>
            <q-list-header compact>
              Settings
            </q-list-header>
            <!-- groups -->
            <q-item link tag="label">
              <q-item-side>
                <img style="width:40px" src="/statics/_demo/lifestyle.app.description.svg" class="brighten-50 dark-img-invert-100 on-left-sm"/>
              </q-item-side>
              <q-item-main class="font-size-160p text-weight-semibold">
                <q-item-tile label class="font-size-100p capitalize">Group details</q-item-tile>
                <q-item-tile sublabel class="font-size-80p">Display group description</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="settings_display_groups_description" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
            <!-- cards -->
            <q-item link tag="label">
              <q-item-side>
                <img style="width:40px" src="/statics/_demo/lifestyle.app.display.svg" class="brighten-50 dark-img-invert-100 on-left-sm"/>
              </q-item-side>
              <q-item-main class="font-size-160p text-weight-semibold">
                <q-item-tile label class="font-size-100p capitalize">Nutritional details</q-item-tile>
                <q-item-tile sublabel class="font-size-80p">Display macronutrients</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="settings_display_cards_mode" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
            <!-- shadow blur -->
            <q-item link tag="label">
              <q-item-side>
                <img style="width:40px" :src="`/statics/_demo/lifestyle.app.effects.svg`" class="brighten-50 dark-img-invert-100 on-left-sm"/>
              </q-item-side>
              <q-item-main class="font-size-160p text-weight-semibold">
                <q-item-tile label class="font-size-100p capitalize">Effects</q-item-tile>
                <q-item-tile sublabel>Shadows, blur, and motion effects</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="settings_display_effects" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
            <!-- sound -->
            <q-item link tag="label">
              <q-item-side>
                <img style="width:40px" :src="`/statics/_demo/lifestyle.app.sound.svg`" class="brighten-50 dark-img-invert-100 on-left-sm"/>
              </q-item-side>
              <q-item-main class="font-size-160p text-weight-semibold">
                <q-item-tile label class="font-size-100p capitalize">Sounds</q-item-tile>
                <q-item-tile sublabel>Immersive accent sounds</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="settings_sound" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
            <!-- voice -->
            <q-item link tag="label">
              <q-item-side>
                <img style="width:40px" :src="`/statics/_demo/lifestyle.app.voice.svg`" class="brighten-50 dark-img-invert-100 on-left-sm"/>
              </q-item-side>
              <q-item-main class="font-size-160p text-weight-semibold">
                <q-item-tile label class="font-size-100p capitalize"><q-chip class="q-chip-pro" dense>PRO</q-chip> &nbsp;Voice A.I.</q-item-tile>
                <q-item-tile sublabel>Enable voice-driven interface</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="settings_voice" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
          </q-list>

        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Dropoff
    -->
    <q-modal id="dialogDropoff" v-if="product.data.channel.online" v-model="dialogDropoffShow" position="bottom" class="appLayer dialog-item" enter-class="animated slideInUp animated-d400" leave-class="animated slideOutDown animated-d400">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('DROPOFF.LABEL_TT') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="tertiary-light" text-color="subinfo" rounded @click.native="soundPlay('sheet_up'); scan()">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'scan_white.svg': 'scan_tertiary.svg')" height="24">
              </q-btn>
            </q-card-main>
          </q-card>
          <!--
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded @click="scan">
                {{ $t('WINGLET.SCAN.L') }}
              </q-btn>
            </q-card-main>
          </q-card>
          -->
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogDropoffShow = false; soundPlay('tap'); soundStop('pinging')">
                <!-- {{ $t('DONE') }} -->
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-center text-family-brand">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <!-- display last scan (if any) -->
          <div v-if="intentions.dropoffs.lastscan">
            <div class="animated fadeInUp">
              <!-- <q-spinner-puff color="gold" size="20em" style="margin-top:-110px;margin-bottom:-187px;opacity:0.2;max-width:90vw"/> -->
              <div>
                <svg stroke="currentColor" width="20em" height="20em" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" class="q-spinner text-gold" style="margin-top:-110px;margin-bottom:-187px;opacity:0.2;max-width:90vw"><g fill="none" fill-rule="evenodd" stroke-width="2"><circle cx="22" cy="22" r="16.0582"><animate attributeName="r" begin="0s" dur="2s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate><animate attributeName="stroke-opacity" begin="0s" dur="2s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate></circle></g></svg>
              </div>
              <img v-if="intentions.dropoffs.lastscan.type === 'winglet'" src="/statics/_demo/letsbutterfly-wings-hw-winglet.svg" height="60" class="block margin-auto-lr relative-position">
              <span v-else class="wings-hw-winglet-standing">
                <img src="~/assets/letsbutterfly-wings-winglet.png" height="60" class="block margin-auto-lr">
              </span>
            </div>
            <h3 class="no-margin text-secondary">{{ intentions.dropoffs.lastscan.name }}</h3>
            <p>You are at a dropoff spot for <br><strong class="text-weight-semibold font-size-120p">{{ productFriendlyName }}</strong></p>
            <p class="text-shallow text-weight-semibold no-margin-bottom">
              This spot is reserved for
            </p>
            <p class="no-margin text-empower text-family-brand text-weight-bold" style="font-size: 44px">
              5<span class="inline-block" style="position: relative; top: -4px;">:</span>00
            </p>
            <span class="text-empower font-size-80p text-weight-bold block" style="margin-top:-10px">minutes</span>
            <!--
                  <div class="full-width columns items-center justify-around row">
                    <span class="column font-size-140p">pay</span>
                    <span class="column" style="font-size:44px">{{ bag.total + (bag.total * (10/100)) + (bag.total * (6.25/100)) | nformat('$0,0.00') }}</span>
                  </div>
             -->
            <div class="columns items-center justify-around row">
              <q-card class="width-50p column margin-auto-lr q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top" style="min-height:80px !important">
                <q-card-main class="block column justify-center full-height">
                  <q-btn class="q-btn-stack limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower" text-color="white" rounded @click.native="soundPlay('sheet_up'); scan()">
                    <img src="/statics/_demo/dropoff.swap_white.svg">
                    <span class="block">Swap</span>
                  </q-btn>
                </q-card-main>
              </q-card>
              <q-card class="width-50p column margin-auto-lr q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top" style="min-height:80px !important">
                <q-card-main class="column justify-center full-height">
                  <q-btn class="q-btn-stack limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="shadow-l" text-color="core" rounded @click="intentions.dropoffs.lastscan=null">
                    <img src="/statics/_demo/dropoff.cancel_tertiary.svg">
                    <span class="block">Clear</span>
                  </q-btn>
                </q-card-main>
              </q-card>
            </div>
            <!--
            <div class="animated fadeInUp full-width title-group text-family-brand text-weight-bolder text-center uppercase">{{ $t('SERVICES.LABEL_TT') }}</div>
            <q-list class="text-family-brand" no-border link>
              <q-item @click.native="setService(service, intentions.dropoffs.lastscan)" item v-for="service in intentions.dropoffs.lastscan.services" :key="`${services[service].key}-${service}`" class="animated fadeInUp animated-d800">
                <q-item-main class="font-size-160p text-weight-semibold">
                  <q-item-tile label>
                    {{ $t(`WINGLET.${service}.L`).split(' ')[0] }}
                    <span :class="services[service].color">
                      {{ $t(`WINGLET.${service}.L`).split(' ').slice(1).join(' ') }}
                    </span>
                  </q-item-tile>
                  <q-item-tile sublabel class="on-left-lg">
                    {{ $t(`WINGLET.${service}.D`) }}
                  </q-item-tile>
                </q-item-main>
                <q-item-side class="text-center on-top-sm">
                  <img :src="`/statics/_demo/${ services[service].icon.name }.svg`" :style="`width: ${ services[service].icon.size }`">
                </q-item-side>
              </q-item>
            </q-list>
            -->
          </div>
          <!-- or else display instructions -->
          <div v-else>
            <transition appear enter-active-class="animated fadeInUp animated-d800" leave-active-class="animated fadeOutDown animated-d800">
              <div v-show="product.data.channel.online">
                <h3 style="margin-bottom:-112px;padding-bottom:20px;">SCAN <span class="lowercase text-weight-regular inline-block">FOR</span> SERVICE</h3>
                <!-- <q-spinner-puff v-show="product.data.channel.online" color="gold" size="20em" style="margin-bottom:-13em;opacity:0.2;max-width:90vw"/> -->
                <div>
                  <svg :class="{ 'invisible': !dialogDropoffShow }" stroke="currentColor" width="20em" height="20em" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" class="q-spinner text-gold" style="margin-bottom: -13em;opacity: 0.2;max-width: 90vw;">
                    <g fill="none" fill-rule="evenodd" stroke-width="2">
                      <circle cx="22" cy="22" r="16.0582">
                      <animate attributeName="r"              begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate>
                      <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate>
                      </circle>
                    </g>
                  </svg>
                </div>
                <q-btn rounded class="block margin-auto-lr" style="margin-bottom:20px; width: 100px;" @click="scan">
                  <img src="~/assets/letsbutterfly-wings-winglet.png" height="100" class="block margin-auto-lr">
                </q-btn>
                <p class="no-margin">Tap above to scan a dropoff spot for <br><strong class="text-weight-semibold font-size-120p">{{ productFriendlyName }}</strong></p>
              </div>
            </transition>
          </div>
          <hr class="dot">
          <p class="caption text-weight-regular text-subinfo">
            The list of services offered is communicated and fulfilled by {{ productFriendlyName }} located at {{ product.data.business.address.full }}.
          </p>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: QR Code
    -->
    <q-modal id="dialogQRCode" v-model="dialogQRCodeShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout style="background-image: url(/statics/_demo/qrcode_primary.svg)">
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('QRCODE.LABEL') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogQRCodeShow = false; soundPlay('tap')">
                <!-- {{ $t('DONE') }} -->
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <transition appear enter-active-class="animated fadeInUp animated-d800">
          <img v-if="product.data.qrcode_ref" :src="product.data.qrcode_ref" style="width:95%;border-radius:2rem;max-width:53vh">
          </transition>
          <transition appear enter-active-class="animated fadeInUp animated-d800">
          <p v-if="product.data.qrcode_ref" class="text-family-brand text-weight-semibold text-center font-size-100p text-attention" style="word-break: break-all;margin-bottom:-20px">
            {{ product.data.shortlink.replace('ltsbtrf.ly', 'mywin.gs') }}
            <q-btn flat round icon="ion-link" @click="openURL(product.data.shortlink.replace('ltsbtrf.ly', 'mywin.gs'))"/>
          </p>
          </transition>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: About
    -->
    <q-modal id="dialogAbout" v-model="dialogAboutShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout style="background-image: url(/statics/_demo/info.svg)">
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('DRAWER.ITEM.ABOUT.L') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogAboutShow = false">
                <!-- {{ $t('DONE') }} -->
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <p class="text-family-brand q-title layout-padding no-padding-top text-subinfo slideInDown" style="padding-bottom:20px" v-html="product.data.business.metas.description"></p>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Share
    -->
    <q-modal id="dialogShare" v-model="dialogShareShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout style="background-image: url(/statics/_demo/square.and.arrow.up.fill_primary.svg)">
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('SHARE') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogShareShow = false; soundPlay('tap')">
                <!-- {{ $t('DONE') }} -->
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list class="text-family-brand" no-border link :dark="anyDarkmode">
            <p class="q-title layout-padding no-padding-top text-subinfo slideInDown" style="padding-bottom:20px">
              Share this channel using any of the following methods and platforms for everyone to view.
            </p>
            <q-item v-if="shareSheetSupport()" item @click.native="shareSheet">
              <q-item-main :label="$t('SHARESHEET.LABEL')" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/rectangle.stack.fill.svg" style="height:33px" :class="{ 'filter-invert-80': anyDarkmode }">
              </q-item-side>
            </q-item>
            <q-item item @click.native="openURL(productFullURI)">
              <q-item-main :label="$t('WEBLINK.LABEL')" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/square.and.arrow.up.fill_primary.svg" style="height:33px">
              </q-item-side>
            </q-item>
            <q-item item @click.native="qrcode">
              <q-item-main :label="$t('QRCODE.LABEL')" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/qrcode_primary.svg" style="height:33px">
              </q-item-side>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <div style="height:40px"></div>
    <l-footer simple/>
  </div>
  </q-pull-to-refresh>
  <q-page-sticky position="bottom" :offset="[0, 30]">
    <transition appear enter-active-class="animated fadeInUp animated400" leave-active-class="animated fadeOutDown animated400">
      <q-btn :class="{ 'animate-bounce': bagUpdated }" v-if="bag.items.length && stickyButtonOrderShow" rounded size="lg" v-ripple push color="primary" class="shadow-24 text-weight-bold animated800 animated-c1" style="height: 60px" @click.native="dialogBagOpen()">
        <img src="/statics/_demo/bag.fill.svg" width="22" class="filter-invert-100"> &nbsp; {{ bag.total | nformat('$0,0.00') }}
      </q-btn>
    </transition>
  </q-page-sticky>
  <q-page-sticky position="bottom-right" :offset="[20, 30]">
    <transition mode="out-in" appear enter-active-class="animated fadeInUp animated400" leave-active-class="animated fadeOutDown animated400">
      <q-btn v-if="settings_voice" key="mic" size="lg" v-ripple round push color="primary" @click.native="personalizeVoice()" class="shadow-24">
        <img src="/statics/_demo/mic_white.svg" width="22">
      </q-btn>
      <q-btn v-else key="heart" size="lg" v-ripple round push color="primary" @click.native="personalizeList(); soundPlay('sheet_up')" class="shadow-24">
        <img src="/statics/_demo/heart.fill_white.svg" width="22">
      </q-btn>
    </transition>
  </q-page-sticky>
  </div>
</template>

<script>
/* globals Recorder */
import LFooter from 'components/l-footer'
import { axiosLIO } from 'plugins/axios'
import { openURL, scroll } from 'quasar'
import PubNub from 'pubnub'

// formatting modules
import moment from 'moment'
import nformat from 'vue-filter-number-format'
import VuePhoneNumberInput from 'vue-phone-number-input'

// Wings
import Wings from '../services/wings.js'

// QR Code Stream
import { QrcodeStream } from 'vue-qrcode-reader'

// Sound { Howl, Howler }
import { Howl } from 'howler'

// Magic.Link
import { Magic } from 'magic-sdk'

// Confetti
import VueConfetti from 'vue-confetti'

// DemoBusiness (v1.0 development)
import DemoBusinessCfg from '../services/demo-business-cfg.js'

export default {
  name: 'PageChannelProduct',
  props: ['auth', 'authenticated', 'lang', 'ecosystem', 'anyDarkmode', 'wingletDialogTrigger', 'accountDialogTrigger'],
  meta () {
    return {
      title: ['Wings', this.paramName].join(' · '),
      description: { name: 'description', content: 'Live channel for ' + this.paramName }
    }
  },
  components: {
    QrcodeStream,
    LFooter,
    VuePhoneNumberInput,
    VueConfetti
  },
  filters: {
    nformat: nformat,
    tformat: function (val) {
      return moment(val).format('MMMM Do, YYYY')
    }
  },
  data () {
    return {
      uuid: null,
      uri: null,
      pn: null,
      channel: null,
      ready: false,
      lastScrolledPayload: null,
      buttonShareShow: false,
      contentInfoShow: false,
      stickyButtonOrderShow: false,
      dialogPerspectiveItem: null,
      dialogPerspectiveShow: false,
      dialogPersonalizeShow: false,
      dialogPersonalizeListShow: false,
      dialogPersonalizeVoiceShow: false,
      dialogPersonalizeVoiceStatus: 0, // 0 - idle, 1 - init, 2 - listening, 3 - processing, 4 - processed
      dialogPersonalizeVoiceText: '',
      dialogProductGroup: null,
      dialogProductItem: null,
      dialogProductShow: false,
      dialogProductSendShow: false,
      dialogProductSendSending: false,
      dialogQueueShow: false,
      dialogQRCodeShow: false,
      dialogShareShow: false,
      dialogAboutShow: false,
      dialogDropoffShow: false,
      dialogAccountShow: false,
      dialogBusinessShow: false,
      dialogDropoffScanShow: false,
      dialogDropoffScanStreamShow: false,
      dialogAccountPhoneNumber: '',
      dialogAccountPhoneNumberProcessing: false,
      dialogAccountPhoneNumberPayload: null,
      datagroups: Wings.datagroups(this),
      bagUpdated: false,
      // account
      account: {
        email: null,
        metadata: null,
        isLoggedIn: false,
        magicLink: null
      },
      // ui
      modalAdaptCache: {
        target: null,
        header: null,
        container: null
      },
      // sounds
      sound: {
        theme: 'glasswing',
        themes: {
          glasswing: {
            ux: {
              tap: {},
              tap_disabled: {},
              sheet_up: {},
              sheet_mini_up: {},
              sheet_drop: {},
              sheet_grab: {},
              entry_actionsheet: {},
              entry_scrub: {},
              notification: {},
              pinging: {}
            }
          }
        }
      },
      // voice
      voice: {
        context: null,
        recorder: null,
        payload: {
          audio: {
            content: null
          },
          config: {
            encoding: 'LINEAR16',
            // sampleRateHertz: 44100,
            languageCode: 'en-US'
          }
        }
      },
      // guardian
      guardian: {
        business: DemoBusinessCfg.business,
        consumer: {
          personas: {
            shakes: { status: null },
            acai_bowls: { status: null },
            burgers: { status: null },
            bowls: { status: null },
            juices: { status: null },
            shots: { status: null },
            cold: { status: null },
            vegan: { status: 'good' },
            eggs: { status: null },
            dairyfree: { status: null },
            curbside: { status: null },
            vegetarian: { status: 'good' },
            glutenfree: { status: null },
            containsnuts: { status: null },
            poultry: { status: 'good' },
            chicken: { status: null },
            meat: { status: 'good' },
            bison: { status: null },
            fish: { status: 'good' },
            beyond_meat: { status: null },
            avocados: { status: null },
            pineapple: { status: null },
            ahi_tuna: { status: null },
            salmon: { status: null },
            mushroom: { status: null },
            lemon: { status: null },
            ginger: { status: null },
            cayenne: { status: null },
            lowcarb: { status: null },
            lowfat: { status: null },
            turmeric: { status: null },
            apple_cider_vinegar: { status: null },
            orange: { status: null },
            honey: { status: null },
            spinach: { status: null },
            black_pepper: { status: null }
          }
        }
      },
      // offerings
      offerings: DemoBusinessCfg.offerings,
      // services
      services: DemoBusinessCfg.services,
      // dropoffs
      dropoffs: DemoBusinessCfg.dropoffs
    }
  },
  computed: {
    paramName () {
      return this.$route.params.uri.split('-')[0].replace(/_/g, ' ')
        .trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    },
    channelID () {
      return this.uri ? 'pn_' + this.uri : null
    },
    productFriendlyName () {
      return this.product.data.uri.split('-')[0].replace(/_/g, ' ')
        .trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    },
    productFullURI () {
      return [document.location.origin, '/channel-demo/', this.product.data.uri].join('')
    },
    productShortURI () {
      return this.product.data.shortlink
    },
    productIcon () {
      if (this.product && this.product.data && this.product.data.business) {
        let website = this.product.data.business.website
        let metas = this.product.data.business.metas
        if (website && metas) {
          let icons = []
          if (metas['twitter:image']) icons.push(metas['twitter:image'])
          if (metas['msapplication-tileimage']) icons.push(metas['msapplication-tileimage'])
          if (icons.length) {
            let icon = icons[0]
            if (icon.indexOf('http') === 0) {
              return icon
            }
            if (website.slice(-1) !== '/') website += '/'
            return website + icon
          }
        }
      }
      return false
    },
    productNameHidden () {
      return !true
    },
    productBanner () {
      return this.anyDarkmode ? 'https://res.cloudinary.com/letsbutterfly/image/upload/f_auto/v1593720437/wings-app/logos/proteinhouse_marlborough-9c5e7e1eb769d58f9e3a1907d8f84328.logo-dark.png'
        : 'https://res.cloudinary.com/letsbutterfly/image/upload/f_auto/v1593720437/wings-app/logos/proteinhouse_marlborough-9c5e7e1eb769d58f9e3a1907d8f84328.logo.png'
    },
    product: {
      get () {
        console.log('product::GET', this.$store.getters['app/getProducts'].list[0])
        return this.$store.getters['app/getProducts'].list[0]
      },
      set (product) {
        console.log('product::SET', product)
        this.$store.commit('app/updateProductPayload', 0, product)
      }
    },
    intentions: {
      get () {
        return this.$store.state.app.intentions
      },
      set (val) {
        return val
      }
    },
    bag: {
      get () {
        return this.$store.state.app.bag
      },
      set (val) {
        return val
      }
    },
    wallet_amount: {
      get () {
        return this.$store.state.app.wallet.us_dollars.amount
      },
      set (val) {
        this.$store.state.app.wallet.us_dollars.amount = val
        this.$store.commit('app/updateAccountWalletUsDollarsAmount', val)
      }
    },
    settings_voice: {
      get () {
        return this.$store.state.app.preferences.voice.enabled
      },
      set (val) {
        this.$store.state.app.preferences.voice.enabled = val
        this.$store.commit('app/updatePreferencesVoiceState', val)
        this.soundPlay('sheet_drop')
      }
    },
    settings_sound: {
      get () {
        return this.$store.state.app.preferences.sound.enabled
      },
      set (val) {
        this.$store.state.app.preferences.sound.enabled = val
        this.$store.commit('app/updatePreferencesSoundState', val)
        this.soundPlay('sheet_drop')
      }
    },
    settings_display_groups_description: {
      get () {
        return this.$store.state.app.preferences.display.groups.description
      },
      set (val) {
        this.$store.state.app.preferences.display.groups.description = val
        this.$store.commit('app/updatePreferencesDisplayGroupsDescription', val)
        this.soundPlay('sheet_drop')
      }
    },
    settings_display_cards_mode: {
      get () {
        return this.$store.state.app.preferences.display.cards.mode
      },
      set (val) {
        this.$store.state.app.preferences.display.cards.mode = val
        this.$store.commit('app/updatePreferencesDisplayCardsMode', val)
        this.soundPlay('sheet_drop')
      }
    },
    settings_display_effects: {
      get () {
        return this.$store.state.app.preferences.display.effects.enabled
      },
      set (val) {
        this.$store.state.app.preferences.display.effects.enabled = val
        this.$store.commit('app/updatePreferencesDisplayEffectsState', val)
        this.soundPlay('sheet_drop')
        if (val) {
          this.enableFilters()
          // this.soundPlay('sheet_drop')
        } else {
          this.disableFilters()
          // this.soundPlay('entry_scrub')
        }
      }
    },
    requests_user () {
      if (this.channel.requests && this.channel.requests.length) {
        if (this.account && this.account.metadata) {
          return this.channel.requests.filter(request => request.user.publicAddress === this.account.metadata.publicAddress)
        }
      }
      return []
    },
    requests_user_count () {
      return this.requests_user.length
    },
    guardian_personas () {
      let _count = 0
      let _personas = {}
      for (let personaGroup in this.guardian.business.personas.groups) {
        for (let personaIndex in this.guardian.business.personas.groups[personaGroup].bases) {
          let personaId = this.guardian.business.personas.groups[personaGroup].bases[personaIndex]
          if (!_personas[personaGroup]) {
            _personas[personaGroup] = {
              count: 0,
              data: {}
            }
          }
          if (this.guardian.consumer.personas[personaId] && this.guardian.consumer.personas[personaId].status !== null) {
            _count++
            _personas[personaGroup].count++
            _personas[personaGroup].data[personaId] = this.guardian.consumer.personas[personaId]
          } else {
            _personas[personaGroup].data[personaId] = { status: null }
          }
        }
      }
      return {
        count: _count,
        data: _personas
      }
    },
    offerings_computed () {
      // offerings:
      let computed = {
        items: this.offerings.items,
        consumer: this.guardian_personas
      }

      // consumer personas
      let targetPersonas = {
        byPersona: [],
        byGroups: {},
        byGHA: {}
      }
      let targetPersonasCount = 0
      for (let g in computed.consumer.data) {
        for (let p in computed.consumer.data[g].data) {
          let d = computed.consumer.data[g].data[p]
          if (d.status !== null) {
            targetPersonasCount++
            // byPersona
            targetPersonas.byPersona.push(p)
            // byGroup
            let byGroup = this.offerings.personas.bases[p].group
            if (!targetPersonas.byGroups[byGroup]) {
              targetPersonas.byGroups[byGroup] = {
                good: [],
                avoid: [],
                health: []
              }
            }
            targetPersonas.byGroups[byGroup][d.status].push(p)
            // byGHA
            if (!targetPersonas.byGHA[d.status]) {
              targetPersonas.byGHA[d.status] = []
            }
            targetPersonas.byGHA[d.status].push(p)
          }
        }
      }
      // console.log(':: targetPersonas :', targetPersonas)

      // detect opposing personas
      let mutateOpposements = (personas) => {
        let opposites = this.guardian.business.personas.opposites
        for (let p in personas) {
          let persona = personas[p]
          for (let op in personas) {
            if (p === op) continue
            let oppPersona = personas[op] ? personas[op] : null
            // console.log(`oppositions: ${persona} <> ${oppPersona}`)
            // oppose only if both personas are explicitly set to "good"
            let oppExplicit = this.guardian.consumer.personas[persona].status === 'good' && this.guardian.consumer.personas[oppPersona].status === 'good'
            // console.log('is [', persona, ' <> ', oppPersona, '] oppExplicit?', oppExplicit)
            // oppExplicit = true
            if (oppExplicit) {
              let oppExists = (persona && opposites[oppPersona] && opposites[oppPersona].indexOf(persona) >= 0) || false
              if (oppExists) {
                let personaSplit0 = personas.filter((c) => c.indexOf(persona) !== 0)
                let personaSplit1 = personas.filter((c) => c.indexOf(oppPersona) !== 0)
                // console.log(`   ✅: MUTATING: ${persona}:${oppPersona}`, personaSplit0, personaSplit1)
                return [mutateOpposements(personaSplit0), mutateOpposements(personaSplit1)]
              }
            }
          }
        }
        // console.log('❤️❤️❤️', personas)
        return personas
      }
      // mutute only "good" personas
      let targetPersonasSplits = mutateOpposements(targetPersonas.byPersona)
      // let targetPersonasSplits = mutateOpposements(targetPersonas.byGHA.good)
      // console.log(':: targetPersonasSplits ::', targetPersonasSplits)
      if (typeof targetPersonasSplits[0] === 'object') {
        targetPersonasSplits = [targetPersonasSplits[0], targetPersonasSplits[1]]
      } else {
        targetPersonasSplits = [targetPersonasSplits]
      }
      // console.log(':: targetPersonasSplits ::', targetPersonasSplits)

      // clean opposing personas
      let isPersonaContainer = (set) => {
        return typeof set === 'object' && set.length && typeof set[0] !== 'object'
      }
      let cleanPersonas = []
      let cleanPersonasSplits = (personas) => {
        // console.log(' >> ', personas)
        if (isPersonaContainer(personas)) {
          cleanPersonas.push(personas)
        } else {
          for (let p in personas) {
            if (isPersonaContainer(personas[p])) {
              cleanPersonas.push(personas[p])
            } else {
              cleanPersonas.push(cleanPersonasSplits(personas[p]))
            }
          }
        }
      }
      cleanPersonasSplits(targetPersonasSplits)
      cleanPersonas = cleanPersonas.filter((c) => c)
      // console.log(':: cleanPersonas ::', cleanPersonas)
      let targetPersonasSplitsRefined = []
      for (let tps in cleanPersonas) {
        // console.log(cleanPersonas[tps].sort())
        targetPersonasSplitsRefined.push(JSON.stringify(cleanPersonas[tps].sort()))
      }
      targetPersonasSplitsRefined = targetPersonasSplitsRefined.filter((v, i, a) => a.indexOf(v) === i)
      // console.log(':: targetPersonasSplitsRefined ::', targetPersonasSplitsRefined)
      for (let tps in targetPersonasSplitsRefined) {
        targetPersonasSplitsRefined[tps] = JSON.parse(targetPersonasSplitsRefined[tps])
        // console.log(targetPersonasSplitsRefined[tps])
      }
      console.log(':: targetPersonasSplitsRefined ::')
      for (let rtp in targetPersonasSplitsRefined) {
        console.log(targetPersonasSplitsRefined[rtp])
      }

      // toggle verboseDebug for console readout (slows performance)
      let verboseDebug = false

      // go through the offerings
      let promotedOffers = {}
      for (let offer in computed.items) {
        let computedOffer = computed.items[offer]
        let promoted = []
        let promotedDefault = false
        let promotionProcessed = false

        // Current offer
        if (verboseDebug) console.log('===============================================')
        if (verboseDebug) console.log(`🟣     :: OFFER: ${offer}`)

        // go through all the potential products for this offering
        for (let p in computedOffer.products) {
          let product = computedOffer.products[p]

          // status of current products' promotion state
          let promotedStatus = null
          // let promotedStatusByGroups = {}

          // go through each persona splits (if any) and analyze each group
          let totalTargetPersonasSplits = targetPersonasSplitsRefined.length
          for (let tpsr in targetPersonasSplitsRefined) {
            // setup the target persona for the current product offering
            let targetPersonasSplit = targetPersonasSplitsRefined[tpsr]
            let targetPersonasSplitMeta = {
              byPersona: [],
              byGroups: {},
              byGHA: {}
            }
            // ... byPersona
            targetPersonasCount = targetPersonasSplit.length
            targetPersonasSplitMeta.byPersona = targetPersonasSplit
            for (let tpsi in targetPersonasSplit) {
              let _persona = targetPersonasSplit[tpsi]
              let consumerPersona = this.guardian.consumer.personas[_persona]
              // ... byGroup
              let byGroup = this.offerings.personas.bases[_persona].group
              if (!targetPersonasSplitMeta.byGroups[byGroup]) {
                targetPersonasSplitMeta.byGroups[byGroup] = {
                  good: [],
                  avoid: [],
                  health: []
                }
              }
              targetPersonasSplitMeta.byGroups[byGroup][consumerPersona.status].push(_persona)
              // ... byGHA
              if (!targetPersonasSplitMeta.byGHA[consumerPersona.status]) {
                targetPersonasSplitMeta.byGHA[consumerPersona.status] = []
              }
              targetPersonasSplitMeta.byGHA[consumerPersona.status].push(_persona)
            }
            //
            if (verboseDebug) console.log('===============================================')
            if (verboseDebug) console.log(`       :: PRODUCT ${offer}•${product.default ? 'default' : 'mutation[' + p + ']'} +testing(_meta: ${(1 * tpsr) + 1} / ${totalTargetPersonasSplits})`)
            if (verboseDebug) console.log(`       :: PRODUCT _meta`, product.personas)
            if (verboseDebug) console.log('       :: _meta.byPersona', targetPersonasSplitMeta.byPersona)
            if (verboseDebug) console.log('       :: _meta.byGroups', targetPersonasSplitMeta.byGroups)
            if (verboseDebug) console.log('       :: _meta.byGHA', targetPersonasSplitMeta.byGHA)
            if (verboseDebug) console.log('       ::======================================')
            //
            //
            // global computes
            //
            let mn = product.nutrition.macronutrients
            let total = mn.p + mn.c + mn.f
            product.nutrition.total = total
            product.nutrition.p_portion = mn.p / total
            product.nutrition.c_portion = mn.c / total
            product.nutrition.f_portion = mn.f / total

            // check if this product is satisfactory against _meta.GOOD
            if (verboseDebug) console.log('       :: CHECK good.*', targetPersonasSplitMeta.byGHA.good)
            if (targetPersonasSplitMeta.byGHA.good) {
              let totalGoods = 0
              for (let g in targetPersonasSplitMeta.byGHA.good) {
                let gg = targetPersonasSplitMeta.byGHA.good[g]
                let ggExists = product.personas.indexOf(gg) >= 0
                if (!ggExists) {
                  // check ingredients
                  let ingredientsMatch = product.contains.filter((c) => c.base === gg)
                  ggExists = ingredientsMatch && ingredientsMatch.length > 0
                }
                if (!product.computes) {
                  product.computes = {}
                }
                if (!ggExists) {
                  if (gg === 'lowcarb') {
                    let carbs = (mn.c / total) * 100
                    if (carbs >= 20 && carbs <= 30) {
                      product.computes.lowcarb = true
                      ggExists = true
                    }
                  } else if (gg === 'lowfat') {
                    let fats = (mn.f / total) * 100
                    if (fats >= 15 && fats <= 20) {
                      product.computes.lowfat = true
                      ggExists = true
                    }
                  }
                }
                if (verboseDebug) console.log(`       :: CHECK good.${gg}`, ggExists)
                if (ggExists) {
                  totalGoods++
                }
              }
              promotedStatus = totalGoods === targetPersonasSplitMeta.byGHA.good.length
            } else {
              if (verboseDebug) console.log(`       :: CHECK good [EMPTY]`)
            }
            // check if this product is satisfactory against _meta.AVOID
            if (verboseDebug) console.log('       :: CHECK avoid.*', targetPersonasSplitMeta.byGHA.avoid)
            if (targetPersonasSplitMeta.byGHA.avoid) {
              let totalAvoids = 0
              for (let a in targetPersonasSplitMeta.byGHA.avoid) {
                let aa = targetPersonasSplitMeta.byGHA.avoid[a]
                // check personas
                let aaExists = product.personas.indexOf(aa) >= 0
                if (!aaExists) {
                  // check ingredients
                  let ingredientsMatch = product.contains.filter((c) => c.base === aa)
                  aaExists = ingredientsMatch && ingredientsMatch.length > 0
                }
                if (!product.computes) {
                  product.computes = {}
                }
                if (!aaExists) {
                  if (aa === 'lowcarb') {
                    let carbs = (mn.c / total) * 100
                    if (carbs >= 20 && carbs <= 30) {
                      product.computes.lowcarb = true
                      aaExists = true
                    }
                  } else if (aa === 'lowfat') {
                    let fats = (mn.f / total) * 100
                    if (fats >= 15 && fats <= 20) {
                      product.computes.lowfat = true
                      aaExists = true
                    }
                  }
                }
                if (verboseDebug) console.log(`       :: CHECK avoid.${aa}`, aaExists)
                if (aaExists) {
                  totalAvoids++
                }
              }
              if (promotedStatus) {
                promotedStatus = promotedStatus && totalAvoids === 0
              }
              // promotedStatus = totalAvoids === 0
            }
            if (verboseDebug) console.log('       :: promotedDefault', promotedDefault)
            if (verboseDebug) console.log('       :: promotedStatus', promotedStatus, promotedStatus ? '✅' : '❌')

            // promote product
            if (promotedStatus && !promotedDefault) {
              promoted.push(product)
            }
            if (product.default && promotedStatus && !promotedDefault) {
              promotedDefault = true
            }
          }
        }

        // finalize based on flow type
        if (computedOffer.flow === 'exact' && !promotionProcessed) {
          if (!promoted.length && !targetPersonasCount) {
            if (!computedOffer.products[0].personas.length) {
              promoted.push(computedOffer.products[0])
            }
          }
        }

        if (promoted.length) {
          promotedOffers[offer] = {
            offer: offer,
            products: promoted
          }
        }
      }

      return promotedOffers
    }
  },
  /*
   * INIT
   */
  created () {
    document.querySelector('#q-app > .q-loading-bar.top.bg-primary').hidden = true

    // new Magic Link
    this.accountInit()

    // update settings
    this.soundInit()
    if (!this.settings_display_effects) {
      this.disableFilters()
    }

    // uuid
    this.uuid = localStorage.getItem('uuid')
    if (!this.uuid) {
      this.uuid = this.$guid.noHyphen(this.$guid.generate())
    }
    localStorage.setItem('uuid', this.uuid)
    // init Wings data
    Wings.DFID = {}
    Wings.DGID = {}
    for (let dg in this.datagroups) {
      Wings.DGID[this.datagroups[dg].id.toUpperCase()] = 1 * dg
      for (let df in this.datagroups[dg].datafields) {
        Wings.DFID[this.datagroups[dg].datafields[df].id] = { datagroup: dg, datafield: df }
        Wings.DGID[[this.datagroups[dg].id, this.datagroups[dg].datafields[df].id].join('_').toUpperCase()] = 1 * df
      }
    }

    // process any instructionSet
    this.processIs()

    // connect to PN
    this.channelInit()
  },
  beforeMount () {
    // query product information
    axiosLIO.get('/channel/' + this.uri).then((res) => {
      console.log(res)
      let product = res.data.data.product
      product.data = JSON.parse(product.payload)
      this.$store.commit('app/updateProducts', { list: [product], group: {} })
      this.ready = true
      this.datafieldsInit()
      this.manifestInit()
    })
  },
  mounted () {
    document.querySelector('#appHeader').classList.add('no-border')
    document.querySelector('#appHeader').classList.add('no-shadow')
  },
  watch: {
    wingletDialogTrigger () {
      this.wingletDialogOpen()
    },
    accountDialogTrigger () {
      setTimeout(() => {
        this.soundPlay('sheet_up')
        this.dialogAccountShow = true
        this.toolbarShadowOnOverscrollClear()
      }, 100)
    }
  },
  methods: {
    openURL,
    confetti_start () {
      this.$confetti.start({
        particles: 20,
        windSpeedMax: 2
      })
      setTimeout(this.confetti_stop, 5000)
    },
    confetti_stop () {
      this.$confetti.stop()
    },
    getEcosystemLabel (l) {
      return `E.${this.ecosystem_id_t}.${l}`
    },
    htmlEntities (str) {
      return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
    },
    scrolled (scroll) {
      if (!scroll) {
        scroll = this.lastScrolledPayload
      }
      if (scroll.direction === 'down' && scroll.position >= 1) {
        document.querySelector('#appHeader').classList.remove('no-shadow')
      } else if (scroll.direction === 'up' && scroll.position <= 10) {
        document.querySelector('#appHeader').classList.add('no-shadow')
      }

      if (scroll.direction === 'down' && scroll.position >= 222) {
        this.buttonShareShow = true
        document.querySelector('#appTitle .title').innerText = 'PROTEIN HOUSE'
      } else if (scroll.direction === 'up' && scroll.position <= 333) {
        this.buttonShareShow = false
        document.querySelector('#appTitle .title').innerText = this.$t('HELLO_FRIEND')
      }

      this.stickyButtonOrderShow = scroll.position > 600
      this.drawAppSubtitle()
      // backup last scrolled data
      this.lastScrolledPayload = scroll
    },
    drawAppSubtitle () {
      if (this.intentions && this.intentions.service) {
        try {
          document.querySelector('#appTitle .q-toolbar-subtitle').innerHTML = [
            '<div>',
            // '<span>', this.$t(`WINGLET.${this.intentions.service}.L`), '</span>',
            '<span>', this.intentions.dropoffs.lastscan.name, '</span>',
            '</div>'
          ].join('')
        } catch (e) {}
      }
    },
    async accountInit () {
      const useMagicProductionKey = true
      if (!this.account.magicLink) {
        this.account.magicLink = new Magic(useMagicProductionKey ? 'pk_live_021C33000C015223' : 'pk_test_51C23D17705F42EB')
      }
      this.magicRefresh()
    },
    updatePhoneNumber (payload) {
      this.dialogAccountPhoneNumberPayload = payload
      return true
    },
    async accountLogin () {
      let pn = this.dialogAccountPhoneNumberPayload

      if (pn && pn.isValid) {
        this.dialogAccountPhoneNumberProcessing = true
        this.magicHandleLogin(pn.e164)
      }
    },
    async accountLogout () {
      this.dialogAccountPhoneNumberProcessing = true
      this.magicHandleLogout()
    },
    async magicRefresh () {
      let _t = this
      setTimeout(() => { _t.dialogAccountPhoneNumberProcessing = false }, 500)
      const isLoggedIn = await this.account.magicLink.user.isLoggedIn()
      this.account.isLoggedIn = isLoggedIn
      this.account.email = ''
      this.account.metadata = {}
      if (isLoggedIn) {
        const userMetadata = await this.account.magicLink.user.getMetadata()
        this.account.email = userMetadata.email
        this.account.phoneNumber = userMetadata.phoneNumber
        this.account.metadata = userMetadata
      }
    },
    async magicHandleLogin (phoneNumber) {
      // await this.account.magicLink.auth.loginWithMagicLink({ email })
      // await this.account.magicLink.auth.loginWithEmailOTP({ email: phoneNumber })
      await this.account.magicLink.auth.loginWithSMS({ phoneNumber })
      await this.magicRefresh()
    },
    async magicHandleLogout () {
      await this.account.magicLink.user.logout()
      await this.magicRefresh()
    },
    walletOpen () {
      this.soundPlay('entry_actionsheet')
      let actions = [{
        label: 'Add Funds' + '<p class="q-actionsheet-sublabel font-size-80p">Add $20, $40, $80, or $100</p>',
        avatar: '/statics/_demo/collection.add.svg',
        status: 'add'
      }, {
        label: 'Automate Funds' + '<p class="q-actionsheet-sublabel font-size-80p">Automatically load funds based on rules</p>',
        avatar: '/statics/_demo/collection.add.multitple.svg',
        status: 'automate'
      }, {}, {
        label: 'Manage ' + '<p class="q-actionsheet-sublabel font-size-80p">History, sharing, and payment sources</p>',
        avatar: '/statics/_demo/lifestyle.app.wallet.dollar-square.svg',
        status: 'manage'
      }]
      this.$q.actionSheet({
        title: 'Wallet',
        actions
      }).then(action => {
        if (action.status === 'add') {
          this.walletAdd()
        } else {
          this.soundPlay('tap')
          this.$q.dialog({
            title: 'not available'
          })
        }
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    walletAdd () {
      this.soundPlay('entry_actionsheet')
      this.$q.actionSheet({
        title: 'Add Funds',
        actions: [{
          label: '$20.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 20.00
        }, {
          label: '$40.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 40.00
        }, {
          label: '$80.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 80.00
        }, {
          label: '$100.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 100.00
        }]
      }).then(action => {
        if (action.value) {
          this.wallet_amount = parseFloat(this.wallet_amount) + action.value
          this.$q.notify({
            color: 'white',
            textColor: 'value',
            detail: 'Wallet',
            message: nformat(this.wallet_amount, '$0,0.00'),
            position: 'top',
            timeout: 2000
          })
          this.soundPlay('notification')
        }
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    soundInit () {
      // load sounds
      let theme = this.sound.themes[this.sound.theme]
      Object.keys(theme.ux).forEach((uxi) => {
        console.log(':: Sound load: ', uxi)
        this.sound.themes[this.sound.theme].ux[uxi] = new Howl({ src: [`/statics/sound/${uxi}.mp3`] })
      })
      this.sound.loaded = true
    },
    soundPlay (uxi) {
      // async-run the sound (if enabled)
      if (this.settings_sound) {
        setTimeout(() => {
          this.sound.themes[this.sound.theme].ux[uxi].play()
        }, 1)
      }
    },
    soundLoop (uxi, loop) {
      this.sound.themes[this.sound.theme].ux[uxi].loop(!!loop)
    },
    soundStop (uxi) {
      this.sound.themes[this.sound.theme].ux[uxi].stop()
    },
    soundFade (uxi, from, to) {
      this.sound.themes[this.sound.theme].ux[uxi].fade(from, to, 400)
    },
    disableSounds () {
      this.settings_sound = false
    },
    enableSounds () {
      this.settings_sound = true
    },
    disableFilters () {
      document.querySelector('html').classList.add('no-filters')
    },
    enableFilters () {
      document.querySelector('html').classList.remove('no-filters')
    },
    modalAdapt (obj) {
      let modalTarget = this.toolbarShadowOnOverscrollTarget()
      let modalTargetHeader = modalTarget.querySelector('.q-toolbar')
      let modalTargetContainer = modalTarget.querySelector('.modal-content')

      let modalStyleDefault = 'border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; transition: all 0.2s ease-in-out !important;'
      // header adaption
      if (modalTarget) {
        if (obj.isFirst) {
          console.log('grab: started')
          modalTargetHeader.classList.remove('cursor-grab')
          modalTargetHeader.classList.add('cursor-grabbing')
          let offsetHeight = modalTargetContainer.offsetHeight
          let finalHeight = offsetHeight - obj.delta.y
          modalTargetContainer.setAttribute('style', `max-height: ${finalHeight}px; transform: scale(1.04)`)
          modalTarget.classList.remove('dialog-grow')
          modalTarget.classList.remove('dialog-grow-full')
          this.soundPlay('sheet_grab')
        } else if (obj.isFinal) {
          console.log('grab: ended')
          modalTargetHeader.classList.remove('cursor-grabbing')
          modalTargetHeader.classList.add('cursor-grab')
          let offsetHeight = modalTargetContainer.offsetHeight
          let finalHeight = offsetHeight - obj.delta.y // transform: scale(1);
          // adjust position (if needed)
          let availableHeight = window.innerHeight
          if (availableHeight - finalHeight <= 50) {
            finalHeight = availableHeight
            modalTarget.classList.add('dialog-grow')
            modalTarget.classList.add('dialog-grow-full')
          } else if (finalHeight <= 200) {
            finalHeight = 200
          // } else if (availableHeight - finalHeight >= 61) {
          //   finalHeight = availableHeight - 61
          }
          modalTargetContainer.setAttribute('style', `max-height: ${finalHeight}px; ${modalStyleDefault}`)
          this.soundPlay('sheet_drop')
        } else {
          console.log('grab: grabbing...')
          let offsetHeight = modalTargetContainer.offsetHeight
          let finalHeight = offsetHeight - obj.delta.y // transform: scale(1.04);
          modalTargetContainer.setAttribute('style', `max-height: ${finalHeight}px; transition: none;
          transform: scale(1.04);
          box-shadow: 0 -11px 15px -7px rgba(0,0,0,0.2), 0 -24px 38px 3px rgba(0,0,0,0.14), 0 -9px 46px 8px rgba(0,0,0,0.12) !important`)
        }
      }
    },
    itemContainsGroup (item, group) {
      for (let c in this.offerings_computed[item].products[0].contains) {
        let contains = this.offerings_computed[item].products[0].contains[c]
        if (this.offerings.personas.bases[contains.base] && this.offerings.personas.bases[contains.base].group === group) {
          return true
        }
      }
      return false
    },
    processIs (hash) {
      let _hash = hash || document.location.hash
      if (_hash && _hash.length) {
        let instructionSet = _hash.split('#')[1], _is = {
          instruction: instructionSet.substr(0, 2),
          payload: instructionSet.substr(2)
        }
        switch (_is.instruction) {
          case 'rd': {
            // ref:dropoff
            console.log('::_is: ref:DROPOFF #', _is.payload)
            this.$store.state.app.intentions.dropoffs.lastscan = this.dropoffs['d' + _is.payload]
            // show dialog
            setTimeout(() => {
              this.dialogDropoffShow = true
            }, this.ready ? 200 : 1000)
            break
          }
          default: {
            console.log('::_is: <UNKNOWN>')
          }
        }
      }
    },
    wingletDialogOpen () {
      // this.$store.state.app.intentions.dropoff = !this.$store.state.app.intentions.dropoff
      // this.soundLoop('pinging', true)
      setTimeout(() => {
        this.dialogDropoffShow = true
        this.soundPlay('sheet_up')
        // this.soundPlay('pinging')
        this.toolbarShadowOnOverscrollClear()
      }, 1)
    },
    dialogQueueOpen () {
      this.dialogQueueShow = true
      this.soundPlay('sheet_up')
      this.toolbarShadowOnOverscrollClear()
    },
    dialogQueueClose () {
      this.dialogQueueShow = false
      this.soundPlay('tap')
    },
    scan () {
      this.dialogDropoffScanShow = true
      this.toolbarShadowOnOverscrollClear()
      setTimeout(() => {
        this.dialogDropoffScanStreamShow = true
      }, 200)
    },
    scanOff () {
      this.dialogDropoffScanStreamShow = false
      setTimeout(() => {
        this.dialogDropoffScanShow = false
        this.toolbarShadowOnOverscrollClear()
      }, 200)
    },
    scanFlashToggle () {},
    scanCameraToggle () {},
    wingletOnDecode (result) {
      let shortlink = this.product.data.shortlink
      console.log(':: wingletOnDecode: product.shortlink: ', shortlink)
      if (result.indexOf(shortlink) === 0 || result.indexOf('https://ltsbtrf.ly/2YkWOo2') === 0) {
        console.log(':: wingletOnDecode: decoded: ', result, ': consider')
        try {
          document.getElementById('wingletDecodeContainer').classList.add('shrink')
        } catch (e) {}
        //
        let hash = result.indexOf('#') >= 0 ? result.split('#')[1] : false
        if (hash) {
          this.processIs(`#${hash}`)
          setTimeout(() => {
            this.dialogDropoffScanShow = false
            this.toolbarShadowOnOverscrollClear()
            try {
              document.getElementById('wingletDecodeContainer').classList.remove('shrink')
            } catch (e) {}
          }, 400)
        }
      } else {
        console.log(':: wingletOnDecode: decoded: ', result, ': ignore')
        setTimeout(() => {
          this.$q.notify({
            detail: 'Ignored',
            color: 'white',
            textColor: 'value',
            message: 'Unrelated Code',
            position: 'top',
            timeout: 2000
          })
          this.soundPlay('notification')
        }, 1)
      }
    },
    async wingletOnInit (promise) {
      try {
        await promise
      } catch (error) {
        // if (error.name === 'NotAllowedError') {
        //   this.error = "ERROR: you need to grant camera access permisson"
        // } else if (error.name === 'NotFoundError') {
        //   this.error = "ERROR: no camera on this device"
        // } else if (error.name === 'NotSupportedError') {
        //   this.error = "ERROR: secure context required (HTTPS, localhost)"
        // } else if (error.name === 'NotReadableError') {
        //   this.error = "ERROR: is the camera already in use?"
        // } else if (error.name === 'OverconstrainedError') {
        //   this.error = "ERROR: installed cameras are not suitable"
        // } else if (error.name === 'StreamApiNotSupportedError') {
        //   this.error = "ERROR: Stream API is not supported in this browser"
        // }
      }
    },
    setService (service, payload) {
      console.log(':: setService ', service, payload)
      this.intentions.service = service
      this.dialogDropoffShow = false
      this.toolbarShadowOnOverscrollClear()
      setTimeout(() => {
        this.drawAppSubtitle()
      }, 100)
    },
    channelInit (done) {
      this.pn = null
      if (done) done()
      this.channelConnect()
      this.channelSubscribe()
      this.channelSeekHistory()
      // this.channelSetState()
    },
    channelConnect () {
      // update/check URI
      this.uri = this.$route.params.uri
      this.pn = new PubNub({
        subscribeKey: 'sub-c-6ef8f7b4-860c-11e9-99de-d6d3b84c4a25',
        publishKey: 'pub-c-4a7e4814-55a0-4e5f-98d7-eba6d8e92cd3',
        uuid: this.uuid,
        ssl: true,
        autoNetworkDetection: true
      })
      this.pn.addListener({
        message: (m) => {
          if (m.channel.split('.').pop() === 'requests') {
            // this a request UPDATE
            console.log(':: Channel: [main.requests] UPDATE:')
            console.log(m.message)
            return
          }
          console.log(':: Channel: [main] UPDATE:')
          console.log(m.message)
          let channelOld = this.channel
          let channelNew = m.message
          this.channel = channelNew
          // compute channelDiff
          let channelChg = []
          Object.keys(channelOld).forEach((i) => {
            if (i === 'requests') return
            if (i === 'spinach') {
              console.log('spinach before: ', channelOld[i])
              console.log('spinach after : ', channelNew[i])
            }
            if (channelOld[i] !== channelNew[i]) {
              channelChg.push({ key: i, val: channelNew[i] })
            }
          })
          console.log(':: Channel: [main] UPDATE: ALL.DIFF:')
          console.log(channelChg)
          channelChg = channelChg.length ? channelChg[0] : false
          console.log(':: Channel: [main] UPDATE: FINAL.DIFF:')
          console.log(channelChg)
          if (channelChg.key === 'requests') {
            this.product.data.channel.requests = channelNew.requests
            // this.product.data.channel[channelChg.key] = channelChg.val
          } else if (channelChg !== false) {
            let _dix = Wings.DFID[channelChg.key].datagroup
            let _fix = Wings.DFID[channelChg.key].datafield
            //
            this.datagroups[_dix].datafields[_fix].value.option = channelChg.val
            this.product.data.channel[channelChg.key] = channelChg.val
            this.$store.commit('app/updateProductPayload', 0, this.product.payload)
            //
            let _dg = this.datagroups[_dix]
            let _df = _dg.datafields[_fix]
            let message = []
            if (_df.valueType === Boolean || _df.notifyLabel === true) {
              message.push(this.$t(channelChg.key.toUpperCase() + '.LABEL'))
            }
            message.push(this.$t(this.datagroups[_dix].datafields[_fix].value.options[channelChg.val].label.toUpperCase()))
            message = message.join(' » ').toLowerCase()
            // async notifcation
            // remove notifications (for now)
            // setTimeout(() => {
            //   this.$q.notify({
            //     detail: this.$t(_dg.id.toUpperCase() + '.LABEL_TT').toUpperCase(),
            //     color: 'white',
            //     textColor: 'value',
            //     message: message,
            //     position: 'top',
            //     timeout: 2000
            //   })
            // }, 1)
            // hijack global persona (for avoidance)
            if (channelChg.key === 'avocados') {
              this.guardian.consumer.personas.avocados.status = channelChg.val === 0 ? null : 'avoid'
            }
            if (channelChg.key === 'eggs') {
              this.guardian.consumer.personas.eggs.status = channelChg.val === 0 ? null : 'avoid'
            }
            if (channelChg.key === 'honey') {
              this.guardian.consumer.personas.honey.status = channelChg.val === 0 ? null : 'avoid'
            }
            if (channelChg.key === 'spinach') {
              this.guardian.consumer.personas.spinach.status = channelChg.val === 0 ? null : 'avoid'
            }
            if (channelChg.key === 'kitchen_wait_time' || channelChg.key === 'barista_wait_time') {
              console.log('change:: ' + channelChg.key)
              console.log(channelChg.val)
              let wait = 0
              switch (channelChg.val) {
                default:
                case 0: wait = 0; break
                case 1: wait = 5; break
                case 2: wait = 10; break
                case 3: wait = 15; break
                case 4: wait = 25; break
                case 5: wait = 30; break
              }
              this.guardian.business.stations[channelChg.key.split('_')[0]].times.wait = wait
            }
          }
        }
      })
    },
    renderListKey (dg, df, init) {
      return [dg, df].join('_')
    },
    channelSubscribe () {
      this.pn.subscribe({
        channels: [this.channelID, `${this.channelID}.requests`],
        withPresence: true
      })
    },
    channelSeekHistory (done) {
      this.channel = null
      if (done) done()
      if (this.pn) {
        return this.pn.history({ channel: this.channelID, count: 1 }, (status, response) => {
          if (!status.error) {
            setTimeout(() => {
              this.channel = response.messages.length ? response.messages[0].entry : false
              // hijack
              let wait = 0
              switch (this.channel.kitchen_wait_time) {
                default:
                case 0: wait = 0; break
                case 1: wait = 5; break
                case 2: wait = 10; break
                case 3: wait = 15; break
                case 4: wait = 25; break
                case 5: wait = 30; break
              }
              this.guardian.business.stations.kitchen.times.wait = wait
              switch (this.channel.barista_wait_time) {
                default:
                case 0: wait = 0; break
                case 1: wait = 5; break
                case 2: wait = 10; break
                case 3: wait = 15; break
                case 4: wait = 25; break
                case 5: wait = 30; break
              }
              this.guardian.business.stations.barista.times.wait = wait
            }, 1400)
          }
        })
      }
      return false
    },
    channelSetState () {
      // let self = this
      this.pn.setState({
        state: {
          observing: true
        },
        uuid: this.uuid,
        channels: [this.channelID]
      }, function (r) {
        console.log(':: channelSetState')
        console.log(':: :: fn()')
        console.log(r)
        // self.channel = false
      })
    },
    toolbarShadowOnOverscrollTarget () {
      let modalTarget = null
      document.querySelectorAll('.modal').forEach((o, i) => {
        if (o.clientHeight !== 0) modalTarget = o
      })
      return modalTarget
    },
    toolbarShadowOnOverscrollClear (timeout = 10) {
      setTimeout(() => {
        try {
          let modalTarget = this.toolbarShadowOnOverscrollTarget()
          if (modalTarget) {
            modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
            // modalTarget.querySelector('.toolbar-overscroll-controls').classList.remove('toolbar-overscroll-controls-show')
          }
        } catch (e) {
          // console.log(':: toolbarShadowOnOverscrollClear: ', e)
        }
      }, timeout)
    },
    toolbarShadowOnOverscroll (scroll) {
      let modalTarget = this.toolbarShadowOnOverscrollTarget()
      if (modalTarget) {
        if (scroll.direction === 'down' && scroll.position >= 1) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.add('toolbar-overscroll-shadow-show')
        } else if (scroll.direction === 'up' && scroll.position <= 10) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
        }
        // multi-controls (if any)
        try {
          if (scroll.direction === 'down' && scroll.position >= 160) {
            modalTarget.querySelector('.toolbar-overscroll-controls').classList.add('toolbar-overscroll-controls-show')
          } else if (scroll.direction === 'up' && scroll.position <= 160) {
            modalTarget.querySelector('.toolbar-overscroll-controls').classList.remove('toolbar-overscroll-controls-show')
          }
        } catch (e) {}
      }
    },
    perspective (card) {
      let ids = card.dataset.index.split('-')
      this.dialogPerspectiveItem = this.datagroups[1 * ids[1]].datafields[1 * ids[3]]
      this.dialogPerspectiveShow = true
      this.toolbarShadowOnOverscrollClear()
      console.log(this.dialogPerspectiveItem)
    },
    cleanURL (url) {
      if (!url) return ''
      return url
        .replace('https://', '')
        .replace('http://', '')
        .replace('www.', '')
        .replace(/\/$/, '')
    },
    shareSheetSupport () {
      // console.log(':: shareSheetSupport: ', !!navigator.share)
      return !!navigator.share
    },
    shareSheet () {
      if (this.shareSheetSupport()) {
        let sharePayload = {
          title: ['Wings', this.productFriendlyName, this.product.data.business.address.full].join(' · '),
          // text: this.product.data.business.address.full,
          url: this.productFullURI
        }
        navigator.share(sharePayload)
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error))
      } else {
        console.log('::SHARE: -- error')
      }
    },
    personalize () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogPersonalizeShow = !this.dialogPersonalizeShow
    },
    personalizeList () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogPersonalizeListShow = !this.dialogPersonalizeListShow
    },
    personalizeVoice () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogPersonalizeVoiceShow = !this.dialogPersonalizeVoiceShow
      if (this.dialogPersonalizeVoiceShow) {
        this.soundPlay('sheet_mini_up')
        this.personalizeVoiceStartListening()
      }
    },
    voiceInit () {
      try {
        window.AudioContext = window.AudioContext || window.webkitAudioContext
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia
        window.URL = window.URL || window.webkitURL

        this.voice.context = new AudioContext()
        console.log(':: recorder: context: created')
        console.log(':: recorder: @navigator.getUserMedia: ' + (navigator.getUserMedia ? 'available' : 'not present'))
      } catch (e) {
        alert(':: recorder: No web audio support in this browser!')
      }

      if (!this.$q.platform.has.touch && navigator.getUserMedia) {
        console.log(':: recorder: getUserMedia: init')
        navigator.getUserMedia({
          audio: true
        }, (stream) => {
          console.log(':: recorder: stream: init')
          this.voiceRecorderInit(stream)
        }, (e) => {
          console.log(':: recorder: No live audio input: ' + e)
        })
      } else if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log(':: recorder: mediaDevices.getUserMedia: init')
        navigator.mediaDevices.getUserMedia({
          audio: true
        }).then((stream) => {
          console.log(':: recorder: stream: init')
          this.voiceRecorderInit(stream)
        }).catch((e) => {
          console.log(':: recorder: No live audio input: ' + e)
        })
      }
    },
    voiceRecorderInit (stream) {
      // create media stream
      console.log(':: recorder: stream: created')
      const input = this.voice.context.createMediaStreamSource(stream)
      // start recorder
      this.voice.recorder = new Recorder(input)
      console.log(':: recorder: initialized')
      this.dialogPersonalizeVoiceStatus = 1 // 1 - init
      this.voiceRecorderStart()
    },
    voiceRecorderStart () {
      this.voice.recorder.record()
      console.log(':: recorder: started')
      this.dialogPersonalizeVoiceStatus = 2 // 2 - listening
      setTimeout(this.voiceRecorderStop, 3.0 * 1000)
    },
    voiceRecorderStop () {
      console.log(':: recorder: stopped')
      this.voice.recorder.stop()
      this.voiceRecorderProcess()
      this.voiceRecorderClear()
    },
    voiceRecorderClear () {
      this.voice.recorder.clear()
      console.log(':: recorder: cleared')
    },
    voiceRecorderProcess () {
      console.log(':: recorder: processing...')
      this.dialogPersonalizeVoiceStatus = 3 // 3 - processing
      this.voice.recorder.exportWAV((blob) => {
        let file = new window.FileReader()
        file.readAsDataURL(blob)
        file.onload = () => {
          const baseData = file.result
          const base64Data = baseData.replace('data:audio/wav;base64,', '')
          this.voice.payload.audio.content = base64Data
          this.$axios.post(
            `https://speech.googleapis.com/v1/speech:recognize?key=AIzaSyCrx0_HAYQ2NfvLJu3aOZQD93pGEBJn-n4`,
            this.voice.payload
          ).then(response => {
            // console.log(':: recorder: processing: response: ', response)
            try {
              let transcript = response.data.results[0].alternatives[0].transcript
              this.personalizeVoiceShowTranscript(transcript)
              this.personalizeVoiceProcessTranscript(transcript)
            } catch (e) {
              this.dialogPersonalizeVoiceText = '--'
              setTimeout(() => {
                this.dialogPersonalizeVoiceStatus = 4
                this.dialogPersonalizeVoiceShow = false
              }, 100)
            }
          }).catch(error => {
            console.log(':: recorder: processing: error: ', error)
          })
        }
      })
    },
    personalizeVoiceStartListening () {
      this.dialogPersonalizeVoiceStatus = 0 // 0 - idle
      this.dialogPersonalizeVoiceText = ''
      this.voiceInit()
    },
    personalizeVoiceShowTranscript (transcript) {
      this.dialogPersonalizeVoiceStatus = 4 // 4 - processed
      console.log(':: recorder: showing: transcript: ', transcript)
      this.soundPlay('notification')
      this.dialogPersonalizeVoiceText = transcript
    },
    personalizeVoiceProcessTranscript (transcript) {
      console.log(':: recorder: processing: transcript: ', transcript)
      axiosLIO.post('/ai/dit', { transcript }).then((res) => {
        console.log(':: recorder: processed: ', res)
        //
        let intent = null
        try {
          intent = res.data.data.intentions.intentName
        } catch (e) {}

        // intent
        console.log(':: recorder: decoded: intent: ', intent)

        // personalize-*
        if (intent.indexOf('personalize') === 0) {
          let personas = []
          if (res.data.data.intentions.result.parameters.fields && res.data.data.intentions.result.parameters.fields.personas) {
            console.log(':: recorder: decoded: personas: exist: #', res.data.data.intentions.result.parameters.fields.personas.listValue.values.length)
            for (let p in res.data.data.intentions.result.parameters.fields.personas.listValue.values) {
              personas.push(
                res.data.data.intentions.result.parameters.fields.personas.listValue.values[p].stringValue
              )
            }
          }
          console.log(':: recorder: decoded: ', intent, personas)
          if (intent === 'personalize-set') {
            // this.personasClear()
            for (let p in personas) {
              this.guardian.consumer.personas[personas[p]] = { status: 'good' }
            }
          }
          if (intent === 'personalize-avoid') {
            // this.personasClear()
            for (let p in personas) {
              this.guardian.consumer.personas[personas[p]] = { status: 'avoid' }
            }
          }
          if (intent === 'personalize-allergic') {
            // this.personasClear()
            for (let p in personas) {
              this.guardian.consumer.personas[personas[p]] = { status: 'health' }
            }
          }
          if (intent === 'personalize-reset') {
            this.personasClear()
            this.personasDefault()
          }
          if (intent === 'personalize-adjust-add') {
            for (let p in personas) {
              this.guardian.consumer.personas[personas[p]] = { status: 'good' }
            }
          }
          if (intent === 'personalize-adjust-remove') {
            for (let p in personas) {
              this.guardian.consumer.personas[personas[p]] = { status: 'avoid' }
            }
          }
        }

        // item-*
        if (intent === 'item-view') {
          let item = null
          try {
            item = res.data.data.intentions.result.parameters.fields.items.stringValue
          } catch (e) {
            console.log(e)
          }
          let group = null
          for (let g in this.offerings.groups) {
            let _g = this.offerings.groups[g]
            if (_g.list && _g.list.indexOf(item) >= 0) {
              group = g
              break
            }
          }
          console.log(':: recorder: decoded: ', intent, group, item)
          if (group && item) {
            this.processCardSelection(['item', group, item].join('-'))
          }
        }

        // bag-*
        if (intent === 'bag-item-add') {
          // let items = []
          if (res.data.data.intentions.result.parameters.fields && res.data.data.intentions.result.parameters.fields.items) {
            console.log(':: recorder: decoded: items: exist: #', res.data.data.intentions.result.parameters.fields.items.listValue.values.length)
            for (let p in res.data.data.intentions.result.parameters.fields.items.listValue.values) {
              let _item = res.data.data.intentions.result.parameters.fields.items.listValue.values[p].stringValue
              if (this.offerings.items[_item]) {
                this.bag.items.push(this.offerings_computed[_item].products[0])
              }
            }
          }
          this.updateBag()
          this.dialogBagOpen()
        }
        if (intent === 'bag-clear') {
          this.bag.items = []
          this.updateBag()
        }

        // end
        setTimeout(() => {
          this.dialogPersonalizeVoiceShow = false
        }, transcript.split(' ').length * 200)
      }).catch(err => {
        console.log(':: recorder: processing: error: ', err)
        this.dialogPersonalizeVoiceShow = false
      })
    },
    personasDefault () {
      for (let p in this.guardian.business.personas.defaults) {
        this.guardian.consumer.personas[this.guardian.business.personas.defaults[p]] = { status: 'good' }
      }
    },
    personasClear () {
      for (let p in this.guardian.consumer.personas) {
        this.guardian.consumer.personas[p] = { status: null }
      }
    },
    personaRemove (persona) {
      console.log(':: personaRemove :', persona)
      this.guardian.consumer.personas[persona] = { status: null }
    },
    personaEdit (persona, payload) {
      this.soundPlay('entry_actionsheet')
      let imgIndicator = '<img class="q-actionsheet-indicator float-right" src="/statics/_demo/checkmark_green.svg"/>'
      let actions = [{
        label: 'Want ' + (payload.status === 'good' ? imgIndicator : '') + '<p class="q-actionsheet-sublabel font-size-80p">Interested</p>',
        avatar: '/statics/_demo/heart_educate.svg',
        status: 'good'
      }, {}, {
        label: 'Avoid ' + (payload.status === 'avoid' ? imgIndicator : '') + '<p class="q-actionsheet-sublabel font-size-80p">Not interested</p>',
        avatar: '/statics/_demo/nosign_attention.svg',
        status: 'avoid'
      }, {
        label: 'Health Condition' + (payload.status === 'health' ? imgIndicator : '') + '<p class="q-actionsheet-sublabel font-size-80p">Allergic or sensitive</p>',
        avatar: '/statics/_demo/avoid_protect.svg',
        status: 'health'
      }]
      if (payload.status !== null) {
        actions.push({})
        actions.push({
          label: 'Remove',
          status: null
        })
      }
      this.$q.actionSheet({ title: persona, actions }).then(action => {
        if (this.guardian.consumer.personas[persona].status === action.status) {
          this.soundPlay('tap_disabled')
        } else if (action.status === null) {
          this.soundPlay('entry_scrub')
        } else {
          this.soundPlay('sheet_drop')
        }
        // console.log(persona, action.status)
        // console.log(':: before :', this.guardian.consumer.personas[persona])
        this.guardian.consumer.personas[persona] = {
          status: action.status
        }
        // console.log(':: after :', this.guardian.consumer.personas[persona])
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    send () {
      this.dialogProductSendShow = true
      this.soundPlay('tap')
      // addToBag(); dialogProductShow = false; soundPlay('tap')
    },
    sendRequest () {
      this.dialogProductSendSending = true
      this.soundPlay('tap')
      const messageRequest = {
        user: {
          publicAddress: this.account.metadata.publicAddress
        },
        info: {
          name: this.offerings_computed[this.dialogProductItem].offer,
          station: this.offerings.items[this.offerings_computed[this.dialogProductItem].offer].station
        },
        item: this.offerings_computed[this.dialogProductItem].products[0]
      }
      console.log('====', messageRequest)
      // connect to PN
      this.pn.publish({
        channel: `${this.channelID}.requests`,
        sendByPost: true,
        storeInHistory: true,
        message: messageRequest
      }, (status, response) => {
        if (status.error) {
          console.log(status)
          this.sendRequestCleanup()
        } else {
          console.log('message Published w/ timetoken', response.timetoken)
          console.log(response)
          this.sendRequestCleanup()
          this.sendRequestUpdateWallet()
        }
      })
    },
    sendRequestCleanup () {
      setTimeout(() => {
        this.dialogProductSendSending = false
        this.dialogProductSendShow = false
        this.dialogProductShow = false
      }, 800)
    },
    sendRequestUpdateWallet () {
      setTimeout(() => {
        let amount = {
          current: this.wallet_amount,
          diff: this.price_total(this.offerings_computed[this.dialogProductItem].products[0].price)
        }
        amount.final = amount.current - amount.diff
        this.wallet_amount = amount.final
        this.$q.notify({
          color: 'white',
          textColor: 'value',
          detail: 'Wallet',
          message: nformat(amount.final, '$0,0.00'),
          position: 'top',
          timeout: 2000
        })
        this.soundPlay('notification')
      }, 800)
    },
    price_total (price) {
      const tax = 6 / 100
      return price + (price * tax)
    },
    scrollToRef (ref) {
      const { getScrollTarget, setScrollPosition } = scroll
      const el = this.$refs[ref].$el
      let target = getScrollTarget(el)
      let offset = el.offsetTop - 100
      let duration = 600
      setScrollPosition(target, offset, duration)
    },
    addToBag () {
      let item = this.dialogProductItem
      let itemPayload = {
        item,
        payload: this.offerings_computed[item].products[0]
      }
      this.$store.state.app.bag.items.push(itemPayload)
      this.updateBag()
    },
    updateBag () {
      let total = 0
      this.$store.state.app.bag.items.forEach((i) => {
        total += i.payload.price
      })
      this.$store.state.app.bag.total = total
      // call scrolled() to show sticky buttons (if needed)
      // this is based on previous scrolled state
      this.scrolled()
      this.bagUpdated = true
      setTimeout(() => {
        this.bagUpdated = false
      }, 1000)
    },
    clearBag () {
      this.$q.dialog({
        title: 'empty your bag?',
        color: 'protect',
        ok: this.$t('YES')
        // cancel: this.$t('NO')
      }).then(() => {
        this.bag.items = []
        this.updateBag()
        this.dialogBagClose()
      })
    },
    share () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogShareShow = !this.dialogShareShow
    },
    about () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogAboutShow = !this.dialogAboutShow
    },
    qrcode () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogQRCodeShow = !this.dialogQRCodeShow
    },
    datagroupData (g, f) {
      let datagroups = Wings.datagroups(this)
      let groupIndex = Wings.DGID[g.toUpperCase()]
      let fieldIndex = Wings.DGID[[g.toUpperCase(), f.toUpperCase()].join('_')]
      let path = datagroups[groupIndex].datafields[fieldIndex]
      return path
    },
    datagroupDataValue (g, f, option, sh = false) {
      let labelOption = ['label', sh ? '_sh' : ''].join('')
      return this.datagroupData(g, f).value.options[option][labelOption]
    },
    datafieldsInit () {
      this.datagroups.forEach((g, i) => {
        g.datafields.forEach((f, j) => {
          // initialize values
          if (f.valueType) {
            if (f.valueType.name === 'Boolean') {
              this.datagroups[i].datafields[j].value = {
                option: 0,
                options: [{
                  bTrue: true,
                  label: this.$t('YES')
                }, {
                  bTrue: false,
                  label: this.$t('NO')
                }]
              }
            }
          }
          // initialize option
          if (typeof this.product.data.channel[f.id] === 'undefined') {
            this.product.data.channel[f.id] = this.datagroups[i].datafields[j].value.option || 0
          } else {
            this.datagroups[i].datafields[j].value.option = this.product.data.channel[f.id]
          }
          // create signals
          this.datagroups[i].datafields[j].signal = {
            // create update method
            update: (option) => {
              this.product.data.channel[f.id] = option
              // this.channelPublish()
              // this.mutateProduct(this.product.id, this.product.data, `SIGNAL.${f.id}: ${this.product.data.channel[f.id]}`, () => {})
            },
            updateAll: (ch) => {
              this.product.data.channel = ch
            },
            // create descriptor
            bCheck: () => {
              let bTrue = this.datagroups[i].datafields[j].value.options[this.datagroups[i].datafields[j].value.option || 0].bTrue
              return (bTrue === true || bTrue === false) ? bTrue : null
            },
            descriptor: () => {
              return this.datagroups[i].datafields[j].value.options[this.datagroups[i].datafields[j].value.option || 0].label
            }
          }
        })
      })
    },
    manifestInit () {
      console.log(':: manifestInit()')
      let manifestLink = document.querySelector('link[rel="manifest"]')
      let manifestCfg = {
        name: this.__qMeta.title,
        short_name: this.productFriendlyName,
        description: this.__qMeta.description.content,
        background_color: '#ffffff',
        theme_color: '#000000',
        start_url: this.productFullURI,
        display: 'fullscreen',
        fakekey: 'fakevalue',
        manifestVersion: +new Date()
      }
      if (this.productIcon) {
        manifestCfg.icons = [{
          src: this.productIcon,
          sizes: '144x144',
          type: 'image/png'
        }]
      }
      if (manifestLink) {
        const manifestString = JSON.stringify(manifestCfg)
        const blob = new Blob([manifestString], { type: 'application/javascript' })
        const manifestURL = URL.createObjectURL(blob)
        // update manifest link
        document.querySelector('link[rel="manifest"]').setAttribute('href', manifestURL)
        if (manifestCfg.icons.length) {
          // update apple-touch-icon (ios)
          document.querySelectorAll('link[rel="apple-touch-icon"]').forEach((domLink) => {
            console.log(':: updated apple-touch-icon @ ', domLink)
            domLink.setAttribute('href', this.productIcon)
            // domLink.setAttribute('sizes', '144x144')
          })
        }
      }
    },
    dialogItemAdjustFit (percentage = 80) {
      let innerHeight = window.innerHeight
      let dialogItem = document.getElementById('dialogItem')
      if (dialogItem !== null) {
        let dialogItemContent = document.querySelector('#dialogItem .modal-content')
        try {
          dialogItemContent.style.setProperty('height', `${(innerHeight * percentage) / 100}px`, 'important')
        } catch (err) {}
      }
    },
    call (number) {
      document.location = 'tel:' + this.cleanPhoneNumber(number)
    },
    cleanPhoneNumber (number) {
      return number.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '')
    },
    processCardSelection (card) {
      if (typeof card !== 'object') {
        let id = card
        card = this.$refs[`product-card-${id}`].$el || this.$refs[`product-card-${id}`][0].$el
      }
      // grab cardDataObject
      let cardIXs = card.dataset.index.split('-')
      if (cardIXs[0] === 'item') {
        this.dialogProductItem = cardIXs.pop()
        this.dialogProductGroup = cardIXs.pop()
        this.dialogProductShow = true
        this.toolbarShadowOnOverscrollClear()
        setTimeout(() => {
          this.soundPlay('sheet_up')
        }, 1)
      } else {
        let cardObj = this.datagroups[1 * cardIXs[1]].datafields[1 * cardIXs[3]]
        let cardType = cardObj.type
        if (cardType === 'link') {
          openURL(cardObj.indicates.aux().value)
        } else if (cardType === 'phone') {
          this.call(cardObj.indicates.aux().text)
        } else {
          this.perspective(card)
        }
      }
      setTimeout(() => {
        // reset shadow scroll
        // document.querySelector('#itemEditHeader').classList.remove('shadow-overscroll-show')
        // adjust height per viewport
        this.dialogItemAdjustFit()
      }, 10)
    },
    setCardIntent (obj, id, cb) {
      let card = this.$refs[`product-card-${id}`].$el || this.$refs[`product-card-${id}`][0].$el
      // ignore intent if card is disabled
      if (card.attributes.disabled) return
      // handle card
      if (obj.isFirst) {
        card.classList.add('intent')
      } else if (obj.isFinal) {
        if (card.classList.contains('intent')) {
          card.classList.remove('intent')
          if (Math.abs(obj.offset.x) < 50 && Math.abs(obj.offset.y) < 50) {
            // call handler
            setTimeout(function () {
              (cb)(card)
            }, 1)
          }
        }
      } else {
        if (card.classList.contains('intent')) {
          if (Math.abs(obj.offset.x) > 50 || Math.abs(obj.offset.y) > 50) {
            card.classList.remove('intent')
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus">
h1
  font-size 200%
  margin-top 0
  margin-bottom 10px
  letter-spacing 0

img
  &.hero
    display inline-block
    padding-bottom 20px
    width 100%
    max-width 140px

.q-progress, .q-progress-track, .q-progress-model
  border-radius 0 !important

.q-card-widget-guard,
.q-card-widget-guard-online,
.q-card-widget-guard-offline,
.q-card-widget-guard-warning
  width 94%
.q-card-widget-guard
  background #fff
  box-shadow 0 0 41px 34px rgba(0,0,0,0.06) !important
  .q-card-title
    color #555555
    font-weight 800
.q-card-widget-guard-online
  background-image linear-gradient(180deg, #21BA45 0%, #15862f 100%) !important
  .q-card-title
    color white
    font-weight 800
.q-card-widget-guard-offline
  // background #fff
  box-shadow 0 0 41px 34px rgba(0,0,0,0.06) !important
  // background-image linear-gradient(180deg, #555555 0%, #3D3D3D 100%) !important
  .q-card-title
    color white
    font-weight 800
.q-card-widget-guard-warning
  background-image linear-gradient(180deg, #F57313 0%, #CD5F0D 100%) !important
  .q-card-title
    color white
    font-weight 800

.moveup
  transform-style preserve-3d
  backface-visibility hidden
  background-image url(/statics/_demo/balloons.svg) !important
  background-size 80%
  background-repeat repeat-x
  background-position bottom center
  background-blend-mode lighten
  background-color rgba(255,255,255,0)
  animation moveup 15000ms infinite ease-in-out

@-webkit-keyframes moveup
  0%
    background-color rgba(255,255,255,1)
    background-position 200px 300px
  25%
    background-color rgba(255,255,255,0.25)
  50%
    background-color rgba(255,255,255,0.50)
  75%
    background-color rgba(255,255,255,0.70)
  100%
    background-color rgba(255,255,255,1)
    background-position -100px -200px

#dialogItem, .dialog-item
  .q-modal-layout
    background-repeat no-repeat
    background-position 130% bottom
    background-color rgba(255, 255, 255, 0.96)
    background-blend-mode color
    background-size 50%
  .q-option-inner
    i
      font-size 160%

#dialogItem, .dialog-item
  .q-modal-layout
    background-repeat no-repeat
    background-position 130% bottom
    background-color rgba(255, 255, 255, 0.96)
    background-blend-mode color
    background-size 50%
  .q-option-inner
    i
      font-size 160%

.q-card[data-index*='-doordash-']
  .q-card-primary
    background-size 125px
    background-repeat no-repeat
    background-position 93% -33px !important
    background-image url(/statics/_demo/services/doordash.svg) !important

.q-card[data-index*='-grubhub-']
  .q-card-primary
    background-size 101px
    background-repeat no-repeat
    background-position 97% 7px !important
    background-image url(/statics/_demo/services/grubhub.png) !important

.q-card[data-index*='-ubereats-']
  .q-card-primary
    background-size 101px
    background-repeat no-repeat
    background-position 92% 21px !important
    background-image url(/statics/_demo/services/ubereats.svg) !important

.q-card[data-index*='-postmates-']
  .q-card-primary
    background-size 72px
    background-repeat no-repeat
    background-position 102% -9px !important
    background-image url(/statics/_demo/services/postmates.svg) !important

.scanning
  overflow hidden
  &:after
    content ''
    display block
    position absolute
    width calc(100%)
    height calc(100%)
    top 0
    left 0
    z-index 1
    background-image linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0) 30%, rgba(255, 0, 145, 0.4) 55%, #ff0091 56%, rgba(0, 0, 0, 0) 57%, rgba(0, 0, 0, 0.2))
    background-size 300% 100%
    background-repeat no-repeat
    background-position 84% center
    animation scan 4s infinite ease

@keyframes scan
  60%
    background-position 25% center

.shrink
  animation shrink-center 2000ms ease-in-out

@keyframes shrink-center
  0%
    transform scale(1)
  100%
    transform scale(0)

.dialog-mini
  .modal-content
    max-width 94vw !important
    max-height 25vh !important
    margin-bottom 40px
    border-radius 2em !important

.dialog-grow
  .modal-content
    max-height 95vh !important
  &.dialog-grow-full
    .modal-content
      max-height 100vh !important
      border-top-left-radius 0 !important
      border-top-right-radius 0 !important
  &.no-title
    .q-layout-header
      .q-toolbar:first-child
        display none

</style>
